import { PromiseCompletionSource, Viewable } from "scene-manager";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { InjectableFactories } from "../../../../composition/InjectableFactories";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../audio/AudioManager";
import { UIResourcesPackage } from "../../slot/components/ui/resources/UIResourcesPackage";
import { MobileBetView } from "./views/MobileBetView";

@injectable()
export class MobileBetScene extends Viewable<number> {
    public constructor(
        private readonly bonusGameFinishResources: UIResourcesPackage,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        @inject("initialTotalBet") private readonly initialTotalBet: number,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager
    ) {
        super();
        this.bonusGameFinishResources = bonusGameFinishResources;
        this.canvasScaler = canvasScaler;
    }
    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<number>();
        this.view = new MobileBetView(
            this.bonusGameFinishResources,
            this.canvasScaler,
            this.initialTotalBet,
            this.defaultHoverHighlightButtonFactory,
            this.audioManager,
            viewCanBeClosed
        );

        viewCanBeClosed.result.then((totalBet: number) => this.operationControls.complete(totalBet));
    }
}
