import { BitmapText, Container, IDestroyOptions, Sprite } from "pixi.js";
import { SlotViewModel } from "../../../models/SlotViewModel";
import { FreeSpinsModeResourcesPackage } from "../../../resources/FreeSpinsModeResourcesPackage";
import { nameof } from "bindable-data";
import { SlotResourcesPackage } from "../../../resources/SlotResourcesPackage";
import { SlotStateType } from "../../../views/slotStates/SlotStateType";
import { DefaultBitmapStyleText } from "../../../../common/font/DefaultBitmapStyleText";

export class Title extends Container {
    private titleSprite: Sprite;
    private freeSpinsCountText: BitmapText;

    public constructor(
        private readonly freeSpinsModeResources: FreeSpinsModeResourcesPackage,
        private readonly slotResources: SlotResourcesPackage,
        private readonly slotViewModel: SlotViewModel
    ) {
        super();
        this.slotViewModel.propertyChanged.add(this.onChangeSlotState, this);
        this.build();
        this.layout();
    }

    private build(): void {
        this.titleSprite = new Sprite(this.slotResources.assets_slot_field_title_png);
        this.addChild(this.titleSprite);
        this.freeSpinsCountText = new BitmapText("", new DefaultBitmapStyleText(30));
        this.freeSpinsCountText.visible = false;
        this.addChild(this.freeSpinsCountText);
    }

    private layout(): void {
        this.freeSpinsCountText.position.set(73, 20);
    }

    private onChangeSlotState(model: SlotViewModel, key: string): void {
        switch (key) {
            case nameof(model, "slotViewState"):
                let isFreeSpinMode = model.slotViewState.value == SlotStateType.FreeSpinState;
                this.titleSprite.texture = isFreeSpinMode
                    ? this.freeSpinsModeResources.assets_freeSpinsMode_freeSpinsTitle_png
                    : this.slotResources.assets_slot_field_title_png;
                this.freeSpinsCountText.visible = isFreeSpinMode;
                this.freeSpinsCountText.text = model.freeSpinsCount.value.toString();
                break;
            case nameof(model, "freeSpinsCount"):
                this.freeSpinsCountText.text = model.freeSpinsCount.value.toString();
                break;

        }
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        this.slotViewModel.propertyChanged.remove(this.onChangeSlotState, this);
    }
}
