import { AudioNames } from "../../../../audio/AudioNames";
import { SlotController } from "../../controllers/SlotController";
import { SlotModel } from "../../models/SlotModel";
import { SpinResult } from "../../models/data/SpinResult";
import { WinLine } from "../../models/data/WinLine";
import { DefaultState } from "./DefaultState";
import { SlotStateType } from "./SlotStateType";

export class FreeSpinState extends DefaultState {

    public constructor(
        reelsStopper: (result: SpinResult) => Promise<void>,
        reelsFastStopper: (result: SpinResult, reelsToStop: number) => Promise<void>,
        reelsFader: (result: SpinResult) => Promise<void>,
        specialSymbolsHighlighter: () => Promise<void>,
        winLinesHighlighter: (winLines: WinLine[]) => Promise<void>,
        slotController: SlotController,
        private readonly slotModel: SlotModel
    ) {
        super(reelsStopper, reelsFastStopper, reelsFader, specialSymbolsHighlighter, winLinesHighlighter, slotController);
    }

    public override async enter(): Promise<void> {
        this.slotController.playBackgroundMusic(AudioNames.FreeSpinsMusic);
        this.slotController.disableSlotElements(['autoSpinButtonUi', 'infoButtonUi', 'betFieldUi']);
        this.slotController.changeSlotViewState(SlotStateType.FreeSpinState);
        this.slotController.startFreeSpinsMode();
        await this.slotController.showFreeSpinsStartPopup();
        this.slotController.turnOnAutoSpin();
    }

    public override async process(spinResult: SpinResult): Promise<SlotStateType> {
        await super.process(spinResult);
        if (!this.slotModel.freeSpinMode.value) {
            return SlotStateType.DefaultState;
        }
        return SlotStateType.FreeSpinState;
    }

    public override async exit(): Promise<void> {
        this.slotController.enableSlotElements(['autoSpinButtonUi', 'infoButtonUi', 'betFieldUi']);
        this.slotController.turnOffAutoSpin();
        this.slotController.changeSlotViewState(SlotStateType.DefaultState);
        this.slotController.completeFreeSpinsMode();
        await this.slotController.showFreeSpinsCompletePopup();
        this.slotController.stopBackgroundMusic(AudioNames.FreeSpinsMusic);
    }
}