import { Container, DisplayObject, IDestroyOptions } from "pixi.js";
import { BaseView, PromiseCompletionSource } from "scene-manager";
import gsap from "gsap";
import { AudioManager } from "../../../audio/AudioManager";
import { AudioNames } from "../../../audio/AudioNames";

export class InOutAnimatedPopup<T> extends BaseView<T> {
    protected readonly content: Container;
    private originalOperationControls: PromiseCompletionSource<T>;
    protected showAnimationPopup: gsap.core.Timeline;
    protected hideAnimationPopup: gsap.core.Timeline;

    public constructor(protected readonly audioManager: AudioManager, operationControls: PromiseCompletionSource<T>) {
        const overtakeOperationControls = new PromiseCompletionSource<T>();
        super(overtakeOperationControls);
        this.originalOperationControls = operationControls;
        overtakeOperationControls.result.then(async (result: T) => {
            await this.hidePopupAnimation();
            this.originalOperationControls.complete(result);
        });
        this.content = new Container();
        super.addChild(this.content);
    }

    public start(): void {
        this.showPopupAnimation();
    }

    public override addChild<U extends DisplayObject[]>(...children: U): U[0] {
        return this.content.addChild(...children);
    }

    public override removeChild<U extends DisplayObject[]>(...children: U): U[0] {
        return this.content.removeChild(...children);
    }

    public override addChildAt<U extends DisplayObject>(child: U, index: number): U {
        return this.content.addChildAt(child, index);
    }

    public override removeChildAt(index: number): DisplayObject {
        return this.content.removeChildAt(index);
    }

    public override removeChildren(beginIndex?: number, endIndex?: number): DisplayObject[] {
        return this.content.removeChildren();
    }

    protected async showPopupAnimation(): Promise<void> {
        this.audioManager.play(AudioNames.PopupIn);
        this.showAnimationPopup = gsap.timeline().fromTo(this.content, { pixi: { positionY: -this.height } }, { ease: 'elastic.out(1,1)', pixi: { positionY: 0 }, duration: 1 });
        await this.showAnimationPopup;
    }

    protected async hidePopupAnimation(): Promise<void> {
        this.audioManager.play(AudioNames.PopupOut);
        this.hideAnimationPopup = gsap.timeline().to(this.content, { pixi: { positionY: -this.height }, ease: 'elastic.in(1,1)', duration: 1 });
        await this.hideAnimationPopup;
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        this.showAnimationPopup.kill();
        this.hideAnimationPopup.kill();
    }
}