import { Container, IDestroyOptions, Sprite } from "pixi.js";
import { UIResourcesPackage } from "../resources/UIResourcesPackage";
import { SlotController } from "../../../controllers/SlotController";
import { DefaultHoverHighlightButton } from "../../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../../factories/DefaultHoverHighlightButtonFactory";
import { SlotViewModel } from "../../../models/SlotViewModel";
import { nameof } from "bindable-data";

export class InfoContainer extends Container {
    private infoButton: DefaultHoverHighlightButton;

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly slotController: SlotController,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        private readonly slotViewModel: SlotViewModel
    ) {
        super();
        this.onInfoButtonClick = this.onInfoButtonClick.bind(this);
        this.slotViewModel.propertyChanged.add(this.onSlotViewModelUpdated, this);
        this.eventMode = slotViewModel.disabledSlotElements.value.includes('infoButtonUi') || slotViewModel.spinAnimationInProgress.value ? 'none' : 'auto';
        this.build();
    }

    private build() {
        const infoButtonSprite = new Sprite(this.resources.assets_ui_info_container_info_button_png);
        this.infoButton = this.defaultHoverHighlightButtonFactory.createButton(infoButtonSprite);
        this.infoButton.onPress.connect(this.onInfoButtonClick);
        this.addChild(this.infoButton.view);
    }

    private async onInfoButtonClick() {
        await this.slotController.showRulesPopup();
    }

    private onSlotViewModelUpdated(slotViewModel: SlotViewModel, propertyName: string) {
        if (propertyName === nameof(slotViewModel, "disabledSlotElements")) {
            this.eventMode = slotViewModel.disabledSlotElements.value.includes('infoButtonUi') ? 'none' : 'auto';
        }
        if (propertyName === nameof(slotViewModel, "spinAnimationInProgress")) {
            this.eventMode = !slotViewModel.spinAnimationInProgress.value ? 'auto' : 'none';
        }
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.infoButton.onPress.disconnect(this.onInfoButtonClick);
        this.infoButton.destroy();
        this.slotViewModel.propertyChanged.remove(this.onSlotViewModelUpdated, this);
        super.destroy(options);
    }
}