import { Container, IDestroyOptions, Sprite, Texture } from "pixi.js";
import { BonusGameResourcesPackage } from "../resources/BonusGameResourcesPackage";
import gsap from "gsap";

interface GlowConfiguration {
    backgroundGlow: boolean;
    roundGlow: boolean;
}

export class Multiplier extends Container {
    private backgroundGlow: Sprite;
    private roundGlow: Sprite;
    private result: Sprite;
    private animationTimeline: gsap.core.Timeline;
    private roundGlowAnimationTimeline: gsap.core.Timeline;

    public constructor(private readonly resources: BonusGameResourcesPackage, glowConfig: GlowConfiguration, multiplier: number) {
        super();
        this.build(glowConfig, multiplier);
        this.layout();
    }

    private build(glowConfig: GlowConfiguration, multiplier: number) {
        if (glowConfig.backgroundGlow) {
            this.backgroundGlow = new Sprite(this.resources.assets_popups_bonusGame_Glow_Multiplier_png);
            this.backgroundGlow.pivot.set(this.backgroundGlow.width * 0.5, this.backgroundGlow.height * 0.5);
            this.addChild(this.backgroundGlow);
        }
        if (glowConfig.roundGlow) {
            this.roundGlow = new Sprite(this.resources.assets_popups_bonusGame_RoundGlowMultiplier_png);
            this.roundGlow.pivot.set(this.roundGlow.width * 0.5, this.roundGlow.height * 0.5);
            this.roundGlowAnimationTimeline = gsap.timeline().to(this.roundGlow, { pixi: { rotation: 360 }, ease: "none", repeat: -1, duration: 1 });
            this.addChild(this.roundGlow);
        }
        const resultTexture = this.resolveResultTexture(multiplier);
        this.result = new Sprite(resultTexture);
        this.result.pivot.set(this.result.width * 0.5, this.result.height * 0.5);
        this.addChild(this.result);
    }

    private layout() {
        this.position.set(175, 100);
    }

    private resolveResultTexture(multiplier: number): Texture {
        switch (multiplier) {
            case 2: {
                return this.resources.assets_popups_bonusGame_x2_png;
            }
            case 3: {
                return this.resources.assets_popups_bonusGame_x3_png;
            }
            case 5: {
                return this.resources.assets_popups_bonusGame_x5_png;
            }
            default: {
                throw new Error("Multiplier component can't resolve multiplier texture!");
            }
        }
    }

    public async animateMultiplier() {
        this.animationTimeline = gsap.timeline().fromTo(this, { pixi: { scaleX: 0, scaleY: 0 } }, { pixi: { scaleX: 1, scaleY: 1, positionY: -100 }, duration: 0.5 });
        await this.animationTimeline.play();
        this.animationTimeline.kill();
    }

    public async scaleMultiplier() {
        this.animationTimeline = gsap.timeline({ repeat: 1, yoyo: true }).to(this, { pixi: { scaleX: 1.3, scaleY: 1.3 } });
        await this.animationTimeline.play();
        this.animationTimeline.kill();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.animationTimeline.kill();
        this.roundGlowAnimationTimeline?.kill();
        super.destroy(options);
    }
}