import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class WinPopupResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("WinPopupResourcesPackage");
    }
    public get assets_popups_win_animation_WinPopups_atlas(): any {
        return this.resource<any>("assets/popups/win/animation/WinPopups.atlas");
    }
    public get assets_popups_win_animation_WinPopups_json(): Object {
        return this.resource<Object>("assets/popups/win/animation/WinPopups.json");
    }
    public get assets_popups_win_animation_WinPopups_png(): Texture {
        return this.resource<Texture>("assets/popups/win/animation/WinPopups.png");
    }
    public get assets_popups_win_emitter_json(): Object {
        return this.resource<Object>("assets/popups/win/emitter.json");
    }
    public get assets_popups_win_particle_png(): Texture {
        return this.resource<Texture>("assets/popups/win/particle.png");
    }
}

