import { Container, IDestroyOptions, Text } from "pixi.js";
import { UIResourcesPackage } from "../resources/UIResourcesPackage";
import { UITextStyle } from "../UITextStyle";
import { IPlayerModel } from "../../../../../player/IPlayerModel";
import { nameof } from "bindable-data";
import { CurrencyFormatUtils } from "../../../../common/utils/NumberFormatUtils";

export class BalanceField extends Container {
    private balanceLabel: Text;
    private balanceText: Text;

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly playerModel: IPlayerModel
    ) {
        super();
        this.playerModel.propertyChanged.add(this.updateBalanceText, this);
        this.build();
        this.layout();
    }

    private build() {
        this.balanceLabel = new Text("BALANCE", new UITextStyle(32));
        this.addChild(this.balanceLabel);
        this.balanceText = new Text(CurrencyFormatUtils.getFormatedCurrency(this.playerModel.balance.value), new UITextStyle(34));
        this.balanceText.anchor.set(0.5);
        this.addChild(this.balanceText);
    }

    private layout() {
        this.balanceLabel.position.set((this.width - this.balanceLabel.width * 0.5) * 0.5, 0);
        this.balanceText.position.set(this.width * 0.5, this.balanceLabel.height + 30);
    }

    private updateBalanceText(playerModel: IPlayerModel, key: string) {
        if (key == nameof(playerModel, "balance")) {
            this.balanceText.text = CurrencyFormatUtils.getFormatedCurrency(playerModel.balance.value);
        }
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.playerModel.propertyChanged.remove(this.updateBalanceText, this);
        super.destroy(options);
    }

}
