import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class BigWildAnimationResourcePackage extends BundleResourcesPackage {
    public constructor() {
        super("BigWildAnimationResourcePackage");
    }
    public get assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/bigWildAnimation/bigWildAnimation-0_1x.atlas.json");
    }
    public get assets_bigWildAnimation_0_0_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_0.png"];
    }
    public get assets_bigWildAnimation_0_10_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_10.png"];
    }
    public get assets_bigWildAnimation_0_11_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_11.png"];
    }
    public get assets_bigWildAnimation_0_12_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_12.png"];
    }
    public get assets_bigWildAnimation_0_13_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_13.png"];
    }
    public get assets_bigWildAnimation_0_14_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_14.png"];
    }
    public get assets_bigWildAnimation_0_15_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_15.png"];
    }
    public get assets_bigWildAnimation_0_16_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_16.png"];
    }
    public get assets_bigWildAnimation_0_17_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_17.png"];
    }
    public get assets_bigWildAnimation_0_18_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_18.png"];
    }
    public get assets_bigWildAnimation_0_19_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_19.png"];
    }
    public get assets_bigWildAnimation_0_2_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_2.png"];
    }
    public get assets_bigWildAnimation_0_20_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_20.png"];
    }
    public get assets_bigWildAnimation_0_21_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_21.png"];
    }
    public get assets_bigWildAnimation_0_22_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_22.png"];
    }
    public get assets_bigWildAnimation_0_23_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_23.png"];
    }
    public get assets_bigWildAnimation_0_1_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_1.png"];
    }
    public get assets_bigWildAnimation_0_48_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_48.png"];
    }
    public get assets_bigWildAnimation_0_49_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_json.textures["0_49.png"];
    }
    public get assets_bigWildAnimation_bigWildAnimation_0_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/bigWildAnimation/bigWildAnimation-0_1x.atlas.png");
    }
    public get assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/bigWildAnimation/bigWildAnimation-1_1x.atlas.json");
    }
    public get assets_bigWildAnimation_0_24_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_24.png"];
    }
    public get assets_bigWildAnimation_0_25_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_25.png"];
    }
    public get assets_bigWildAnimation_0_26_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_26.png"];
    }
    public get assets_bigWildAnimation_0_27_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_27.png"];
    }
    public get assets_bigWildAnimation_0_28_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_28.png"];
    }
    public get assets_bigWildAnimation_0_29_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_29.png"];
    }
    public get assets_bigWildAnimation_0_3_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_3.png"];
    }
    public get assets_bigWildAnimation_0_30_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_30.png"];
    }
    public get assets_bigWildAnimation_0_31_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_31.png"];
    }
    public get assets_bigWildAnimation_0_32_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_32.png"];
    }
    public get assets_bigWildAnimation_0_33_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_33.png"];
    }
    public get assets_bigWildAnimation_0_34_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_34.png"];
    }
    public get assets_bigWildAnimation_0_35_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_35.png"];
    }
    public get assets_bigWildAnimation_0_36_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_36.png"];
    }
    public get assets_bigWildAnimation_0_37_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_37.png"];
    }
    public get assets_bigWildAnimation_0_38_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_json.textures["0_38.png"];
    }
    public get assets_bigWildAnimation_bigWildAnimation_1_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/bigWildAnimation/bigWildAnimation-1_1x.atlas.png");
    }
    public get assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/bigWildAnimation/bigWildAnimation-2_1x.atlas.json");
    }
    public get assets_bigWildAnimation_0_39_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_39.png"];
    }
    public get assets_bigWildAnimation_0_4_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_4.png"];
    }
    public get assets_bigWildAnimation_0_40_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_40.png"];
    }
    public get assets_bigWildAnimation_0_41_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_41.png"];
    }
    public get assets_bigWildAnimation_0_42_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_42.png"];
    }
    public get assets_bigWildAnimation_0_43_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_43.png"];
    }
    public get assets_bigWildAnimation_0_44_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_44.png"];
    }
    public get assets_bigWildAnimation_0_45_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_45.png"];
    }
    public get assets_bigWildAnimation_0_46_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_46.png"];
    }
    public get assets_bigWildAnimation_0_47_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_47.png"];
    }
    public get assets_bigWildAnimation_0_5_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_5.png"];
    }
    public get assets_bigWildAnimation_0_6_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_6.png"];
    }
    public get assets_bigWildAnimation_0_7_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_7.png"];
    }
    public get assets_bigWildAnimation_0_8_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_8.png"];
    }
    public get assets_bigWildAnimation_0_9_png(): Texture {
        return this.assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_json.textures["0_9.png"];
    }
    public get assets_bigWildAnimation_bigWildAnimation_2_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/bigWildAnimation/bigWildAnimation-2_1x.atlas.png");
    }
}

