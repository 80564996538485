import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class SlotResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("SlotResourcesPackage");
    }
    public get assets_slot_defaultBackground_jpg(): Texture {
        return this.resource<Texture>("assets/slot/defaultBackground.jpg");
    }
    public get assets_slot_effects_bubbles_json(): Object {
        return this.resource<Object>("assets/slot/effects/bubbles.json");
    }
    public get assets_slot_effects_bubbles_png(): Texture {
        return this.resource<Texture>("assets/slot/effects/bubbles.png");
    }
    public get assets_slot_field_frame_png(): Texture {
        return this.resource<Texture>("assets/slot/field/frame.png");
    }
    public get assets_slot_field_substrate_png(): Texture {
        return this.resource<Texture>("assets/slot/field/substrate.png");
    }
    public get assets_slot_field_title_png(): Texture {
        return this.resource<Texture>("assets/slot/field/title.png");
    }
    public get assets_slot_symbols_bonus_1x1_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/bonus/1x1.png");
    }
    public get assets_slot_symbols_common_Dice_1_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/common/Dice_1.png");
    }
    public get assets_slot_symbols_common_Dice_2_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/common/Dice_2.png");
    }
    public get assets_slot_symbols_common_Dice_3_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/common/Dice_3.png");
    }
    public get assets_slot_symbols_common_Dice_4_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/common/Dice_4.png");
    }
    public get assets_slot_symbols_common_Dice_5_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/common/Dice_5.png");
    }
    public get assets_slot_symbols_common_Dice_6_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/common/Dice_6.png");
    }
    public get assets_slot_symbols_freeSpins_1x1_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/freeSpins/1x1.png");
    }
    public get assets_slot_symbols_freeSpins_1x3_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/freeSpins/1x3.png");
    }
    public get assets_slot_symbols_wild_1x1_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/wild/1x1.png");
    }
    public get assets_slot_symbols_wild_1x3_png(): Texture {
        return this.resource<Texture>("assets/slot/symbols/wild/1x3.png");
    }
}

