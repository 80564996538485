import { AnimatedSprite, Container, IDestroyOptions, Texture } from "pixi.js";
import { gsap } from "gsap";
import { AnticipationFramesProvider } from "./resources/AnticipationFramesProvider";

export class AnticipationAnimation extends Container {
    private animation: AnimatedSprite;
    private animationTimeline: gsap.core.Timeline;
    private onResourcesLoading: () => void;

    public constructor(private readonly resources: AnticipationFramesProvider) {
        super();
        this.build();
    }

    private build() {
        if (this.resources.loaded) {
            this.createAnimation(this.resources.animationFrames);
        } else {
            this.onResourcesLoading = this.onResourcesLoadingProgress.bind(this);
            this.resources.onLoadingProgress.add(this.onResourcesLoading);
        }
    }

    private createAnimation(textures: Array<Texture>) {
        this.animation = new AnimatedSprite(textures);
        this.animation.animationSpeed = 0.5;
        this.animation.alpha = 0;
        this.addChild(this.animation);
    }

    private onResourcesLoadingProgress(packageProgress: number) {
        if (packageProgress === 1) {
            this.createAnimation(this.resources.animationFrames);
        }
    }

    public async play(): Promise<void> {
        if (!this.animation) {
            return;
        }
        this.animation.gotoAndPlay(0);
        this.animationTimeline = gsap.timeline().to(this.animation, { alpha: 1, duration: 0.5 });
        await this.animationTimeline.play();
    }

    public async stop(): Promise<void> {
        if (!this.animation) {
            return;
        }
        this.animationTimeline = gsap.timeline().to(this.animation, { alpha: 0, duration: 0.5 });
        await this.animationTimeline.play();
        this.animation.gotoAndStop(this.resources.animationFrames.length - 1);
    }

    public forceStop() {
        if (!this.animation) {
            return;
        }
        this.animation.alpha = 0;
        this.animation.gotoAndStop(this.resources.animationFrames.length - 1);
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.resources.onLoadingProgress.remove(this.onResourcesLoading);
        super.destroy(options);
    }
}