import { IDestroyOptions, Sprite } from "pixi.js";
import { BaseView, PromiseCompletionSource } from "scene-manager";
import { PreloaderModel } from "../models/PreloaderModel";
import { PreloaderResourcesPackage } from "../resources/PreloaderResourcesPackage";
import { nameof } from "bindable-data";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { PreloaderController } from "../controllers/PreloaderContorller";
import { ProgressBar } from "../components/ProgressBar";
import { IFocusable } from "../../../layers/IFocusable";
import { FullscreenBackground } from "../../common/components/FullscreenBackground";
import { gsap } from "gsap";
import { DefaultHoverHighlightButton } from "../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { DeviceCheckUtils } from "../../common/utils/DeviceCheckUtils";

export class PreloaderView extends BaseView<void> implements IFocusable {
    private static readonly HIDE_ANIMATION_DURATION = 1;
    private progressBar: ProgressBar;
    private logo: Sprite;
    private startButton: DefaultHoverHighlightButton;
    private hideAnimation: GSAPTimeline;
    public readonly focusable: boolean = true;
    private onScreenChangeHandler: () => void;

    public constructor(
        private readonly preloaderResources: PreloaderResourcesPackage,
        private readonly preloaderModel: PreloaderModel,
        private readonly preloaderController: PreloaderController,
        private readonly canvasScaler: ICanvasScaler,
        private readonly fullscreenBackground: FullscreenBackground,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        viewCanBeClosed: PromiseCompletionSource<void>
    ) {
        super(viewCanBeClosed);
        this.preloaderModel.propertyChanged.add(this.preloaderModelChangeHandler, this);
        this.preloaderModelChangeHandler(this.preloaderModel, nameof(this.preloaderModel, "loadingProgress"));
        this.onStartButtonClick = this.onStartButtonClick.bind(this);
        this.onScreenChangeHandler = this.layout.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenChangeHandler);
        this.build();
        this.layout();
    }

    public start() {
        this.preloaderController.startPreloading();
    }

    private build(): void {
        this.fullscreenBackground.updateImage(this.preloaderResources.assets_preloader_background_jpg);
        this.logo = new Sprite(this.preloaderResources.assets_preloader_logo_png);
        this.addChild(this.logo);
        this.startButton = this.defaultHoverHighlightButtonFactory.createButton(new Sprite(this.preloaderResources.assets_preloader_startButton_png));
        this.startButton.onPress.connect(this.onStartButtonClick);
        this.setActiveButton(false);
        this.logo.addChild(this.startButton.view);
        this.progressBar = new ProgressBar(this.preloaderModel, this.preloaderResources.assets_preloader_loadingBar_line_png, this.preloaderResources.assets_preloader_loadingBar_container_png)
        this.logo.addChild(this.progressBar);
    }

    private layout() {
        const referenceWidth = this.canvasScaler.referenceWidth;
        const referenceHeight = this.canvasScaler.referenceHeight;
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.scale.set(2.3);
            this.logo.position.set(-50, (referenceWidth - this.logo.height * 1.25) * 0.5 - 50);
        } else {
            this.scale.set(1);
            this.logo.position.set((referenceWidth - this.logo.width) * 0.5, (referenceHeight - this.logo.height) * 0.5);
        }
        this.startButton.view.position.set(this.logo.width * 0.5 - this.startButton.view.width * 0.5, (referenceHeight - this.startButton.view.height) * 0.5 + 250);
        this.progressBar.position.set(this.logo.width * 0.5 - this.progressBar.width * 0.5, (referenceHeight - this.progressBar.height) * 0.5 + 170);
    }

    private preloaderModelChangeHandler(model: PreloaderModel, property: string): void {
        switch (property) {
            case nameof(model, "loadingProgress"):
                const loadingProgress = model.loadingProgress.value;
                if (loadingProgress == 1.0) {
                    this.setActiveButton(true);
                    this.preloaderController.startBackgroundAssetsLoading();
                }
                break;
        }
    }

    private setActiveButton(isActive: boolean): void {
        this.startButton.view.visible = isActive;
        this.startButton.enabled = isActive;
    }

    private async onStartButtonClick(): Promise<void> {
        this.startButton.enabled = false;
        this.hideAnimation = new gsap.core.Timeline({ paused: true });
        this.hideAnimation.to(this.logo,
            {
                alpha: 0,
                duration: PreloaderView.HIDE_ANIMATION_DURATION
            }
        );
        this.hideAnimation.to(this.startButton.view,
            {
                alpha: 0,
                duration: PreloaderView.HIDE_ANIMATION_DURATION
            }, "<"
        );
        await this.hideAnimation.play();
        this.operationControls.complete();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.hideAnimation.kill();
        this.startButton.onPress.disconnect(this.onStartButtonClick);
        this.startButton.destroy();
        this.preloaderModel.propertyChanged.remove(this.preloaderModelChangeHandler, this);
        window.screen.orientation.removeEventListener("change", this.onScreenChangeHandler);
        super.destroy(options);
    }
}
