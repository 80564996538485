import { AudioNames } from "../../../../audio/AudioNames";
import { SlotController } from "../../controllers/SlotController";
import { SpinResult } from "../../models/data/SpinResult";
import { WinLine } from "../../models/data/WinLine";
import { ISlotState } from "./ISlotState";
import { SlotStateType } from "./SlotStateType";

export class DefaultState implements ISlotState<SpinResult> {

    public constructor(
        private readonly reelsStopper: (result: SpinResult) => Promise<void | number>,
        private readonly reelsFastStopper: (result: SpinResult, reelsToStop: number) => Promise<void>,
        private readonly reelsFader: (result: SpinResult) => Promise<void>,
        private readonly specialSymbolsHighlighter: () => Promise<void>,
        private readonly winLinesHighlighter: (winLines: WinLine[]) => Promise<void>,
        protected readonly slotController: SlotController
    ) {

    }

    public async enter(): Promise<void> {
        this.slotController.playBackgroundMusic(AudioNames.MainTheme);
    }
    
    public async process(spinResult: SpinResult): Promise<SlotStateType> {
        let nextState = SlotStateType.DefaultState;
        const reelsToStop = await this.reelsStopper(spinResult);
        if (reelsToStop !== null && typeof reelsToStop === 'number') {
            await this.reelsFastStopper(spinResult, reelsToStop);
        }
        await this.reelsFader(spinResult);
        this.slotController.restoreStopReelsFast();
        if (spinResult.winning.isBigWin) {
            await this.slotController.showBigWinPopup();
        } else if (spinResult.winning.prize > 0) {
            await this.slotController.showRegularWinPopup();
        }
        if (spinResult.winning.prize > 0) {
            await this.winLinesHighlighter(spinResult.winning.winLines);
        }
        await this.specialSymbolsHighlighter();
        this.slotController.invalidatePlayer(spinResult.playerBalance);
        if (spinResult.winning.isBonusGameWin) {
            nextState = SlotStateType.BonusGameState;
        }
        if (spinResult.winning.isFreeSpinsWin) {
            nextState = SlotStateType.FreeSpinState;
        }
        return nextState;
    }
    
    public async exit(): Promise<void> {
        this.slotController.stopBackgroundMusic(AudioNames.MainTheme);
        this.slotController.turnOffAutoSpin();
    }

}