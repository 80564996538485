import { ViewableLayer } from "scene-manager";
import { CanvasScaler, Scales } from "../../canvas/CanvasScaler";
import { DisplayObject, IDestroyOptions } from "pixi.js";
import { IFocusable } from "./IFocusable";
import { Fader } from "../scenes/common/components/Fader";

export class MarineDiceViewableLayer extends ViewableLayer {
    private canvasScaler: CanvasScaler;
    private readonly fixedWidth: number;
    private readonly fixedHeight: number;
    private readonly fader: Fader;

    public constructor(canvasScaler: CanvasScaler) {
        super();
        this.canvasScaler = canvasScaler;
        this.fixedWidth = this.canvasScaler.referenceWidth;
        this.fixedHeight = this.canvasScaler.referenceHeight;
        // Mask to cut render outside base resolution
        // This was commented due to inquery of fullscreen background fade. Hope there won't be any components wider then window.
        // If any appear we should use mask in view of the viewable rather then here.
        // const maskObject = new Graphics().beginFill(0xff0000).drawRect(0, 0, this.fixedWidth, this.fixedHeight);
        // this.mask = maskObject;
        // this.addChild(maskObject);
        this.fader = new Fader(this.canvasScaler.browserWidth, this.canvasScaler.browserHeight, 0.5, 0.0, 0.7);
        this.addChild(this.fader);
        this.onResize(canvasScaler.getSize());
        this.canvasScaler.onResize.add(this.onResize, this);
    }

    private onResize(size: Scales): void {
        this.height = size.height;
        this.width = size.width;
        const xCenterOffset = (this.canvasScaler.browserWidth - this.width) * 0.5;
        this.position.set(xCenterOffset, -this.canvasScaler.safeAreaOffset);
        this.fader.clear();
        // neglect parent scale influence
        this.fader.beginFill(0x000122, 1).drawRect(0, 0, this.canvasScaler.browserWidth / this.fader.parent.scale.x, this.canvasScaler.browserHeight / this.fader.parent.scale.y).endFill();
        if (this.fader.parent != null) {
            const globalTopLeft = this.toLocal({x: 0, y: 0});
            this.fader.position.set(globalTopLeft.x, globalTopLeft.y);
        }
    }

    public override calculateBounds(): void {
        this._bounds.clear();
        this._calculateBounds();
        this._bounds.updateID = this._boundsID;
    }

    protected override _calculateBounds(): void {
        this._bounds.addFramePad(0, 0, this.fixedWidth, this.fixedHeight, 0, 0);
    }

    public override addChild<U extends DisplayObject[]>(...children: U): U[0] {
        const addedChild = super.addChild(...children);
        if (this.isFocusable(addedChild)) {
            // turn on fader and move it under child
            const childIndex = this.getChildIndex(addedChild);
            this.setChildIndex(this.fader, childIndex == 0 ? 0 : childIndex - 1);
            this.fader.eventMode = 'static';
            this.fader.fadeIn();
        }
        return addedChild;
    }

    public override removeChild<U extends DisplayObject[]>(...children: U): U[0] {
        const removedChild = super.removeChild(...children);
        if (this.isFocusable(removedChild)) {
            // Move fader to the last Focusable child or switch of fader
            for (let i = this.children.length - 1; i >= 0; i--) {
                const child = this.children[i];
                if (this.isFocusable(child)) {
                    // Move fader behind this child
                    this.setChildIndex(this.fader, i);
                    return removedChild;
                }
            }
            this.fader.eventMode = 'none';
            this.fader.fadeOut();
        }
        return removedChild;
    }

    private isFocusable(obj: any): obj is IFocusable {
        return "focusable" in obj;
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.canvasScaler.onResize.remove(this.onResize, this);
        super.destroy(options);
    }

}
