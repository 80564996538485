import { Container, IDestroyOptions, Sprite, Texture } from "pixi.js";
import { BonusGameResourcesPackage } from "../resources/BonusGameResourcesPackage";
import { BonusGameController } from "../controllers/BonusGameController";
import { Event } from "event-system";
import { nameof } from "bindable-data";
import { Multiplier } from "./Multiplier";
import gsap from "gsap";
import { BonusGameViewModel } from "../models/BonusGameViewModel";
import { SlotModel } from "../../../slot/models/SlotModel";
import { AsyncAnimatedSprite } from "../../../common/components/AsyncAnimatedSprite";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { ChestAnimationFramesProvider } from "../resources/ChestAnimationFramesProvider";
import { IAudioManager } from "../../../../audio/IAudioManager";
import { AudioNames } from "../../../../audio/AudioNames";
import { Fader } from "../../../common/components/Fader";

const DELAY_BEFORE_MULTIPLIER_ANIMATION = 900; //ms

export class ChestContainer extends Container {
    private chest: AsyncAnimatedSprite;
    private openButton: DefaultHoverHighlightButton;
    private onOpenButtonClick: () => Promise<void>;
    public onButtonClickEvent: Event<number>;
    private containerAnimationTimeline: gsap.core.Timeline;
    private multiplier: Multiplier;
    private readonly glowAnimationDelay: number;
    private fader: Fader;
    private static readonly FADER_ALPHA = 0.6;

    public constructor(
        private readonly resources: BonusGameResourcesPackage,
        private readonly chestAnimationResources: ChestAnimationFramesProvider,
        private readonly slotModel: SlotModel,
        private readonly bonusGameViewModel: BonusGameViewModel,
        private readonly bonusGameController: BonusGameController,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        private readonly audioManager: IAudioManager,
        private readonly chestId: number
    ) {
        super();
        this.onOpenButtonClick = this.onOpenButtonClickHandler.bind(this);
        this.slotModel.propertyChanged.add(this.onSlotModelPropertyChange, this);
        this.bonusGameViewModel.propertyChanged.add(this.onBonusGameViewModelPropertyChange, this);
        this.onButtonClickEvent = new Event<number>();
        this.glowAnimationDelay = chestId;
        this.build();
        this.layout();
        this.setupGlowAnimation();
    }

    private build() {
        this.chest = new AsyncAnimatedSprite(this.chestAnimationResources.animationFrames);
        this.addChild(this.chest);
        const buttonView = new Sprite(this.resources.assets_popups_bonusGame_Button_Open_png);
        this.openButton = this.defaultHoverHighlightButtonFactory.createButton(buttonView);
        this.openButton.onPress.connect(this.onOpenButtonClick);
        this.addChild(this.openButton.view);
        this.fader = new Fader(397, 718, 0.5, 0, ChestContainer.FADER_ALPHA);
        this.addChild(this.fader);
    }

    private layout() {
        this.openButton.view.position.set(this.chest.width * 0.5 - this.openButton.view.width * 0.5, this.chest.height + 20);
        this.fader.position.set(-30, -178);
    }

    private async onOpenButtonClickHandler(): Promise<void> {
        this.onButtonClickEvent.emit(this.chestId);
        this.audioManager.play(AudioNames.BonusGameMultipliers);
    }

    public updateButtonTexture(newTexture: Texture) {
        const buttonView = this.openButton.view as Sprite;
        buttonView.texture = newTexture;
    }

    private async onSlotModelPropertyChange(bonusGameModel: SlotModel, propertyName: string): Promise<void> {
        switch (propertyName) {
            case nameof(bonusGameModel, "bonusGameResult"): {
                await this.stopGlowAnimation();
                if (bonusGameModel.bonusGameResult.value.chestId === this.chestId) {
                    this.audioManager.play(AudioNames.BonusSymbol);
                    this.chest.playAsync();
                    await this.delay(DELAY_BEFORE_MULTIPLIER_ANIMATION);
                    this.multiplier = new Multiplier(this.resources, { backgroundGlow: true, roundGlow: true }, bonusGameModel.bonusGameResult.value.multiplier);
                    this.addChild(this.multiplier);
                    await this.multiplier.animateMultiplier();
                    this.bonusGameController.finishMainAnimationPlay();
                } else {
                    await this.fadeContainerAnimation();
                }
                break;
            }
        }
    }

    private async onBonusGameViewModelPropertyChange(bonusGameViewModel: BonusGameViewModel, propertyName: string): Promise<void> {
        switch (propertyName) {
            case nameof(bonusGameViewModel, "mainAnimationFinished"): {
                if (this.slotModel.bonusGameResult.value.chestId !== this.chestId) {
                    this.audioManager.play(AudioNames.BonusSymbol);
                    this.chest.playAsync();
                    await this.delay(DELAY_BEFORE_MULTIPLIER_ANIMATION);
                    const otherMultiplier = bonusGameViewModel.getMultiplierValueByChestId(this.chestId);
                    this.multiplier = new Multiplier(this.resources, { backgroundGlow: false, roundGlow: false }, otherMultiplier);
                    this.addChild(this.multiplier);
                    await this.multiplier.animateMultiplier();
                    this.bonusGameController.finishSecondaryAnimationPlay();
                }
                break;
            }
            case nameof(bonusGameViewModel, "secondaryAnimationFinished"): {
                if (this.slotModel.bonusGameResult.value.chestId === this.chestId) {
                    await this.multiplier.scaleMultiplier();
                    this.bonusGameController.finishAnimation();
                }
                break;
            }
        }
    }

    private setupGlowAnimation(): void {
        this.containerAnimationTimeline = gsap
            .timeline({ repeat: -1, repeatDelay: 3, delay: this.glowAnimationDelay })
            .to(this, { pixi: { brightness: 2 }, duration: 0.5, onStart: () => this.audioManager.play(AudioNames.BonusGameIdle) })
            .to(this, { pixi: { brightness: 1 }, duration: 0.5 });
    }

    private async stopGlowAnimation(): Promise<void> {
        this.containerAnimationTimeline.kill();
        this.containerAnimationTimeline = gsap.timeline().to(this, { pixi: { brightness: 1 }, duration: 0.1 });
        await this.containerAnimationTimeline.play();
    }

    private async fadeContainerAnimation(): Promise<void> {
        this.containerAnimationTimeline.kill();
        this.fader.fadeIn();
    }

    private delay(timeMs: number) {
        return new Promise(resolve => setTimeout(resolve, timeMs));
    }

    public disableButton(): void {
        this.openButton.enabled = false;
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.slotModel.propertyChanged.remove(this.onBonusGameViewModelPropertyChange, this);
        this.bonusGameViewModel.propertyChanged.remove(this.onBonusGameViewModelPropertyChange, this);
        this.containerAnimationTimeline.kill();
        this.openButton.onPress.disconnect(this.onOpenButtonClick);
        this.openButton.destroy();
        super.destroy(options);
    }
}