import { gsap } from "gsap";
import { ColorSource, Graphics, IDestroyOptions } from "pixi.js";
type Timeline = gsap.core.Timeline;

export class Fader extends Graphics {
    private fadeDuration: number;
    private fromAlpha: number;
    private toAlpha: number;
    private animationTimeLine: Timeline;
    private static readonly DEFAULT_COLOR = 0x000122;

    public constructor(width: number, height: number, fadeDuration: number = 1, fromAlpha: number = 0, toAplha = 1, color: ColorSource = Fader.DEFAULT_COLOR) {
        super();
        this.beginFill(color, toAplha);
        this.drawRect(0, 0, width, height);
        this.endFill();
        this.fadeDuration = fadeDuration;
        this.fromAlpha = fromAlpha;
        this.toAlpha = toAplha;
        this.alpha = fromAlpha;
        this.animationTimeLine = new gsap.core.Timeline();
    }

    public async fadeIn(): Promise<void> {
        this.animationTimeLine.clear();
        this.animationTimeLine.to(this, { alpha: this.toAlpha, duration: this.fadeDuration, ease: "none" });
        await this.animationTimeLine.play();
        this.animationTimeLine.clear();
    }

    public async fadeOut(): Promise<void> {
        this.animationTimeLine.to(this, { alpha: this.fromAlpha, duration: this.fadeDuration, ease: "none" });
        await this.animationTimeLine.play();
        this.animationTimeLine.clear();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.animationTimeLine?.kill();
        super.destroy(options);
    }
}
