import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class FreeSpinOpenResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("FreeSpinOpenResourcesPackage");
    }
    public get assets_popups_freeSpinOpen_Spark_png(): Texture {
        return this.resource<Texture>("assets/popups/freeSpinOpen/Spark.png");
    }
    public get assets_popups_freeSpinOpen_popup_png(): Texture {
        return this.resource<Texture>("assets/popups/freeSpinOpen/popup.png");
    }
    public get assets_popups_freeSpinOpen_start_button_png(): Texture {
        return this.resource<Texture>("assets/popups/freeSpinOpen/start_button.png");
    }
}

