import { Texture } from "pixi.js";
import { WinFrameResourcesPackage } from "./WinFrameResourcesPackage";

export class WinFrameAnimationFramesProvider extends WinFrameResourcesPackage {
    private instance: Array<Texture>;

    public get animationFrames(): Array<Texture> {
        if (!this.loaded) {
            throw new Error("You can not use frames provider before actual package is loaded.");
        }
        if (this.instance == null) {
            this.instance = [
                this.assets_winFrame_shot0_png,
                this.assets_winFrame_shot1_png,
                this.assets_winFrame_shot2_png,
                this.assets_winFrame_shot3_png,
                this.assets_winFrame_shot4_png,
                this.assets_winFrame_shot5_png,
                this.assets_winFrame_shot6_png,
                this.assets_winFrame_shot7_png,
                this.assets_winFrame_shot8_png,
                this.assets_winFrame_shot9_png,
                this.assets_winFrame_shot10_png,
                this.assets_winFrame_shot11_png,
                this.assets_winFrame_shot12_png,
                this.assets_winFrame_shot13_png,
                this.assets_winFrame_shot14_png,
                this.assets_winFrame_shot15_png,
                this.assets_winFrame_shot16_png,
                this.assets_winFrame_shot17_png,
                this.assets_winFrame_shot18_png,
                this.assets_winFrame_shot19_png,
                this.assets_winFrame_shot20_png,
                this.assets_winFrame_shot21_png,
                this.assets_winFrame_shot22_png,
                this.assets_winFrame_shot23_png,
                this.assets_winFrame_shot24_png,
                this.assets_winFrame_shot25_png,
                this.assets_winFrame_shot26_png,
                this.assets_winFrame_shot27_png,
                this.assets_winFrame_shot28_png,
                this.assets_winFrame_shot29_png,
                this.assets_winFrame_shot30_png,
                this.assets_winFrame_shot31_png,
                this.assets_winFrame_shot32_png,
                this.assets_winFrame_shot33_png,
                this.assets_winFrame_shot34_png,
                this.assets_winFrame_shot35_png,
                this.assets_winFrame_shot36_png,
                this.assets_winFrame_shot37_png,
                this.assets_winFrame_shot38_png,
                this.assets_winFrame_shot39_png,
                this.assets_winFrame_shot40_png,
                this.assets_winFrame_shot41_png,
                this.assets_winFrame_shot42_png,
                this.assets_winFrame_shot43_png,
                this.assets_winFrame_shot44_png,
                this.assets_winFrame_shot45_png,
                this.assets_winFrame_shot46_png,
                this.assets_winFrame_shot47_png
            ]
        }
        return this.instance;
    }
}