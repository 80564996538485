import { inject, injectable } from "@launchtray/tsyringe-async";
import { SlotModel } from "../models/SlotModel";
import { BigWinSceneFactory, BonusGameFinishSceneFactory, BonusGameOpenSceneFactory, BonusGameSceneFactory, FreeSpinFinishSceneFactory, FreeSpinOpenSceneFactory, InjectableFactories, MobileBetSceneFactory, RegularWinSceneFactory, RulesSceneFactory } from "../../../../composition/InjectableFactories";
import { SceneManager } from "scene-manager";
import { SlotViewModel } from "../models/SlotViewModel";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { IPlayerModel } from "../../../player/IPlayerModel";
import { AudioManager } from "../../../audio/AudioManager";
import { SlotStateType } from "../views/slotStates/SlotStateType";
import { DisabledSlotElement } from "../models/data/DisabledSlotElement";
import { AudioNames } from "../../../audio/AudioNames";

@injectable()
export class SlotController {
    public constructor(
        private readonly slotModel: SlotModel,
        @inject(InjectableFactories.FreeSpinOpenSceneFactory) private readonly freeSpinOpenSceneFactory: FreeSpinOpenSceneFactory,
        @inject(InjectableFactories.FreeSpinFinishSceneFactory) private readonly freeSpinFinishSceneFactory: FreeSpinFinishSceneFactory,
        @inject(InjectableFactories.BonusGameOpenSceneFactory) private readonly bonusGameOpenSceneFactory: BonusGameOpenSceneFactory,
        @inject(InjectableFactories.BonusGameSceneFactory) private readonly bonusGameSceneFactory: BonusGameSceneFactory,
        @inject(InjectableFactories.BonusGameFinishSceneFactory) private readonly bonusGameFinishSceneFactory: BonusGameFinishSceneFactory,
        @inject(InjectableFactories.RegularWinSceneFactory) private readonly regularWinSceneFactory: RegularWinSceneFactory,
        @inject(InjectableFactories.BigWinSceneFactory) private readonly bigWinSceneFactory: BigWinSceneFactory,
        @inject(InjectableFactories.RulesSceneFactory) private readonly rulesSceneFactory: RulesSceneFactory,
        @inject(InjectableFactories.MobileBetSceneFactory) private readonly mobileBetSceneFactory: MobileBetSceneFactory,
        @inject(InjectableInterfaces.IPlayerModel) private readonly playerModel: IPlayerModel,
        private readonly slotViewModel: SlotViewModel,
        private readonly sceneManager: SceneManager,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager
    ) {
    }

    public spin(bet: number): void {
        this.slotModel.makeSpin(bet);
    }

    public async showBonusGameStartPopup(): Promise<void> {
        const bonusGameStartPopup = await this.bonusGameOpenSceneFactory();
        await this.sceneManager.showViewableAsync(bonusGameStartPopup);
    }

    public async showBonusGamePopup(): Promise<void> {
        const bonusGamePopup = await this.bonusGameSceneFactory();
        await this.sceneManager.showViewableAsync(bonusGamePopup);
    }

    public async showBonusGameCompletePopup(): Promise<void> {
        const bonusGameCompletePopup = await this.bonusGameFinishSceneFactory(this.slotModel.bonusGameResult.value.prize);
        await this.sceneManager.showViewableAsync(bonusGameCompletePopup);
    }

    public async showFreeSpinsStartPopup(): Promise<void> {
        const freeSpinsStartScene = await this.freeSpinOpenSceneFactory(this.slotViewModel.freeSpinsCount.value);
        await this.sceneManager.showViewableAsync(freeSpinsStartScene);
    }

    public async showFreeSpinsCompletePopup(): Promise<void> {
        const freeSpinsCompleteScene = await this.freeSpinFinishSceneFactory(this.slotViewModel.freeSpinModePrize);
        await this.sceneManager.showViewableAsync(freeSpinsCompleteScene);
    }

    public async showRegularWinPopup(): Promise<void> {
        const regularWinScene = await this.regularWinSceneFactory(this.slotModel, this.slotViewModel);
        await this.sceneManager.showViewableAsync(regularWinScene);
    }

    public async showBigWinPopup(): Promise<void> {
        const bigWinScene = await this.bigWinSceneFactory(this.slotModel, this.slotViewModel);
        await this.sceneManager.showViewableAsync(bigWinScene);
    }

    public toggleAutoSpin(): void {
        this.slotViewModel.toggleAutoSpin();
    }

    public turnOnAutoSpin(): void {
        if (this.slotViewModel.autoSpinEnabled.value === false) {
            this.slotViewModel.toggleAutoSpin();
        }
    }

    public turnOffAutoSpin(): void {
        if (this.slotViewModel.autoSpinEnabled.value === true) {
            this.slotViewModel.toggleAutoSpin();
        }
    }

    public async tryBet(bet: number): Promise<boolean> {
        return await this.playerModel.tryBet(bet);
    }

    public invalidatePlayer(balance: number): void {
        this.playerModel.updatePlayer(balance);
    }

    public switchSound(isOn: boolean): void {
        this.audioManager.playAllSounds = isOn;
    }

    public async showRulesPopup() {
        const rulesScene = await this.rulesSceneFactory();
        await this.sceneManager.showViewableAsync(rulesScene);
    }

    public setSpinAnimationInProgress(value: boolean) {
        this.slotViewModel.spinAnimationInProgress.setValue(value);
    }

    public disableSlotElements(elementsToDisable: Array<DisabledSlotElement>) {
        this.slotViewModel.disableSlotElements(elementsToDisable);
    }

    public enableSlotElements(elementsToEnable: Array<DisabledSlotElement>) {
        this.slotViewModel.enableSlotElements(elementsToEnable);
    }

    public changeSlotViewState(slotStateType: SlotStateType): void {
        this.slotViewModel.changeSlotViewState(slotStateType);
    }

    public stopReelsFast(): void {
        this.slotViewModel.setFastReelsStop(true);
    }

    public restoreStopReelsFast(): void {
        this.slotViewModel.setFastReelsStop(false);
    }

    public startFreeSpinsMode(): void {
        this.slotViewModel.setFreeSpinsMode(true);
    }

    public completeFreeSpinsMode(): void {
        this.slotViewModel.setFreeSpinsMode(false);
    }

    public playBackgroundMusic(musicName: AudioNames): void {
        this.audioManager.play(musicName);
    }

    public stopBackgroundMusic(musicName: AudioNames): void {
        this.audioManager.stop(musicName);
    }

    public async showMobileBetPopup(initialTotalBet: number): Promise<number> {
        const mobileBetPopup = await this.mobileBetSceneFactory(initialTotalBet);
        return await this.sceneManager.showViewableAsync(mobileBetPopup);
    }

}
