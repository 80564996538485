import { Emitter } from "@pixi/particle-emitter";
import { Container, Texture } from "pixi.js";
import { CanvasScaler } from "../../../../../canvas/CanvasScaler";
import { BubblesEffectConfig } from "./BubblesEffectConfig";

export class BubblesEffect extends Container {
    private fisrtBubblesEffect: Emitter;
    private secondBubblesEffect: Emitter;
    private thirdBubblesEffect: Emitter;
    private fourthBubblesEffect: Emitter;

    public constructor(private readonly canvasScaler: CanvasScaler, private readonly texture: Texture) {
        super();
        this.build();
        this.layout();
    }

    private build(): void {
        let fisrtConfig = new BubblesEffectConfig(this.texture, 0.2, 0.3, 1, 300);
        let secondConfig = new BubblesEffectConfig(this.texture, 0.175, 0.275, 1.5, 300);
        this.fisrtBubblesEffect = new Emitter(this, fisrtConfig.EmitterConfig);
        this.secondBubblesEffect = new Emitter(this, secondConfig.EmitterConfig);
        this.thirdBubblesEffect = new Emitter(this, secondConfig.EmitterConfig);
        this.fourthBubblesEffect = new Emitter(this, fisrtConfig.EmitterConfig);
    }

    private layout(): void {
        this.fisrtBubblesEffect.spawnPos.set(0, this.canvasScaler.referenceHeight);
        this.secondBubblesEffect.spawnPos.set(600, this.canvasScaler.referenceHeight);
        this.thirdBubblesEffect.spawnPos.set(1100, this.canvasScaler.referenceHeight);
        this.fourthBubblesEffect.spawnPos.set(this.canvasScaler.referenceWidth - 300, this.canvasScaler.referenceHeight);
    }
}