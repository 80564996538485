import { WinLineDto } from "./response/WinLineDto";

export class WinLine {
    public readonly line: number[];
    public readonly matched: {
        symbol: number;
        length: number;
    };

    public constructor(dto: WinLineDto) {
        if (dto.line == null || dto.line.length == 0) {
            throw new Error("Failed to convert WinLineDto to WinLine. Dto 'line' has wrong data.");
        }
        if (dto.matched == null || isNaN(dto.matched.symbol) || isNaN(dto.matched.length)) {
            throw new Error("Failed to convert WinLineDto to WinLine. Dto 'matched' has wrong data.");
        }
        this.line = dto.line;
        this.matched = dto.matched;
    }
}