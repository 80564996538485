import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class WinFrameResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("WinFrameResourcesPackage");
    }
    public get assets_winFrame_winFrame_0_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/winFrame/winFrame-0_1x.atlas.json");
    }
    public get assets_winFrame_shot0_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot0.png"];
    }
    public get assets_winFrame_shot1_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot1.png"];
    }
    public get assets_winFrame_shot2_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot2.png"];
    }
    public get assets_winFrame_shot3_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot3.png"];
    }
    public get assets_winFrame_shot45_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot45.png"];
    }
    public get assets_winFrame_shot46_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot46.png"];
    }
    public get assets_winFrame_shot47_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot47.png"];
    }
    public get assets_winFrame_shot11_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot11.png"];
    }
    public get assets_winFrame_shot12_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot12.png"];
    }
    public get assets_winFrame_shot13_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot13.png"];
    }
    public get assets_winFrame_shot14_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot14.png"];
    }
    public get assets_winFrame_shot15_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot15.png"];
    }
    public get assets_winFrame_shot16_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot16.png"];
    }
    public get assets_winFrame_shot36_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot36.png"];
    }
    public get assets_winFrame_shot37_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot37.png"];
    }
    public get assets_winFrame_shot38_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot38.png"];
    }
    public get assets_winFrame_shot39_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot39.png"];
    }
    public get assets_winFrame_shot4_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot4.png"];
    }
    public get assets_winFrame_shot44_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot44.png"];
    }
    public get assets_winFrame_shot10_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot10.png"];
    }
    public get assets_winFrame_shot20_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot20.png"];
    }
    public get assets_winFrame_shot21_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot21.png"];
    }
    public get assets_winFrame_shot23_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot23.png"];
    }
    public get assets_winFrame_shot24_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot24.png"];
    }
    public get assets_winFrame_shot25_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot25.png"];
    }
    public get assets_winFrame_shot28_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot28.png"];
    }
    public get assets_winFrame_shot5_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot5.png"];
    }
    public get assets_winFrame_shot6_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot6.png"];
    }
    public get assets_winFrame_shot7_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot7.png"];
    }
    public get assets_winFrame_shot17_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot17.png"];
    }
    public get assets_winFrame_shot18_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot18.png"];
    }
    public get assets_winFrame_shot19_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot19.png"];
    }
    public get assets_winFrame_shot26_png(): Texture {
        return this.assets_winFrame_winFrame_0_1x_atlas_json.textures["shot26.png"];
    }
    public get assets_winFrame_winFrame_0_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/winFrame/winFrame-0_1x.atlas.png");
    }
    public get assets_winFrame_winFrame_1_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/winFrame/winFrame-1_1x.atlas.json");
    }
    public get assets_winFrame_shot22_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot22.png"];
    }
    public get assets_winFrame_shot27_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot27.png"];
    }
    public get assets_winFrame_shot29_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot29.png"];
    }
    public get assets_winFrame_shot30_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot30.png"];
    }
    public get assets_winFrame_shot33_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot33.png"];
    }
    public get assets_winFrame_shot34_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot34.png"];
    }
    public get assets_winFrame_shot35_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot35.png"];
    }
    public get assets_winFrame_shot40_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot40.png"];
    }
    public get assets_winFrame_shot41_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot41.png"];
    }
    public get assets_winFrame_shot42_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot42.png"];
    }
    public get assets_winFrame_shot43_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot43.png"];
    }
    public get assets_winFrame_shot8_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot8.png"];
    }
    public get assets_winFrame_shot9_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot9.png"];
    }
    public get assets_winFrame_shot31_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot31.png"];
    }
    public get assets_winFrame_shot32_png(): Texture {
        return this.assets_winFrame_winFrame_1_1x_atlas_json.textures["shot32.png"];
    }
    public get assets_winFrame_winFrame_1_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/winFrame/winFrame-1_1x.atlas.png");
    }
}

