import { Texture } from "pixi.js";
import { ChestAnimationResourcesPackage } from "./ChestAnimationResourcesPackage";
export class ChestAnimationFramesProvider extends ChestAnimationResourcesPackage {
    private instance: Array<Texture>;

    public get animationFrames(): Array<Texture> {
        if (!this.loaded) {
            throw new Error("You can not use frames provider before actual package is loaded.");
        }
        if (this.instance == null) {
            this.instance = [
                this.assets_chestAnimation_chestAnimation_0_png,
                this.assets_chestAnimation_chestAnimation_1_png,
                this.assets_chestAnimation_chestAnimation_2_png,
                this.assets_chestAnimation_chestAnimation_3_png,
                this.assets_chestAnimation_chestAnimation_4_png,
                this.assets_chestAnimation_chestAnimation_5_png,
                this.assets_chestAnimation_chestAnimation_6_png,
                this.assets_chestAnimation_chestAnimation_7_png,
                this.assets_chestAnimation_chestAnimation_8_png,
                this.assets_chestAnimation_chestAnimation_9_png,
                this.assets_chestAnimation_chestAnimation_10_png,
                this.assets_chestAnimation_chestAnimation_11_png,
                this.assets_chestAnimation_chestAnimation_12_png,
                this.assets_chestAnimation_chestAnimation_13_png,
                this.assets_chestAnimation_chestAnimation_14_png,
                this.assets_chestAnimation_chestAnimation_15_png,
                this.assets_chestAnimation_chestAnimation_16_png,
                this.assets_chestAnimation_chestAnimation_17_png,
                this.assets_chestAnimation_chestAnimation_18_png,
                this.assets_chestAnimation_chestAnimation_19_png,
                this.assets_chestAnimation_chestAnimation_20_png,
                this.assets_chestAnimation_chestAnimation_21_png,
                this.assets_chestAnimation_chestAnimation_22_png,
                this.assets_chestAnimation_chestAnimation_23_png,
                this.assets_chestAnimation_chestAnimation_24_png,
                this.assets_chestAnimation_chestAnimation_25_png,
                this.assets_chestAnimation_chestAnimation_26_png,
                this.assets_chestAnimation_chestAnimation_27_png,
                this.assets_chestAnimation_chestAnimation_28_png,
                this.assets_chestAnimation_chestAnimation_29_png,
                this.assets_chestAnimation_chestAnimation_30_png,
                this.assets_chestAnimation_chestAnimation_31_png,
                this.assets_chestAnimation_chestAnimation_32_png,
                this.assets_chestAnimation_chestAnimation_33_png,
                this.assets_chestAnimation_chestAnimation_34_png,
                this.assets_chestAnimation_chestAnimation_35_png,
                this.assets_chestAnimation_chestAnimation_36_png,
                this.assets_chestAnimation_chestAnimation_37_png,
                this.assets_chestAnimation_chestAnimation_38_png,
                this.assets_chestAnimation_chestAnimation_39_png,
                this.assets_chestAnimation_chestAnimation_40_png,
                this.assets_chestAnimation_chestAnimation_41_png,
                this.assets_chestAnimation_chestAnimation_42_png,
                this.assets_chestAnimation_chestAnimation_43_png,
                this.assets_chestAnimation_chestAnimation_44_png,
                this.assets_chestAnimation_chestAnimation_45_png,
                this.assets_chestAnimation_chestAnimation_46_png,
                this.assets_chestAnimation_chestAnimation_47_png,
                this.assets_chestAnimation_chestAnimation_48_png,
                this.assets_chestAnimation_chestAnimation_49_png
            ]
        }
        return this.instance;
    }
}

