import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class BonusGameFinishResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("BonusGameFinishResourcesPackage");
    }
    public get assets_popups_bonusGameFinish_Button_BonusGame_Finish_png(): Texture {
        return this.resource<Texture>("assets/popups/bonusGameFinish/Button_BonusGame_Finish.png");
    }
    public get assets_popups_bonusGameFinish_Popup_BonusGame_Finish_png(): Texture {
        return this.resource<Texture>("assets/popups/bonusGameFinish/Popup_BonusGame_Finish.png");
    }
}

