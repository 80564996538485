import { Container, Sprite, Texture, Rectangle, IDestroyOptions } from "pixi.js";
import { PreloaderModel } from "../models/PreloaderModel";
import { nameof } from "bindable-data/dist/utils/Nameof";

export class ProgressBar extends Container {
    private progress: Sprite;
    private progressStart: Sprite;
    private progressEnd: Sprite;
    private bar: Sprite;
    private static readonly LOADING_LINE_OFFSET = 3;

    public constructor(
        private readonly preloaderModel: PreloaderModel,
        private readonly progressTexture: Texture,
        private readonly barTexture: Texture,
    ) {
        super();
        this.preloaderModel = preloaderModel;
        this.preloaderModel.propertyChanged.add(this.setProgress, this);
        this.build();
        this.layout();
    }

    private build(): void {
        this.bar = new Sprite(this.barTexture);
        this.progressStart = new Sprite(this.progressTexture.clone());
        this.progressStart.scale.x = 1;
        this.progressStart.pivot.set(0, this.progressStart.height * 0.5);
        this.progressStart.texture.frame = new Rectangle(0, 0, this.progressStart.texture.height * 0.5, this.progressStart.height);
        this.bar.addChild(this.progressStart);
        this.progress = new Sprite(this.progressTexture.clone());
        this.progress.scale.x = 0.01;
        this.progress.pivot.set(0, this.progress.height * 0.5);
        this.progress.texture.frame = new Rectangle(this.progress.texture.height * 0.5, 0, this.progress.texture.width - this.progress.texture.height * 0.5 - this.progress.texture.height, this.progressStart.height);
        this.bar.addChild(this.progress);
        this.progressEnd = new Sprite(this.progressTexture.clone());
        this.progressEnd.scale.x = 1;
        this.progressEnd.pivot.set(0, this.progressEnd.height * 0.5);
        this.progressEnd.texture.frame = new Rectangle(this.progressEnd.texture.width - this.progressEnd.texture.height * 0.5, 0, this.progressEnd.texture.height * 0.5, this.progressStart.height);
        this.bar.addChild(this.progressEnd);
        this.addChild(this.bar);
    }

    private layout(): void {
        this.progressStart.position.set(ProgressBar.LOADING_LINE_OFFSET, this.progressStart.height * 0.5 + ProgressBar.LOADING_LINE_OFFSET);
        this.progress.position.set(this.progressStart.texture.height * 0.5 + ProgressBar.LOADING_LINE_OFFSET, this.progress.height * 0.5 + ProgressBar.LOADING_LINE_OFFSET);
        this.progressEnd.position.set(this.bar.width + ProgressBar.LOADING_LINE_OFFSET, this.progressEnd.height * 0.5 + ProgressBar.LOADING_LINE_OFFSET);
    }

    private setProgress(model: PreloaderModel, key: string): void {
        if (key === nameof(model, "loadingProgress")) {
            this.progress.scale.x = Math.min(Math.max(model.loadingProgress.value, 0), 1) * (this.bar.width) / (this.progress.texture.width + this.progressStart.texture.width + this.progressEnd.texture.width * 1.5 - ProgressBar.LOADING_LINE_OFFSET);
            this.progressEnd.position.x = this.progress.width + this.progress.position.x;
            if (model.loadingProgress.value == 1) {
                this.bar.visible = false;
            }
        }
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.preloaderModel.propertyChanged.remove(this.setProgress, this);
        super.destroy(options);
    }
}
