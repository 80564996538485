import { Container, Graphics, Sprite, Texture } from "pixi.js";
import { UIResourcesPackage } from "./resources/UIResourcesPackage";
import { BetField } from "./components/BetField";
import { WinField } from "./components/WinField";
import { SpinButtonsContainer } from "./components/SpinButtonsContainer";
import { BalanceField } from "./components/BalanceField";
import { InfoContainer } from "./components/InfoContainer";
import { IPlayerModel } from "../../../../player/IPlayerModel";
import { SlotController } from "../../controllers/SlotController";
import { SlotViewModel } from "../../models/SlotViewModel";
import { Event } from "event-system";
import { SoundContainer } from "./components/SoundContainer";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { ToggleButtonFactory } from "../../../../factories/ToggleButtonFactory";
import { IAudioManager } from "../../../../audio/IAudioManager";
import { ISlotControlPanel } from "./ISlotControlPanel";

export class SlotControlPanel extends Container implements ISlotControlPanel {
    private container: Graphics;
    private betField: BetField;
    private winField: WinField;
    private spinContainer: SpinButtonsContainer;
    private balanceField: BalanceField;
    private infoContainer: InfoContainer;
    public onSpin: Event<void>;
    private soundContainer: SoundContainer;

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly player: IPlayerModel,
        private readonly slotController: SlotController,
        private readonly slotViewModel: SlotViewModel,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        private readonly toggleButtonFactory: ToggleButtonFactory,
        private readonly audioManager: IAudioManager,
        private readonly initialTotalBet: number = 0.2,
        private readonly initialWinField: number = 0,
    ) {
        super();
        this.onSpin = new Event();
        this.build();
        this.layout();
    }

    private build() {
        this.container = new Graphics();
        this.container.beginFill(0xffffff, 0.001);
        this.container.drawRect(0, 0, 1350, 190);
        this.container.endFill();
        this.addChild(this.container);
        this.betField = new BetField(this.resources, this.defaultHoverHighlightButtonFactory, this.slotViewModel, this.initialTotalBet);
        this.container.addChild(this.betField);
        this.winField = new WinField(this.resources, this.slotViewModel, this.initialWinField);
        this.container.addChild(this.winField);
        this.spinContainer = new SpinButtonsContainer(this.resources, this.slotController, this.slotViewModel, this.defaultHoverHighlightButtonFactory);
        this.spinContainer.onSpinClick.add(this.onSpinClickEmit, this);
        this.container.addChild(this.spinContainer);
        this.balanceField = new BalanceField(this.resources, this.player);
        this.container.addChild(this.balanceField);
        this.infoContainer = new InfoContainer(this.resources, this.slotController, this.defaultHoverHighlightButtonFactory, this.slotViewModel);
        this.container.addChild(this.infoContainer);
        this.soundContainer = new SoundContainer(this.resources, this.slotController, this.toggleButtonFactory, this.audioManager.playAllSounds);
        this.container.addChild(this.soundContainer);
    }

    private layout() {
        this.betField.position.set((this.container.width - this.betField.width) * 0.5, 0);
        this.winField.position.set(900, 0);
        this.spinContainer.position.set(this.container.width - this.spinContainer.width, (this.container.height - this.spinContainer.height) * 0.5);
        this.balanceField.position.set(215, 0);
        this.infoContainer.position.set(0, (this.container.height - this.infoContainer.height) * 0.5);
        this.soundContainer.position.set(this.infoContainer.width + 2, (this.container.height - this.soundContainer.height) * 0.5);
    }

    public getTotalBet(): number {
        return this.betField.totalBet;
    }

    private onSpinClickEmit() {
        this.onSpin.emit();
    }

}