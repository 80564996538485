import { WinLine } from "./WinLine";
import { SpinWinningDto } from "./response/SpinWinningDto";

export class SpinWinning {
    public readonly prize: number;
    public readonly isBigWin: boolean;
    public readonly winLines: Array<WinLine>;
    public readonly isFreeSpinsWin: boolean;
    public readonly isBonusGameWin: boolean;
    public readonly freeSpinCount: number;
    public readonly freeSpinsTotalPrize: number;

    public constructor(dto: SpinWinningDto, dropLastWinField: boolean) {
        if (dto.prize == null || isNaN(dto.prize)) {
            throw new Error("Failed to convert SpinWinningDto to SpinWinning. Dto 'prize' has wrong data.");
        }
        if (dto.isBigWin == null) {
            throw new Error("Failed to convert SpinWinningDto to SpinWinning. Dto 'isBigWin' has wrong data.");
        }
        if (dto.winLines == null || (dto.prize > 0 && dto.winLines.length == 0)) {
            throw new Error("Failed to convert SpinWinningDto to SpinWinning. Dto 'winLines' has wrong data.");
        }
        if (dto.isFreeSpinsWin == null) {
            throw new Error("Failed to convert SpinWinningDto to SpinWinning. Dto 'isFreeSpinsWin' has wrong data.");
        }
        if (dto.freeSpinCount == null) {
            throw new Error("Failed to convert SpinWinningDto to SpinWinning. Dto 'freeSpinCount' has wrong data.");
        }
        if (dto.isBonusGameWin == null) {
            throw new Error("Failed to convert SpinWinningDto to SpinWinning. Dto 'isBonusGameWin' has wrong data.");
        }
        this.prize = dropLastWinField ? 0 : dto.prize;
        this.isBigWin = dto.isBigWin;
        this.winLines = new Array();
        for (const winLineDto of dto.winLines) {
            this.winLines.push(new WinLine(winLineDto));
        }
        this.isFreeSpinsWin = dto.isFreeSpinsWin;
        this.freeSpinCount = dto.freeSpinCount;
        this.freeSpinsTotalPrize = dto.freeSpinsTotalPrize;
        this.isBonusGameWin = dto.isBonusGameWin;
    }
}