import { ScrollBox, ScrollBoxOptions } from "@pixi/ui";
import { ObservablePoint } from "pixi.js";
import { Signal } from 'typed-signals';

export class UIScrollBox extends ScrollBox {
    public onScroll: Signal<(value: number) => void>;
    private previoutEventScrollValue: number;

    public constructor(options?: ScrollBoxOptions) {
        super(options);
        this.onScroll = new Signal();
        this.setupListPositionChangeHandler();
    }

    private setupListPositionChangeHandler(): void {
        const callback = this.list.transform.position.cb.bind(this.list.transform);
        this.list.transform.position = new ObservablePoint(() => {
            this.onListPositionChange();
            callback();
        }, this, this.list.x, this.list.y);
    }

    private onListPositionChange(): void {
        const scrollValue = this.options.type === "horizontal" ? this._trackpad.x : this._trackpad.y;
        if (this.previoutEventScrollValue === scrollValue) {
            return;
        }
        this.previoutEventScrollValue = scrollValue;
        this.onScroll.emit(-this.previoutEventScrollValue);
    }
}