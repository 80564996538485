import { PromiseCompletionSource } from "scene-manager";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { IDestroyOptions, Sprite } from "pixi.js";
import { BonusGameOpenResourcesPackage } from "../resources/BonusGameOpenResourcesPackage";
import { IFocusable } from "../../../../layers/IFocusable";
import { InOutAnimatedPopup } from "../../../common/views/InOutAnimatedPopup";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../../audio/AudioManager";
import { DeviceCheckUtils } from "../../../common/utils/DeviceCheckUtils";
import { ClosePopupOnClickPanel } from "../../../common/views/ClosePopupOnClickPanel";
import { CanvasScaler } from "../../../../../canvas/CanvasScaler";

export class BonusGameOpenView extends InOutAnimatedPopup<void> implements IFocusable {
    public readonly focusable: boolean = true;
    private playButton: DefaultHoverHighlightButton;
    private substrate: Sprite;
    private onScreenChangeHandler: () => void;
    private closePopupOnClickPanel: ClosePopupOnClickPanel;

    public constructor(
        private readonly canvasScaler: ICanvasScaler,
        private readonly bonusGameOpenResources: BonusGameOpenResourcesPackage,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        audioManager: AudioManager,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(audioManager, operationControls);
        this.onPlayButtonClick = this.onPlayButtonClick.bind(this);
        this.onScreenChangeHandler = this.layout.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenChangeHandler);
        this.build();
        this.layout();
    }

    private build(): void {
        this.substrate = new Sprite(this.bonusGameOpenResources.assets_popups_bonusGameOpen_Popup_BonusGame_Open_png);
        const spriteButton = new Sprite(this.bonusGameOpenResources.assets_popups_bonusGameOpen_Button_BonusGame_Open_png);
        this.playButton = this.defaultHoverHighlightButtonFactory.createButton(spriteButton);
        this.playButton.onPress.connect(this.onPlayButtonClick);
        this.addChild(this.substrate);
        this.closePopupOnClickPanel = new ClosePopupOnClickPanel(this.canvasScaler as CanvasScaler);
        this.closePopupOnClickPanel.onClick.connect(this.onPlayButtonClick);
        this.addChild(this.closePopupOnClickPanel);
        this.substrate.addChild(this.playButton.view);
    }

    private layout(): void {
        const referenceWidth = this.canvasScaler.referenceWidth;
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.scale.set(1.5);
            this.substrate.position.set((referenceWidth - this.substrate.width) * 0.125, 220);
        } else {
            this.scale.set(1);
            this.substrate.position.set((referenceWidth - this.substrate.width) * 0.5, 120);
        }
        this.playButton.view.position.set(this.substrate.width * 0.5 - this.playButton.view.width * 0.5, 730);
    }

    private onPlayButtonClick(): void {
        this.closePopupOnClickPanel.eventMode = 'none';
        this.operationControls.complete();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.playButton.onPress.disconnect(this.onPlayButtonClick);
        this.closePopupOnClickPanel.onClick.disconnect(this.onPlayButtonClick);
        this.playButton.destroy();
        window.screen.orientation.removeEventListener("change", this.onScreenChangeHandler);
        super.destroy(options);
    }
}
