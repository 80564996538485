import { Bindable, NotifyProperty, nameof } from "bindable-data";
import { SlotModel } from "../../../slot/models/SlotModel";
import { injectable } from "@launchtray/tsyringe-async";

@injectable()
export class BonusGameViewModel extends Bindable {
    public readonly resultReceived: NotifyProperty<boolean>;
    public readonly mainAnimationFinished: NotifyProperty<boolean>;
    public readonly secondaryAnimationFinished: NotifyProperty<boolean>;
    public readonly finishAnimation: NotifyProperty<boolean>;

    public constructor(
        private readonly slotModel: SlotModel
    ) {
        super();
        this.resultReceived = new NotifyProperty<boolean>(this, nameof(this, "resultReceived"), false);
        this.mainAnimationFinished = new NotifyProperty<boolean>(this, nameof(this, "mainAnimationFinished"), false);
        this.secondaryAnimationFinished = new NotifyProperty<boolean>(this, nameof(this, "secondaryAnimationFinished"), false);
        this.finishAnimation = new NotifyProperty<boolean>(this, nameof(this, "finishAnimation"), false);
        this.slotModel.propertyChanged.add(this.onBonusGameModelChanged, this);
    }

    private onBonusGameModelChanged(bonusGameModel: SlotModel, propertyName: string) {
        if (propertyName === nameof(bonusGameModel, "bonusGameResult")) {
            if (bonusGameModel.bonusGameResult.value != null) {
                this.resultReceived.setValue(true);
            }
        }
    }

    public getMultiplierValueByChestId(chestId: number): number {
        if (!this.slotModel.bonusGameResult.value || this.slotModel.bonusGameResult.value.otherMultipliers.length === 0) {
            throw new Error("Failed to get bonus game otherMultipliers.");
        }
        return this.slotModel.bonusGameResult.value.otherMultipliers.find(value => value.chestId === chestId).multiplierValue;
    }

    public restoreDefaults() {
        this.resultReceived.setValue(false);
        this.mainAnimationFinished.setValue(false);
        this.secondaryAnimationFinished.setValue(false);
        this.finishAnimation.setValue(false);
    }

    public destroy(): void {
        this.slotModel.propertyChanged.remove(this.onBonusGameModelChanged, this);
    }

}