import { inject, injectable } from "@launchtray/tsyringe-async";
import { SceneManagerWrapper } from "by/gamefactory/marinedice/SceneManagerWrapper";
import { Sprite, Texture } from "pixi.js";
import { SceneManager } from "scene-manager";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { Scales } from "../../../../canvas/CanvasScaler";

@injectable()
export class FullscreenBackground extends Sprite {
    private readonly canvasScaler: ICanvasScaler;

    public constructor(
        sceneManager: SceneManager,
        @inject(InjectableInterfaces.ICanvasScaler) canvasScaler: ICanvasScaler
    ) {
        super();
        this.canvasScaler = canvasScaler;
        (sceneManager as SceneManagerWrapper).backgroundLayer.addChild(this);
        this.canvasScaler.onResize.add(this.onResize, this);
    }

    public updateImage(texture: Texture): void {
        this.texture = texture;
        this.layout(this.canvasScaler.browserWidth, this.canvasScaler.browserHeight);
    }

    private onResize(_scales: Scales): void {
        this.layout(this.canvasScaler.browserWidth, this.canvasScaler.browserHeight);
    }

    private layout(width: number, height: number): void {
        const ratio = this.texture.height / this.texture.width;
        if (width > height) {
            this.width = width;
            this.height = width / ratio;
        }
        else {
            this.height = height;
            this.width = height * ratio;
        }
    }
}