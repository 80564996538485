import { inject, injectable } from "@launchtray/tsyringe-async";
import { IDataStorage } from "./IDataStorage";
import { InjectableInterfaces } from "../composition/InjectableInterfaces";

@injectable()
export class VariableSaver {
    private storageEngine: IDataStorage;

    public constructor(@inject(InjectableInterfaces.IDataStorage) storageEngine: IDataStorage) {
        this.storageEngine = storageEngine;
    }

    public exist(name: string): boolean {
        return this.storageEngine.exist(name);
    }

    public saveBoolean(name: string, value: boolean): void {
        this.storageEngine.saveBoolean(name, value);
    }

    public saveNumber(name: string, value: number): void {
        this.storageEngine.saveNumber(name, value);
    }

    public saveString(name: string, value: string): void {
        this.storageEngine.saveString(name, value);
    }

    public getBoolean(name: string): boolean {
        return this.storageEngine.getBoolean(name);
    }

    public getNumber(name: string): number {
        return this.storageEngine.getNumber(name);
    }

    public getString(name: string): string {
        return this.storageEngine.getString(name);
    }

    public removeItem(name: string): void {
        return this.storageEngine.removeItem(name);
    }
}