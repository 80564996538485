import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { INetworkClient } from "../../../../network/INetworkClient";
import { Bindable } from "bindable-data/dist/binding_data/Bindable";
import { NotifyProperty } from "bindable-data/dist/binding_data/NotifyProperty";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { SpinResultDto } from "./data/response/SpinResultDto";
import { SpinRequestDto } from "./data/request/SpinRequestDto";
import { SpinResult } from "./data/SpinResult";
import { nameof } from "bindable-data";
import { BonusGameResult } from "./data/BonusGameResult";
import { BonusGameResponseDto } from "./data/response/BonusGameResponseDto";
import { BonusGameRequestDto } from "./data/request/BonusGameRequestDto";


@injectable()
export class SlotModel extends Bindable {
    private static readonly MAIN_GAME_SPIN = "main-game/spin";
    private static readonly MAIN_GAME_FREE_SPIN = "main-game/spin";
    private static readonly MAIN_GAME_BONUS_URL = "main-game/bonus";
    private static readonly MAIN_GAME_RESTORE_URL = "main-game/restore";
    public readonly reelsState: NotifyProperty<Array<number>>;
    public readonly spinResult: NotifyProperty<SpinResult>;
    public readonly freeSpinMode: NotifyProperty<boolean>;
    public readonly freeSpinsCount: NotifyProperty<number>;
    public readonly bonusGameActive: NotifyProperty<boolean>;
    public readonly bonusGameResult: NotifyProperty<BonusGameResult>;

    public constructor(
        @inject(InjectableInterfaces.INetworkClient) private readonly networkClient: INetworkClient
    ) {
        super();
        this.networkClient = networkClient;
        this.reelsState = new NotifyProperty(this, 'reelsState', null);
        this.freeSpinsCount = new NotifyProperty(this, 'freeSpinsCount', 0);
        this.spinResult = new NotifyProperty<SpinResult>(this, nameof(this, "spinResult"), null);
        this.freeSpinMode = new NotifyProperty<boolean>(this, nameof(this, "freeSpinMode"), false);
        this.bonusGameActive = new NotifyProperty<boolean>(this, nameof(this, "bonusGameActive"), false);
        this.bonusGameResult = new NotifyProperty<BonusGameResult>(this, nameof(this, "bonusGameResult"), null);
    }

    @initializer()
    public async initialize(): Promise<void> {
        const lastSpin = await this.networkClient.get<SpinResultDto>(SlotModel.MAIN_GAME_RESTORE_URL, {});
        this.reelsState.setValue(lastSpin.field);
        const spinResult = new SpinResult(lastSpin, true);
        this.spinResult.setValue(spinResult);
        this.bonusGameActive.setValue(spinResult.winning.isBonusGameWin);
        this.freeSpinMode.setValue(spinResult.winning.freeSpinCount > 0);
        this.freeSpinsCount.setValue(spinResult.winning.freeSpinCount);
    }

    public async makeSpin(bet: number): Promise<void> {
        let result: SpinResultDto;
        if (this.freeSpinMode.value) {
            result = await this.networkClient.post<SpinResultDto, SpinRequestDto>(SlotModel.MAIN_GAME_FREE_SPIN, { bet: bet });
            this.freeSpinsCount.setValue(this.freeSpinsCount.value - 1);
            if (this.freeSpinsCount.value == 0) {
                this.freeSpinMode.setValue(false);
            }
        } else {
            result = await this.networkClient.post<SpinResultDto, SpinRequestDto>(SlotModel.MAIN_GAME_SPIN, { bet: bet });
        }
        const spinResult = new SpinResult(result);
        if (spinResult.winning.isFreeSpinsWin) {
            this.freeSpinMode.setValue(true);
            this.freeSpinsCount.setValue(this.freeSpinsCount.value + spinResult.winning.freeSpinCount);
        }
        if (spinResult.winning.isBonusGameWin) {
            this.bonusGameActive.setValue(true);
        }
        this.reelsState.setValue(result.field);
        this.spinResult.setValue(spinResult);
    }

    public async getBonus(id: number): Promise<void> {
        const dto = await this.networkClient.post<BonusGameResponseDto, BonusGameRequestDto>(SlotModel.MAIN_GAME_BONUS_URL, { id });
        const bonusGameResult = new BonusGameResult(dto);
        this.bonusGameActive.setValue(false);
        this.bonusGameResult.setValue(bonusGameResult);
    }

}
