import { container, DependencyContainer, InjectionToken, Lifecycle } from "@launchtray/tsyringe-async";
import { SceneManager } from "scene-manager";
import { ICanvasScaler } from "../canvas/ICanvasScaler";
import { HttpTransport } from "../network/HttpTransport";
import { NetworkClient } from "../network/NetworkClient";
import { PreloaderScene } from "../marinedice/scenes/preloader/PreloaderScene";
import { UserModelStub } from "../marinedice/user/UserModelStub";
import { PreloaderModel } from "../marinedice/scenes/preloader/models/PreloaderModel";
import { InjectableInterfaces } from "./InjectableInterfaces";
import { InjectableFactories } from "./InjectableFactories";
import { SlotScene } from "../marinedice/scenes/slot/SlotScene";
import { SlotModel } from "../marinedice/scenes/slot/models/SlotModel";
import { SlotController } from "../marinedice/scenes/slot/controllers/SlotController";
import { PackageRegistrator } from "../marinedice/resources/PackageRegistrator";
import { ClientConfigProvider } from "../network/clientConfig/ClientConfigProvider";
import { GuestAuthModel } from "../marinedice/auth/GuestAuthModel";
import { VariableSaver } from "../tools/VariableSaver";
import { LocalStorage } from "../tools/LocalStorage";
import { FullscreenBackground } from "../marinedice/scenes/common/components/FullscreenBackground";
import { GameConfig } from "../marinedice/GameConfig";
import { PreloaderController } from "../marinedice/scenes/preloader/controllers/PreloaderContorller";
import { FreeSpinOpenScene } from "../marinedice/scenes/popups/freeSpinOpen/FreeSpinOpenScene";
import { FreeSpinFinishScene } from "../marinedice/scenes/popups/freeSpinFinish/FreeSpinFinishScene";
import { BonusGameOpenScene } from "../marinedice/scenes/popups/bonusGameOpen/BonusGameOpenScene";
import { BonusGameFinishScene } from "../marinedice/scenes/popups/bonusGameFinish/BonusGameFinishScene";
import { RegularWinScene } from "../marinedice/scenes/popups/regularWin/RegularWinScene";
import { BigWinScene } from "../marinedice/scenes/popups/bigWin/BigWinScene";
import { AudioManager } from "../marinedice/audio/AudioManager";
import { PlayerModel } from "../marinedice/player/PlayerModel";
import { SlotViewModel } from "../marinedice/scenes/slot/models/SlotViewModel";
import { RulesScene } from "../marinedice/scenes/popups/rules/RulesScene";
import { BonusGameScene } from "../marinedice/scenes/popups/bonusGame/BonusGameScene";
import { BonusGameController } from "../marinedice/scenes/popups/bonusGame/controllers/BonusGameController";
import { BonusGameViewModel } from "../marinedice/scenes/popups/bonusGame/models/BonusGameViewModel";
import { DefaultHoverHighlightButtonFactory } from "../marinedice/factories/DefaultHoverHighlightButtonFactory";
import { ToggleButtonFactory } from "../marinedice/factories/ToggleButtonFactory";
import { MobileBetScene } from "../marinedice/scenes/popups/mobileBet/MobileBetScene";

export class MarineDiceContext {
    private internalContainer: DependencyContainer;

    public constructor() {
        this.internalContainer = container.createChildContainer();
    }

    public build(
        canvasScaler: ICanvasScaler,
        sceneManager: SceneManager
    ): void {
        this.internalContainer.registerInstance(GameConfig, new GameConfig());
        this.internalContainer.registerInstance(InjectableInterfaces.ICanvasScaler, canvasScaler);
        this.internalContainer.registerInstance(SceneManager, sceneManager);
        this.internalContainer.register(HttpTransport, HttpTransport);
        this.internalContainer.registerSingleton(InjectableInterfaces.IClientConfigProvider, ClientConfigProvider);
        this.internalContainer.registerSingleton(InjectableInterfaces.INetworkClient, NetworkClient);
        this.internalContainer.registerSingleton(InjectableInterfaces.IAudioManager, AudioManager);
        this.internalContainer.registerSingleton(InjectableInterfaces.IUserModel, UserModelStub);
        this.internalContainer.registerSingleton(InjectableFactories.DefaultHoverHighlightButtonFactory, DefaultHoverHighlightButtonFactory);
        this.internalContainer.registerSingleton(InjectableFactories.ToggleButtonFactory, ToggleButtonFactory);
        this.internalContainer.register(PackageRegistrator, {
            useFactory: async (container: DependencyContainer) => {
                return new PackageRegistrator(container);
            }
        });
        this.internalContainer.register(InjectableInterfaces.IAuthModel, GuestAuthModel);
        this.internalContainer.registerSingleton(InjectableInterfaces.IDataStorage, LocalStorage);
        this.internalContainer.registerSingleton(VariableSaver, VariableSaver);
        this.internalContainer.registerSingleton(InjectableInterfaces.IPlayerModel, PlayerModel);
        // Scenes registrations
        // PreloaderScene
        this.internalContainer.register(PreloaderScene, PreloaderScene);
        this.internalContainer.register(PreloaderController, PreloaderController, { lifecycle: Lifecycle.ResolutionScoped });
        this.internalContainer.register(PreloaderModel, PreloaderModel, { lifecycle: Lifecycle.ResolutionScoped });
        this.internalContainer.register(InjectableFactories.PreloaderSceneFactory, {
            useValue: async () => {
                return await this.internalContainer.resolve(PreloaderScene);
            }
        });
        // SlotScene
        this.internalContainer.register(SlotScene, SlotScene);
        this.internalContainer.registerSingleton(SlotModel, SlotModel);
        this.internalContainer.registerSingleton(SlotViewModel, SlotViewModel);
        this.internalContainer.register(SlotController, SlotController, { lifecycle: Lifecycle.ResolutionScoped });
        this.internalContainer.register(InjectableFactories.SlotSceneFactory, {
            useValue: async () => {
                return await this.internalContainer.resolve(SlotScene);
            }
        });
        // FreeSpinOpenScene
        this.internalContainer.register(FreeSpinOpenScene, FreeSpinOpenScene);
        this.internalContainer.register(InjectableFactories.FreeSpinOpenSceneFactory, {
            useValue: async (freeSpinsCount: number) => {
                return await this.resolveWithParams(
                    this.internalContainer,
                    FreeSpinOpenScene,
                    new ParamDescription("freeSpinsCount", freeSpinsCount)
                );
            }
        });
        // FreeSpinFinishScene
        this.internalContainer.register(FreeSpinFinishScene, FreeSpinFinishScene);
        this.internalContainer.register(InjectableFactories.FreeSpinFinishSceneFactory, {
            useValue: async (prize: number) => {
                return await this.resolveWithParams(
                    this.internalContainer,
                    FreeSpinFinishScene,
                    new ParamDescription("prize", prize)
                );
            }
        });
        // BonusGameOpenScene
        this.internalContainer.register(BonusGameOpenScene, BonusGameOpenScene);
        this.internalContainer.register(InjectableFactories.BonusGameOpenSceneFactory, {
            useValue: async () => {
                return await this.internalContainer.resolve(BonusGameOpenScene);
            }
        });
        // BonusGameScene
        this.internalContainer.register(BonusGameScene, BonusGameScene);
        this.internalContainer.register(BonusGameViewModel, BonusGameViewModel, { lifecycle: Lifecycle.ResolutionScoped });
        this.internalContainer.register(BonusGameController, BonusGameController, { lifecycle: Lifecycle.ResolutionScoped });
        this.internalContainer.register(InjectableFactories.BonusGameSceneFactory, {
            useValue: async () => {
                return await this.internalContainer.resolve(BonusGameScene);
            }
        });
        // BonusGameFinishScene
        this.internalContainer.register(BonusGameFinishScene, BonusGameFinishScene);
        this.internalContainer.register(InjectableFactories.BonusGameFinishSceneFactory, {
            useValue: async (prize: number) => {
                return await this.resolveWithParams(
                    this.internalContainer,
                    BonusGameFinishScene,
                    new ParamDescription("prize", prize)
                );
            }
        });
        // RegularWinScene
        this.internalContainer.register(RegularWinScene, RegularWinScene);
        this.internalContainer.register(InjectableFactories.RegularWinSceneFactory, {
            useValue: async (slotModel: SlotModel, slotViewModel: SlotViewModel) => {
                return await this.resolveWithParams(
                    this.internalContainer,
                    RegularWinScene,
                    new ParamDescription(SlotModel, slotModel),
                    new ParamDescription(SlotViewModel, slotViewModel)
                );
            }
        });
        // BigWinScene
        this.internalContainer.register(BigWinScene, BigWinScene);
        this.internalContainer.register(InjectableFactories.BigWinSceneFactory, {
            useValue: async (slotModel: SlotModel, slotViewModel: SlotViewModel) => {
                return await this.resolveWithParams(
                    this.internalContainer,
                    BigWinScene,
                    new ParamDescription(SlotModel, slotModel),
                    new ParamDescription(SlotViewModel, slotViewModel)
                );
            }
        });
        // RulesScene
        this.internalContainer.register(RulesScene, RulesScene);
        this.internalContainer.register(InjectableFactories.RulesSceneFactory, {
            useValue: async () => {
                return await this.internalContainer.resolve(RulesScene);
            }
        });
        // Mobile Bet edit popup
        this.internalContainer.register(MobileBetScene, MobileBetScene);
        this.internalContainer.register(InjectableFactories.MobileBetSceneFactory, {
            useValue: async (initialTotalBet: number) => {
                return await this.resolveWithParams(
                    this.internalContainer,
                    MobileBetScene,
                    new ParamDescription("initialTotalBet", initialTotalBet),
                );
            }
        });
        // Common components
        this.internalContainer.registerSingleton(FullscreenBackground, FullscreenBackground);
    }

    private async resolveWithParams<T>(container: DependencyContainer, token: InjectionToken<T>, ...args: Array<ParamDescription<any>>): Promise<T> {
        const tempContainer = container.createChildContainer();
        for (const arg of args) {
            tempContainer.registerInstance(arg.token, arg.instance);
        }
        const instance = await tempContainer.resolve(token);
        tempContainer.clearInstances();
        tempContainer.reset();
        return instance;
    }

    public async resolve<T>(token: InjectionToken<T>): Promise<T> {
        return await this.internalContainer.resolve(token);
    }
}

class ParamDescription<T> {
    public readonly token: InjectionToken<T>;
    public readonly instance: T;

    public constructor(token: InjectionToken<T>, instance: T) {
        this.token = token;
        this.instance = instance;
    }
}