import { Container } from "pixi.js";
import { AdvancedBloomFilter } from "pixi-filters";
import { AnticipationAnimation } from "./AnticipationAnimation";
import { AudioNames } from "../../../../audio/AudioNames";
import { IAudioManager } from "../../../../audio/IAudioManager";
import { AnticipationFramesProvider } from "./resources/AnticipationFramesProvider";

export class Anticipation extends Container {
    private static readonly reelsIds = [2, 3, 4];
    private static readonly initialAnimationXPosition = 560;
    private static readonly animationXPositionStep = 240;
    private static readonly initialAnimationYPosition = 75;
    private reelsAnticipationMap: Map<number, AnticipationAnimation>;

    public constructor(private readonly resources: AnticipationFramesProvider, private readonly audioManager: IAudioManager) {
        super();
        this.reelsAnticipationMap = new Map<number, AnticipationAnimation>();
        this.build();
    }

    private build() {
        const bloomFilter = new AdvancedBloomFilter({ threshold: 0.5, brightness: 1.2 });
        const anticipationAnimations: Array<AnticipationAnimation> = Anticipation.reelsIds.map((reelId, i) => {
            const animation = new AnticipationAnimation(this.resources);
            animation.filters = [bloomFilter];
            animation.position.set(Anticipation.initialAnimationXPosition + i * Anticipation.animationXPositionStep, Anticipation.initialAnimationYPosition);
            this.reelsAnticipationMap.set(reelId, animation);
            return animation;
        });
        this.addChild(...anticipationAnimations);
    }

    public playReelAnimation(reelId: number): void {
        if (!Anticipation.reelsIds.includes(reelId)) {
            throw new Error(`Failed to find reel anticipation animation with reelId ${reelId} to play. ReelId out of bounds.`);
        }
        this.audioManager.play(AudioNames.Anticipation);
        this.reelsAnticipationMap.get(reelId).play();
    }

    public stopReelAnimation(reelId: number): void {
        if (!Anticipation.reelsIds.includes(reelId)) {
            throw new Error(`Failed to find reel anticipation animation with reelId ${reelId} to stop. ReelId out of bounds.`);
        }
        this.reelsAnticipationMap.get(reelId).stop();
    }

    public forceStop(): void {
        this.audioManager.stop(AudioNames.Anticipation);
        Anticipation.reelsIds.forEach(reelId => this.reelsAnticipationMap.get(reelId).forceStop());
    }

    public countAnticipation(field: number[]): { specialSymbolId: number, reelId: number } | null {
        const reels = [field[0], field[1], field[2], field[3], field[5], field[6], field[7], field[8], field[10], field[11], field[12], field[13]];
        const freeSpinsSymbolsCount = reels.filter(item => item === 7).length;
        const bonusSymbolsCount = reels.filter(item => item === 8).length;
        const columnsCount = 5;
        const rowsCount = 3;
        let specialSymbolId = -1;
        let reelId = -1;
        if (freeSpinsSymbolsCount >= 2 || bonusSymbolsCount >= 2) {
            specialSymbolId = freeSpinsSymbolsCount > bonusSymbolsCount ? 7 : 8;
            let specialSymbolsCount = 0;
            let specialSymbolIndex = 0;
            for (let x = 0; x < columnsCount; x++) {
                for (let y = 0; y < rowsCount; y++) {
                    if (field[y * columnsCount + x] == specialSymbolId) {
                        specialSymbolsCount++;
                        specialSymbolIndex = y * columnsCount + x;
                    }
                }
                if (specialSymbolsCount > 1) {
                    break;
                }
            }
            reelId = specialSymbolIndex % columnsCount;
            return { specialSymbolId, reelId };
        }
        else {
            return null;
        }
    }
}
