import { BonusGameResponseDto } from "./response/BonusGameResponseDto";

export class BonusGameResult {
    public readonly bet: number;
    public readonly multiplier: number;
    public readonly prize: number;
    public readonly chestId: number;
    public readonly playerBalance: number;
    public otherMultipliers: Array<{ chestId: number; multiplierValue: number }>;

    public constructor(dto: BonusGameResponseDto) {
        if (dto == null) {
            throw new Error("Failed to convert BonusGameResponseDto to BonusGameResult. Dto is null.");
        }
        if (dto.bet == null || dto.bet == 0) {
            throw new Error("Failed to convert BonusGameResponseDto to BonusGameResult. Dto 'bet' has wrong data.");
        }
        if (dto.multiplier == null || isNaN(dto.multiplier)) {
            throw new Error("Failed to convert BonusGameResponseDto to BonusGameResult. Dto 'multiplier' has wrong data.");
        }
        if (dto.prize == null || isNaN(dto.prize)) {
            throw new Error("Failed to convert BonusGameResponseDto to BonusGameResult. Dto 'prize' has wrong data.");
        }
        if (dto.chestId == null || ![0, 1, 2].includes(dto.chestId)) {
            throw new Error("Failed to convert BonusGameResponseDto to BonusGameResult. Dto 'chestId' has wrong data.");
        }
        if (dto.playerBalance == null || isNaN(dto.playerBalance)) {
            throw new Error("Failed to convert BonusGameResponseDto to BonusGameResult. Dto 'playerBalance' has wrong data.");
        }
        this.bet = dto.bet;
        this.multiplier = dto.multiplier;
        this.prize = dto.prize;
        this.chestId = dto.chestId;
        this.playerBalance = dto.playerBalance;
        const remainingMultipliers = [2, 3, 5].filter(multiplier => multiplier != dto.multiplier);
        this.otherMultipliers = [0, 1, 2].filter(chestId => chestId !== dto.chestId).map(chestId => ({ chestId, multiplierValue: remainingMultipliers.pop() }));
    }
}