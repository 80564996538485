import { ColorSource, IBitmapTextStyle, TextStyleAlign } from "pixi.js";

export class DefaultBitmapStyleText implements IBitmapTextStyle {
    public fontName: string;
    public fontSize: number;
    public tint: ColorSource;
    public align: TextStyleAlign;
    public letterSpacing: number;
    public maxWidth: number;

    public constructor(fontSize: number) {
        this.fontName = "sansserif";
        this.fontSize = fontSize;
        this.align = 'center';
        this.letterSpacing = 0;
    }

}