import { Texture } from "pixi.js";
import { BigWildAnimationResourcePackage } from "./BigWildAnimationResourcePackage";

export class BigWildAnimationFramesProvider extends BigWildAnimationResourcePackage {
    private instance: Array<Texture>;

    public get animationFrames(): Array<Texture> {
        if (!this.loaded) {
            throw new Error("You can not use frames provider before actual package is loaded.");
        }
        if (this.instance == null) {
            this.instance = [
                this.assets_bigWildAnimation_0_1_png,
                this.assets_bigWildAnimation_0_2_png,
                this.assets_bigWildAnimation_0_3_png,
                this.assets_bigWildAnimation_0_4_png,
                this.assets_bigWildAnimation_0_5_png,
                this.assets_bigWildAnimation_0_6_png,
                this.assets_bigWildAnimation_0_7_png,
                this.assets_bigWildAnimation_0_8_png,
                this.assets_bigWildAnimation_0_9_png,
                this.assets_bigWildAnimation_0_10_png,
                this.assets_bigWildAnimation_0_11_png,
                this.assets_bigWildAnimation_0_12_png,
                this.assets_bigWildAnimation_0_13_png,
                this.assets_bigWildAnimation_0_14_png,
                this.assets_bigWildAnimation_0_15_png,
                this.assets_bigWildAnimation_0_16_png,
                this.assets_bigWildAnimation_0_17_png,
                this.assets_bigWildAnimation_0_18_png,
                this.assets_bigWildAnimation_0_19_png,
                this.assets_bigWildAnimation_0_20_png,
                this.assets_bigWildAnimation_0_21_png,
                this.assets_bigWildAnimation_0_22_png,
                this.assets_bigWildAnimation_0_23_png,
                this.assets_bigWildAnimation_0_24_png,
                this.assets_bigWildAnimation_0_25_png,
                this.assets_bigWildAnimation_0_26_png,
                this.assets_bigWildAnimation_0_27_png,
                this.assets_bigWildAnimation_0_28_png,
                this.assets_bigWildAnimation_0_29_png,
                this.assets_bigWildAnimation_0_30_png,
                this.assets_bigWildAnimation_0_31_png,
                this.assets_bigWildAnimation_0_32_png,
                this.assets_bigWildAnimation_0_33_png,
                this.assets_bigWildAnimation_0_34_png,
                this.assets_bigWildAnimation_0_35_png,
                this.assets_bigWildAnimation_0_36_png,
                this.assets_bigWildAnimation_0_37_png,
                this.assets_bigWildAnimation_0_38_png,
                this.assets_bigWildAnimation_0_39_png,
                this.assets_bigWildAnimation_0_40_png,
                this.assets_bigWildAnimation_0_41_png,
                this.assets_bigWildAnimation_0_42_png,
                this.assets_bigWildAnimation_0_43_png,
                this.assets_bigWildAnimation_0_44_png,
                this.assets_bigWildAnimation_0_45_png,
                this.assets_bigWildAnimation_0_46_png,
                this.assets_bigWildAnimation_0_47_png,
                this.assets_bigWildAnimation_0_48_png,
                this.assets_bigWildAnimation_0_49_png
            ]
        }
        return this.instance;
    }
}