import { injectable } from "@launchtray/tsyringe-async";
import { Bindable, NotifyProperty, nameof } from "bindable-data";
import { SlotModel } from "./SlotModel";
import { Event } from "event-system";
import { SlotStateType } from "../views/slotStates/SlotStateType";
import { DisabledSlotElement } from "./data/DisabledSlotElement";

@injectable()
export class SlotViewModel extends Bindable {
    public readonly onSpinResultsReady: Event<void>;
    public readonly onBonusGameOpenSceneComplete: Event<void>;
    public readonly autoSpinEnabled: NotifyProperty<boolean>;
    public readonly spinAnimationInProgress: NotifyProperty<boolean>;
    public readonly isFreeSpinMode: NotifyProperty<boolean>;
    public readonly isBonusGame: NotifyProperty<boolean>;
    public readonly disabledSlotElements: NotifyProperty<Array<DisabledSlotElement>>;
    public readonly freeSpinsCount: NotifyProperty<number>;
    public readonly slotViewState: NotifyProperty<SlotStateType>;
    public readonly fastReelsStop: NotifyProperty<boolean>;
    private _freeSpinModePrize: number;
    public get freeSpinModePrize(): number {
        return this._freeSpinModePrize;
    }
    public readonly onUpdateWinPrize: Event<number>;

    public constructor(
        private readonly slotModel: SlotModel
    ) {
        super();
        this.onSpinResultsReady = new Event();
        this.onBonusGameOpenSceneComplete = new Event();
        this.autoSpinEnabled = new NotifyProperty(this, nameof(this, "autoSpinEnabled"), false);
        this.spinAnimationInProgress = new NotifyProperty(this, nameof(this, "spinAnimationInProgress"), false);
        this.isFreeSpinMode = new NotifyProperty(this, nameof(this, "isFreeSpinMode"), this.slotModel.freeSpinMode.value);
        this.isBonusGame = new NotifyProperty(this, nameof(this, "isBonusGame"), this.slotModel.bonusGameActive.value);
        this.disabledSlotElements = new NotifyProperty(this, nameof(this, "disabledSlotElements"), []);
        this.freeSpinsCount = new NotifyProperty(this, nameof(this, "freeSpinsCount"), this.slotModel.freeSpinsCount.value);
        this.slotViewState = new NotifyProperty(this, nameof(this, "slotViewState"), SlotStateType.DefaultState);
        this.fastReelsStop = new NotifyProperty(this, nameof(this, "fastReelsStop"), false);
        this.slotModel.propertyChanged.add(this.onSlotModelChanged, this);
        this._freeSpinModePrize = 0;
        this.onUpdateWinPrize = new Event();
    }

    private onSlotModelChanged(slotModel: SlotModel, propertyName: string) {
        switch (propertyName) {
            case nameof(slotModel, "spinResult"):
                this._freeSpinModePrize = slotModel.spinResult.value.winning.freeSpinsTotalPrize;
                this.onSpinResultsReady.emit();
                break;
            case nameof(slotModel, "bonusGameActive"):
                this.isBonusGame.setValue(slotModel.bonusGameActive.value);
                break;
            case nameof(slotModel, "freeSpinsCount"):
                this.freeSpinsCount.setValue(slotModel.freeSpinsCount.value);
                break;
        }
    }

    public toggleAutoSpin(): void {
        this.autoSpinEnabled.setValue(!this.autoSpinEnabled.value);
    }

    public emitWinPrizeUpdate(prize: number): void {
        this.onUpdateWinPrize.emit(prize);
    }

    public disableSlotElement(elementToDisable: DisabledSlotElement): void {
        if (!this.disabledSlotElements.value.includes(elementToDisable)) {
            this.disabledSlotElements.setValue([...this.disabledSlotElements.value, elementToDisable]);
        }
    }

    public enableSlotElement(elementToEnable: DisabledSlotElement): void {
        this.disabledSlotElements.setValue(this.disabledSlotElements.value.filter(element => element !== elementToEnable));
    }

    public disableSlotElements(elementsToDisable: Array<DisabledSlotElement>): void {
        const elementsToDisableDiff = elementsToDisable.filter(elementToDisable => !this.disabledSlotElements.value.includes(elementToDisable));
        if (elementsToDisableDiff.length > 0) {
            this.disabledSlotElements.setValue([...this.disabledSlotElements.value, ...elementsToDisableDiff]);
        }
    }

    public enableSlotElements(elementsToEnable: Array<DisabledSlotElement>): void {
        this.disabledSlotElements.setValue(this.disabledSlotElements.value.filter(element => !elementsToEnable.includes(element)));
    }

    public changeSlotViewState(slotStateType: SlotStateType) {
        this.slotViewState.setValue(slotStateType);
    }

    public setFastReelsStop(value: boolean): void {
        this.fastReelsStop.setValue(value);
    }

    public setFreeSpinsMode(value: boolean) {
        this.isFreeSpinMode.setValue(value);
    }

    public destroy(): void {
        this.slotModel.propertyChanged.remove(this.onSlotModelChanged, this);
    }
}
