import { injectable } from "@launchtray/tsyringe-async";
import { BonusGameViewModel } from "../models/BonusGameViewModel";
import { SlotModel } from "../../../slot/models/SlotModel";

@injectable()
export class BonusGameController {
    public constructor(
        private readonly slotModel: SlotModel,
        private readonly bonusGameViewModel: BonusGameViewModel,
    ) { }

    public async pickBonus(id: number): Promise<void> {
        await this.slotModel.getBonus(id);
    }

    public finishMainAnimationPlay(): void {
        this.bonusGameViewModel.mainAnimationFinished.setValue(true);
    }

    public finishSecondaryAnimationPlay(): void {
        this.bonusGameViewModel.secondaryAnimationFinished.setValue(true);
    }

    public finishAnimation(): void {
        this.bonusGameViewModel.finishAnimation.setValue(true);
    }

}
