import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class PreloaderResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("PreloaderResourcesPackage");
    }
    public get assets_preloader_background_jpg(): Texture {
        return this.resource<Texture>("assets/preloader/background.jpg");
    }
    public get assets_preloader_loadingBar_container_png(): Texture {
        return this.resource<Texture>("assets/preloader/loadingBar_container.png");
    }
    public get assets_preloader_loadingBar_line_png(): Texture {
        return this.resource<Texture>("assets/preloader/loadingBar_line.png");
    }
    public get assets_preloader_logo_png(): Texture {
        return this.resource<Texture>("assets/preloader/logo.png");
    }
    public get assets_preloader_startButton_png(): Texture {
        return this.resource<Texture>("assets/preloader/startButton.png");
    }
}

