import { BaseView, PromiseCompletionSource } from "scene-manager";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { IDestroyOptions, Sprite } from "pixi.js";
import { IFocusable } from "../../../../layers/IFocusable";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { RulesMobileResourcesPackage } from "../resources/RulesMobileResourcesPackage";
import { VerticalScrollBar } from "../components/VerticalScrollBar";
import { UIScrollBox } from "../components/UIScrollBox";

export class MobileRulesView extends BaseView<void> implements IFocusable {
    private exitButton: DefaultHoverHighlightButton;
    private scrollBox: UIScrollBox;
    private scrollBar: VerticalScrollBar;
    private static scrollBoxScale: number = 1.4;
    public readonly focusable: boolean = true;

    public constructor(
        private readonly rulesMobileResources: RulesMobileResourcesPackage,
        private readonly canvasScaler: ICanvasScaler,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(operationControls);
        this.onScroll = this.onScroll.bind(this);
        this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
        window.screen.orientation.addEventListener("change", this.onCloseButtonClick);
        this.build();
        this.layout();
    }

    public start(): void {
    }

    private build(): void {
        const width = this.canvasScaler.referenceHeight;
        const height = this.canvasScaler.referenceWidth;
        const sprites = Array<Sprite>
            (
                new Sprite(this.rulesMobileResources.assets_popups_rulesMobile_wild_png),
                new Sprite(this.rulesMobileResources.assets_popups_rulesMobile_freeSpinsBonus_png),
                new Sprite(this.rulesMobileResources.assets_popups_rulesMobile_freeSpins_png),
                new Sprite(this.rulesMobileResources.assets_popups_rulesMobile_dice_png),
                new Sprite(this.rulesMobileResources.assets_popups_rulesMobile_bonusGame_png),
                new Sprite(this.rulesMobileResources.assets_popups_rulesMobile_payLines_png)
            );
        sprites.forEach(sprite => sprite.scale.set(MobileRulesView.scrollBoxScale));
        this.scrollBox = new UIScrollBox({
            width: width * MobileRulesView.scrollBoxScale,
            height: height * MobileRulesView.scrollBoxScale,
            disableEasing: true,
            items: sprites
        });
        this.scrollBox.onScroll.connect(this.onScroll);
        this.addChild(this.scrollBox);
        this.scrollBar = new VerticalScrollBar(this.scrollBox.height, this.scrollBox.items[0].height * this.scrollBox.items.length - 1);
        this.addChild(this.scrollBar);
        this.exitButton = this.defaultHoverHighlightButtonFactory.createButton(new Sprite(this.rulesMobileResources.assets_popups_rulesMobile_closeButton_png));
        this.exitButton.onPress.connect(this.onCloseButtonClick);
        this.addChild(this.exitButton.view);
    }

    private layout(): void {
        const referenceWidth = this.canvasScaler.referenceWidth;
        const referenceHeight = this.canvasScaler.referenceHeight;
        this.scrollBox.position.set((referenceWidth - this.scrollBox.width) * 0.5 - 50, referenceHeight * 0.5);
        this.scrollBar.position.set(referenceWidth - (referenceWidth - this.scrollBox.width) * 0.5, referenceHeight * 0.5);
        this.exitButton.view.position.set(referenceWidth - (referenceWidth - this.scrollBox.width) * 0.5 - 40, 300);
    }

    private onScroll(value: number): void {
        this.scrollBar.updateScroll(value);
    }

    private onCloseButtonClick(): void {
        this.operationControls.complete();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        window.screen.orientation.removeEventListener("change", this.onCloseButtonClick);
        this.scrollBox.onScroll.disconnect(this.onScroll);
        this.exitButton.onPress.disconnect(this.onCloseButtonClick);
        this.exitButton.destroy();
    }
}
