import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class BonusGameResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("BonusGameResourcesPackage");
    }
    public get assets_popups_bonusGame_bonusGame_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/popups/bonusGame/bonusGame_1x.atlas.json");
    }
    public get assets_popups_bonusGame_Frame_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["Frame.png"];
    }
    public get assets_popups_bonusGame_BonusGameContainer_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["BonusGameContainer.png"];
    }
    public get assets_popups_bonusGame_TITLE_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["TITLE.png"];
    }
    public get assets_popups_bonusGame_Button_Container_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["Button_Container.png"];
    }
    public get assets_popups_bonusGame_Button_Open_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["Button_Open.png"];
    }
    public get assets_popups_bonusGame_Button_Opened_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["Button_Opened.png"];
    }
    public get assets_popups_bonusGame_Glow_Multiplier_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["Glow_Multiplier.png"];
    }
    public get assets_popups_bonusGame_RoundGlowMultiplier_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["RoundGlowMultiplier.png"];
    }
    public get assets_popups_bonusGame_x5_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["x5.png"];
    }
    public get assets_popups_bonusGame_x2_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["x2.png"];
    }
    public get assets_popups_bonusGame_x3_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["x3.png"];
    }
    public get assets_popups_bonusGame_QuestionMark_png(): Texture {
        return this.assets_popups_bonusGame_bonusGame_1x_atlas_json.textures["QuestionMark.png"];
    }
    public get assets_popups_bonusGame_bonusGame_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/popups/bonusGame/bonusGame_1x.atlas.png");
    }
}

