import { Texture } from "pixi.js";
import { AnticipationResourcesPackage } from "./AnticipationResourcesPackage";

export class AnticipationFramesProvider extends AnticipationResourcesPackage {
    private instance: Array<Texture>;

    public get animationFrames(): Array<Texture> {
        if (!this.loaded) {
            throw new Error("You can not use frames provider before actual package is loaded.");
        }
        if (this.instance == null) {
            this.instance = [
                this.assets_anticipation_anticipation_0_png,
                this.assets_anticipation_anticipation_1_png,
                this.assets_anticipation_anticipation_2_png,
                this.assets_anticipation_anticipation_3_png,
                this.assets_anticipation_anticipation_4_png,
                this.assets_anticipation_anticipation_5_png,
                this.assets_anticipation_anticipation_6_png,
                this.assets_anticipation_anticipation_7_png,
                this.assets_anticipation_anticipation_8_png,
                this.assets_anticipation_anticipation_9_png,
                this.assets_anticipation_anticipation_10_png,
                this.assets_anticipation_anticipation_11_png,
                this.assets_anticipation_anticipation_12_png,
                this.assets_anticipation_anticipation_13_png,
                this.assets_anticipation_anticipation_14_png,
                this.assets_anticipation_anticipation_15_png,
                this.assets_anticipation_anticipation_16_png,
                this.assets_anticipation_anticipation_17_png,
                this.assets_anticipation_anticipation_18_png,
                this.assets_anticipation_anticipation_19_png,
                this.assets_anticipation_anticipation_20_png,
                this.assets_anticipation_anticipation_21_png,
                this.assets_anticipation_anticipation_22_png,
                this.assets_anticipation_anticipation_23_png,
                this.assets_anticipation_anticipation_24_png,
                this.assets_anticipation_anticipation_25_png,
                this.assets_anticipation_anticipation_26_png,
                this.assets_anticipation_anticipation_27_png,
                this.assets_anticipation_anticipation_28_png,
                this.assets_anticipation_anticipation_29_png,
                this.assets_anticipation_anticipation_30_png,
                this.assets_anticipation_anticipation_31_png,
                this.assets_anticipation_anticipation_32_png,
                this.assets_anticipation_anticipation_33_png,
                this.assets_anticipation_anticipation_34_png,
                this.assets_anticipation_anticipation_35_png,
                this.assets_anticipation_anticipation_36_png,
                this.assets_anticipation_anticipation_37_png,
                this.assets_anticipation_anticipation_38_png,
                this.assets_anticipation_anticipation_39_png,
                this.assets_anticipation_anticipation_40_png,
                this.assets_anticipation_anticipation_41_png,
                this.assets_anticipation_anticipation_42_png,
                this.assets_anticipation_anticipation_43_png,
                this.assets_anticipation_anticipation_44_png,
                this.assets_anticipation_anticipation_45_png,
                this.assets_anticipation_anticipation_46_png,
                this.assets_anticipation_anticipation_47_png
            ]
        }
        return this.instance;
    }
}