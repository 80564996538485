import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class ChestAnimationResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("ChestAnimationResourcesPackage");
    }
    public get assets_chestAnimation_chestAnimation_0_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/chestAnimation/chestAnimation-0_1x.atlas.json");
    }
    public get assets_chestAnimation_chestAnimation_19_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_19.png"];
    }
    public get assets_chestAnimation_chestAnimation_20_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_20.png"];
    }
    public get assets_chestAnimation_chestAnimation_21_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_21.png"];
    }
    public get assets_chestAnimation_chestAnimation_22_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_22.png"];
    }
    public get assets_chestAnimation_chestAnimation_23_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_23.png"];
    }
    public get assets_chestAnimation_chestAnimation_24_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_24.png"];
    }
    public get assets_chestAnimation_chestAnimation_25_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_25.png"];
    }
    public get assets_chestAnimation_chestAnimation_26_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_26.png"];
    }
    public get assets_chestAnimation_chestAnimation_27_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_27.png"];
    }
    public get assets_chestAnimation_chestAnimation_28_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_28.png"];
    }
    public get assets_chestAnimation_chestAnimation_29_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_29.png"];
    }
    public get assets_chestAnimation_chestAnimation_30_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_30.png"];
    }
    public get assets_chestAnimation_chestAnimation_31_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_31.png"];
    }
    public get assets_chestAnimation_chestAnimation_32_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_32.png"];
    }
    public get assets_chestAnimation_chestAnimation_33_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_33.png"];
    }
    public get assets_chestAnimation_chestAnimation_34_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_34.png"];
    }
    public get assets_chestAnimation_chestAnimation_35_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_35.png"];
    }
    public get assets_chestAnimation_chestAnimation_36_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_36.png"];
    }
    public get assets_chestAnimation_chestAnimation_37_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_37.png"];
    }
    public get assets_chestAnimation_chestAnimation_38_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_38.png"];
    }
    public get assets_chestAnimation_chestAnimation_39_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_39.png"];
    }
    public get assets_chestAnimation_chestAnimation_40_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_40.png"];
    }
    public get assets_chestAnimation_chestAnimation_41_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_41.png"];
    }
    public get assets_chestAnimation_chestAnimation_42_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_42.png"];
    }
    public get assets_chestAnimation_chestAnimation_43_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_43.png"];
    }
    public get assets_chestAnimation_chestAnimation_44_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_44.png"];
    }
    public get assets_chestAnimation_chestAnimation_45_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_45.png"];
    }
    public get assets_chestAnimation_chestAnimation_16_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_16.png"];
    }
    public get assets_chestAnimation_chestAnimation_18_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_18.png"];
    }
    public get assets_chestAnimation_chestAnimation_15_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_15.png"];
    }
    public get assets_chestAnimation_chestAnimation_14_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_14.png"];
    }
    public get assets_chestAnimation_chestAnimation_0_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_0.png"];
    }
    public get assets_chestAnimation_chestAnimation_2_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_2.png"];
    }
    public get assets_chestAnimation_chestAnimation_3_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_3.png"];
    }
    public get assets_chestAnimation_chestAnimation_4_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_4.png"];
    }
    public get assets_chestAnimation_chestAnimation_5_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_5.png"];
    }
    public get assets_chestAnimation_chestAnimation_1_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_0_1x_atlas_json.textures["chestAnimation_1.png"];
    }
    public get assets_chestAnimation_chestAnimation_0_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/chestAnimation/chestAnimation-0_1x.atlas.png");
    }
    public get assets_chestAnimation_chestAnimation_1_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/chestAnimation/chestAnimation-1_1x.atlas.json");
    }
    public get assets_chestAnimation_chestAnimation_46_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_46.png"];
    }
    public get assets_chestAnimation_chestAnimation_47_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_47.png"];
    }
    public get assets_chestAnimation_chestAnimation_48_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_48.png"];
    }
    public get assets_chestAnimation_chestAnimation_49_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_49.png"];
    }
    public get assets_chestAnimation_chestAnimation_17_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_17.png"];
    }
    public get assets_chestAnimation_chestAnimation_6_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_6.png"];
    }
    public get assets_chestAnimation_chestAnimation_7_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_7.png"];
    }
    public get assets_chestAnimation_chestAnimation_8_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_8.png"];
    }
    public get assets_chestAnimation_chestAnimation_9_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_9.png"];
    }
    public get assets_chestAnimation_chestAnimation_10_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_10.png"];
    }
    public get assets_chestAnimation_chestAnimation_11_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_11.png"];
    }
    public get assets_chestAnimation_chestAnimation_12_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_12.png"];
    }
    public get assets_chestAnimation_chestAnimation_13_png(): Texture {
        return this.assets_chestAnimation_chestAnimation_1_1x_atlas_json.textures["chestAnimation_13.png"];
    }
    public get assets_chestAnimation_chestAnimation_1_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/chestAnimation/chestAnimation-1_1x.atlas.png");
    }
}

