import { RulesView } from "./views/RulesView";
import { PromiseCompletionSource, Viewable } from "scene-manager";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { RulesResourcesPackage } from "./resources/RulesResourcesPackage";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { InjectableFactories } from "../../../../composition/InjectableFactories";
import { RulesMobileResourcesPackage } from "./resources/RulesMobileResourcesPackage";
import { MobileRulesView } from "./views/MobileRulesView";
import { DeviceCheckUtils } from "../../common/utils/DeviceCheckUtils";

@injectable()
export class RulesScene extends Viewable<void> {

    public constructor(
        private readonly rulesResources: RulesResourcesPackage,
        private readonly rulesMobileResources: RulesMobileResourcesPackage,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory
    ) {
        super();
    }

    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<void>();
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.view = new MobileRulesView(
                this.rulesMobileResources,
                this.canvasScaler,
                this.defaultHoverHighlightButtonFactory,
                viewCanBeClosed);
        }
        else {
            this.view = new RulesView(
                this.rulesResources,
                this.canvasScaler,
                this.defaultHoverHighlightButtonFactory,
                viewCanBeClosed
            );
        }
        viewCanBeClosed.result.then(() => this.operationControls.complete());
    }
}
