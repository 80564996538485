import { Bindable, NotifyProperty } from "bindable-data";
import { IPlayerModel } from "./IPlayerModel";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { INetworkClient } from "../../network/INetworkClient";
import { InjectableInterfaces } from "../../composition/InjectableInterfaces";
import { PlayerProfileDto } from "./dto/PlayerProfileDto";
import { SlotModel } from "../scenes/slot/models/SlotModel";

const PLAYER_PROFILE_URL = "players/currentPlayer";

@injectable()
export class PlayerModel extends Bindable implements IPlayerModel {
    public readonly balance: NotifyProperty<number>;

    public constructor(
        @inject(InjectableInterfaces.INetworkClient) private readonly networkClient: INetworkClient,
        private readonly slotModel: SlotModel,
    ) {
        super();
        this.balance = new NotifyProperty(this, "balance", 0);
    }

    @initializer()
    public async initialize() {
        const profile = await this.networkClient.get<PlayerProfileDto>(PLAYER_PROFILE_URL, {});
        this.balance.setValue(profile.balance);
    }

    public async tryBet(bet: number): Promise<boolean> {
        const balanceAfter = this.balance.value - bet;
        if (balanceAfter >= 0) {
            // No actual betting right now only visual
            this.balance.setValue(balanceAfter);
            // Make bet
            return true;
        } 
        return false;
    }

    public updatePlayer(balance: number): void {
        this.balance.setValue(balance);
    }
}
