import { SceneManager } from 'scene-manager';
import { Application } from '@pixi/app';
import { CanvasScaler } from './canvas/CanvasScaler';
import { ResourceManager } from 'resource-system';

export class Game extends Application {
    public static readonly WIDTH: number = 1920; //HEIGHT * (slot/field/frame.png ratio)
    public static readonly HEIGHT: number = 1080; //FHD window.innerHeight on pc chrome
    private _sceneManager: SceneManager;
    public get sceneManager(): SceneManager {
        return this._sceneManager;
    };
    private _canvasScaler: CanvasScaler;
    public get canvasScaler(): CanvasScaler {
        return this._canvasScaler;
    };
    private _resourceManager: ResourceManager;
    public get resourceManager(): ResourceManager {
        return this._resourceManager;
    };

    public constructor() {
        super({
            backgroundColor: 0xffffff,
            backgroundAlpha: 1.0,
            width: window.innerWidth,
            height: window.innerHeight,
            antialias: true,
            autoDensity: true,
            resolution: window.devicePixelRatio
        });
        this._canvasScaler = new CanvasScaler(this.renderer, Game.WIDTH, Game.HEIGHT);
    }

    public async launch(sceneManager?: SceneManager, manifestPath?: string): Promise<void> {
        this._sceneManager = sceneManager != null ? sceneManager : new SceneManager(this.stage);
        this._resourceManager = await ResourceManager.initWithManifest(manifestPath ?? "assets/manifest.json");
    }

}
