import { injectable, initializer, inject } from "@launchtray/tsyringe-async";
import { PromiseCompletionSource, Viewable } from "scene-manager";
import { BonusGameView } from "./views/BonusGameView";
import { BonusGameResourcesPackage } from "./resources/BonusGameResourcesPackage";
import { BonusGameController } from "./controllers/BonusGameController";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { BonusGameViewModel } from "./models/BonusGameViewModel";
import { InjectableFactories } from "../../../../composition/InjectableFactories";
import { SlotModel } from "../../slot/models/SlotModel";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../audio/AudioManager";
import { ChestAnimationFramesProvider } from "./resources/ChestAnimationFramesProvider";
import { SlotViewModel } from "../../slot/models/SlotViewModel";

@injectable()
export class BonusGameScene extends Viewable<void> {
    public constructor(
        private readonly resources: BonusGameResourcesPackage,
        private readonly chestAnimationResources: ChestAnimationFramesProvider,
        private readonly bonusGameViewModel: BonusGameViewModel,
        private readonly bonusGameController: BonusGameController,
        private readonly slotModel: SlotModel,
        private readonly slotViewModel: SlotViewModel,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager
    ) {
        super();
    }

    @initializer()
    public async initialize(): Promise<void> {
        const pcs = new PromiseCompletionSource<void>();

        this.view = new BonusGameView(
            this.resources,
            this.chestAnimationResources,
            this.slotModel,
            this.bonusGameViewModel,
            this.bonusGameController,
            this.canvasScaler,
            this.defaultHoverHighlightButtonFactory,
            this.audioManager,
            pcs
        );
        pcs.result.then(async () => {
            this.slotViewModel.onBonusGameOpenSceneComplete.emit();
            this.operationControls.complete();
        });
    }
}