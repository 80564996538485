import { AudioNames } from "../../../../audio/AudioNames";
import { SlotController } from "../../controllers/SlotController";
import { BonusGameResult } from "../../models/data/BonusGameResult";
import { ISlotState } from "./ISlotState";
import { SlotStateType } from "./SlotStateType";

export class BonusGameState implements ISlotState<BonusGameResult> {
    private bonusGameResult: BonusGameResult = null;
    public constructor(
        protected readonly slotController: SlotController
    ) { }

    public async enter(): Promise<void> {
        this.slotController.playBackgroundMusic(AudioNames.BonusGameMusic);
        this.slotController.disableSlotElements(['spinButtonUi', 'autoSpinButtonUi', 'infoButtonUi', 'betFieldUi']);
        await this.slotController.showBonusGameStartPopup();
        await this.slotController.showBonusGamePopup();
    }

    public async process(result: BonusGameResult): Promise<SlotStateType> {
        this.bonusGameResult = result;
        return SlotStateType.DefaultState;
    }

    public async exit(): Promise<void> {
        await this.slotController.showBonusGameCompletePopup();
        this.slotController.stopBackgroundMusic(AudioNames.BonusGameMusic);
        this.slotController.invalidatePlayer(this.bonusGameResult.playerBalance);
        this.slotController.turnOffAutoSpin();
        this.slotController.enableSlotElements(['spinButtonUi', 'autoSpinButtonUi', 'infoButtonUi', 'betFieldUi']);
        this.bonusGameResult = null;
    }
}