import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class FreeSpinsSymbolAnimationResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("FreeSpinsSymbolAnimationResourcesPackage");
    }
    public get assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/freeSpinsSymbolAnimation/freeSpinsSymbolAnimation_1x.atlas.json");
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_0_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_0.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_10_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_10.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_11_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_11.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_12_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_12.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_13_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_13.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_14_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_14.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_15_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_15.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_16_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_16.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_17_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_17.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_18_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_18.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_19_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_19.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_2_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_2.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_20_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_20.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_21_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_21.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_22_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_22.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_23_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_23.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_24_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_24.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_25_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_25.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_26_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_26.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_27_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_27.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_28_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_28.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_29_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_29.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_3_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_3.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_30_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_30.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_31_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_31.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_32_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_32.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_33_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_33.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_34_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_34.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_35_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_35.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_36_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_36.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_37_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_37.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_38_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_38.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_39_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_39.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_4_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_4.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_40_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_40.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_41_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_41.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_42_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_42.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_43_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_43.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_44_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_44.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_45_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_45.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_46_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_46.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_47_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_47.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_5_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_5.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_6_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_6.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_7_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_7.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_8_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_8.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_9_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_9.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_1_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_1.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_48_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_48.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpins_49_png(): Texture {
        return this.assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_json.textures["freeSpins_49.png"];
    }
    public get assets_freeSpinsSymbolAnimation_freeSpinsSymbolAnimation_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/freeSpinsSymbolAnimation/freeSpinsSymbolAnimation_1x.atlas.png");
    }
}

