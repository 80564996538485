import { BaseView, PromiseCompletionSource } from "scene-manager";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { IDestroyOptions, Sprite } from "pixi.js";
import { IFocusable } from "../../../../layers/IFocusable";
import { RulesResourcesPackage } from "../resources/RulesResourcesPackage";
import { RulesContainer } from "../components/RulesContainer";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";

export class RulesView extends BaseView<void> implements IFocusable {
    private rulesContainer: RulesContainer;
    private exitButton: DefaultHoverHighlightButton;
    private nextButton: DefaultHoverHighlightButton;
    private previousButton: DefaultHoverHighlightButton;
    public readonly focusable: boolean = true;

    public constructor(
        private readonly rulesResources: RulesResourcesPackage,
        private readonly canvasScaler: ICanvasScaler,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(operationControls);
        this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
        this.onNextButtonClick = this.onNextButtonClick.bind(this);
        this.onPreviousButtonClick = this.onPreviousButtonClick.bind(this);
        window.screen.orientation.addEventListener("change", this.onCloseButtonClick);
        this.build();
        this.layout();
    }

    public start(): void {

    }

    private build(): void {
        this.rulesContainer = new RulesContainer(this.rulesResources);
        this.addChild(this.rulesContainer);
        this.nextButton = this.defaultHoverHighlightButtonFactory.createButton(new Sprite(this.rulesResources.assets_popups_rules_button_toRight_png));
        this.nextButton.onPress.connect(this.onNextButtonClick);
        this.rulesContainer.addChild(this.nextButton.view);
        this.previousButton = this.defaultHoverHighlightButtonFactory.createButton(new Sprite(this.rulesResources.assets_popups_rules_button_toLeft_png));
        this.previousButton.onPress.connect(this.onPreviousButtonClick);
        this.previousButton.view.visible = false;
        this.rulesContainer.addChild(this.previousButton.view);
        this.exitButton = this.defaultHoverHighlightButtonFactory.createButton(new Sprite(this.rulesResources.assets_popups_rules_buttonExit_png));
        this.exitButton.onPress.connect(this.onCloseButtonClick);
        this.exitButton.view.scale.set(0.8);
        this.addChild(this.exitButton.view);
    }

    private layout(): void {
        const referenceWidth = this.canvasScaler.referenceWidth;
        const referenceHeight = this.canvasScaler.referenceHeight;
        this.rulesContainer.position.set((referenceWidth - this.rulesContainer.width) * 0.5, (referenceHeight - this.rulesContainer.height) * 0.5);
        this.nextButton.view.position.set(this.rulesContainer.width - 375 - this.nextButton.view.width * 1.25, 570);
        this.previousButton.view.position.set(375 + this.previousButton.view.width * 0.25, 570);
        this.exitButton.view.position.set(1420, 190);
    }

    private onCloseButtonClick(): void {
        this.operationControls.complete();
    }

    private onNextButtonClick(): void {
        if (!this.rulesContainer.trySetNextImage()) {
            this.nextButton.view.visible = false;
        }
        this.previousButton.view.visible = true;
    }

    private onPreviousButtonClick(): void {
        if (!this.rulesContainer.trySetPreviousImage()) {
            this.previousButton.view.visible = false;
        }
        this.nextButton.view.visible = true;
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        window.screen.orientation.removeEventListener("change", this.onCloseButtonClick);
        this.nextButton.onPress.disconnect(this.onNextButtonClick);
        this.nextButton.destroy();
        this.previousButton.onPress.disconnect(this.onPreviousButtonClick);
        this.previousButton.destroy();
        this.exitButton.onPress.disconnect(this.onCloseButtonClick);
        this.exitButton.destroy();
    }
}
