import { Texture } from "pixi.js";
import { WildAnimationResourcePackage } from "./WildAnimationResourcePackage";

export class WildAnimationFramesProvider extends WildAnimationResourcePackage {
    private instance: Array<Texture>;

    public get animationFrames(): Array<Texture> {
        if (!this.loaded) {
            throw new Error("You can not use frames provider before actual package is loaded.");
        }
        if (this.instance == null) {
            this.instance = [
                this.assets_wildAnimation_wild_1_png,
                this.assets_wildAnimation_wild_2_png,
                this.assets_wildAnimation_wild_3_png,
                this.assets_wildAnimation_wild_4_png,
                this.assets_wildAnimation_wild_5_png,
                this.assets_wildAnimation_wild_6_png,
                this.assets_wildAnimation_wild_7_png,
                this.assets_wildAnimation_wild_8_png,
                this.assets_wildAnimation_wild_9_png,
                this.assets_wildAnimation_wild_10_png,
                this.assets_wildAnimation_wild_11_png,
                this.assets_wildAnimation_wild_12_png,
                this.assets_wildAnimation_wild_13_png,
                this.assets_wildAnimation_wild_14_png,
                this.assets_wildAnimation_wild_15_png,
                this.assets_wildAnimation_wild_16_png,
                this.assets_wildAnimation_wild_17_png,
                this.assets_wildAnimation_wild_18_png,
                this.assets_wildAnimation_wild_19_png,
                this.assets_wildAnimation_wild_20_png,
                this.assets_wildAnimation_wild_21_png,
                this.assets_wildAnimation_wild_22_png,
                this.assets_wildAnimation_wild_23_png,
                this.assets_wildAnimation_wild_24_png,
                this.assets_wildAnimation_wild_25_png,
                this.assets_wildAnimation_wild_26_png,
                this.assets_wildAnimation_wild_27_png,
                this.assets_wildAnimation_wild_28_png,
                this.assets_wildAnimation_wild_29_png,
                this.assets_wildAnimation_wild_30_png,
                this.assets_wildAnimation_wild_31_png,
                this.assets_wildAnimation_wild_32_png,
                this.assets_wildAnimation_wild_33_png,
                this.assets_wildAnimation_wild_34_png,
                this.assets_wildAnimation_wild_35_png,
                this.assets_wildAnimation_wild_36_png,
                this.assets_wildAnimation_wild_37_png,
                this.assets_wildAnimation_wild_38_png,
                this.assets_wildAnimation_wild_39_png,
                this.assets_wildAnimation_wild_40_png,
                this.assets_wildAnimation_wild_41_png,
                this.assets_wildAnimation_wild_42_png,
                this.assets_wildAnimation_wild_43_png,
                this.assets_wildAnimation_wild_44_png,
                this.assets_wildAnimation_wild_45_png,
                this.assets_wildAnimation_wild_46_png,
                this.assets_wildAnimation_wild_47_png,
                this.assets_wildAnimation_wild_48_png,
                this.assets_wildAnimation_wild_49_png
            ]
        }
        return this.instance;
    }
}