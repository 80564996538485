import { Graphics, IDestroyOptions } from "pixi.js";
import { CanvasScaler } from "../../../../canvas/CanvasScaler";
import { Signal } from 'typed-signals';

export class ClosePopupOnClickPanel extends Graphics {
    public readonly onClick: Signal<() => void>;

    public constructor(
        private readonly canvasScaler: CanvasScaler
    ) {
        super();
        this.onClick = new Signal();
        this.eventMode = 'static';
        this.beginFill(0xff0000, 0.001).drawRect(0, 0, canvasScaler.referenceWidth * 10, canvasScaler.referenceHeight * 10).endFill();
        this.onPanelClick = this.onPanelClick.bind(this);
        this.addListener("pointerup", this.onPanelClick);
    }

    private onPanelClick(): void {
        this.onClick.emit();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.removeListener("pointerup", this.onPanelClick);
        super.destroy(options);
    }
}