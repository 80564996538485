import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class BonusGameOpenResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("BonusGameOpenResourcesPackage");
    }
    public get assets_popups_bonusGameOpen_Button_BonusGame_Open_png(): Texture {
        return this.resource<Texture>("assets/popups/bonusGameOpen/Button_BonusGame_Open.png");
    }
    public get assets_popups_bonusGameOpen_Popup_BonusGame_Open_png(): Texture {
        return this.resource<Texture>("assets/popups/bonusGameOpen/Popup_BonusGame_Open.png");
    }
}

