import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class WildAnimationResourcePackage extends BundleResourcesPackage {
    public constructor() {
        super("WildAnimationResourcePackage");
    }
    public get assets_wildAnimation_wildAnimation_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/wildAnimation/wildAnimation_1x.atlas.json");
    }
    public get assets_wildAnimation_wild_0_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_0.png"];
    }
    public get assets_wildAnimation_wild_10_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_10.png"];
    }
    public get assets_wildAnimation_wild_11_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_11.png"];
    }
    public get assets_wildAnimation_wild_12_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_12.png"];
    }
    public get assets_wildAnimation_wild_13_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_13.png"];
    }
    public get assets_wildAnimation_wild_14_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_14.png"];
    }
    public get assets_wildAnimation_wild_15_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_15.png"];
    }
    public get assets_wildAnimation_wild_16_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_16.png"];
    }
    public get assets_wildAnimation_wild_17_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_17.png"];
    }
    public get assets_wildAnimation_wild_18_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_18.png"];
    }
    public get assets_wildAnimation_wild_19_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_19.png"];
    }
    public get assets_wildAnimation_wild_2_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_2.png"];
    }
    public get assets_wildAnimation_wild_20_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_20.png"];
    }
    public get assets_wildAnimation_wild_21_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_21.png"];
    }
    public get assets_wildAnimation_wild_22_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_22.png"];
    }
    public get assets_wildAnimation_wild_23_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_23.png"];
    }
    public get assets_wildAnimation_wild_24_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_24.png"];
    }
    public get assets_wildAnimation_wild_25_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_25.png"];
    }
    public get assets_wildAnimation_wild_26_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_26.png"];
    }
    public get assets_wildAnimation_wild_27_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_27.png"];
    }
    public get assets_wildAnimation_wild_28_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_28.png"];
    }
    public get assets_wildAnimation_wild_29_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_29.png"];
    }
    public get assets_wildAnimation_wild_3_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_3.png"];
    }
    public get assets_wildAnimation_wild_30_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_30.png"];
    }
    public get assets_wildAnimation_wild_31_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_31.png"];
    }
    public get assets_wildAnimation_wild_32_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_32.png"];
    }
    public get assets_wildAnimation_wild_33_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_33.png"];
    }
    public get assets_wildAnimation_wild_34_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_34.png"];
    }
    public get assets_wildAnimation_wild_35_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_35.png"];
    }
    public get assets_wildAnimation_wild_36_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_36.png"];
    }
    public get assets_wildAnimation_wild_37_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_37.png"];
    }
    public get assets_wildAnimation_wild_38_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_38.png"];
    }
    public get assets_wildAnimation_wild_39_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_39.png"];
    }
    public get assets_wildAnimation_wild_4_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_4.png"];
    }
    public get assets_wildAnimation_wild_40_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_40.png"];
    }
    public get assets_wildAnimation_wild_41_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_41.png"];
    }
    public get assets_wildAnimation_wild_42_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_42.png"];
    }
    public get assets_wildAnimation_wild_43_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_43.png"];
    }
    public get assets_wildAnimation_wild_44_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_44.png"];
    }
    public get assets_wildAnimation_wild_45_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_45.png"];
    }
    public get assets_wildAnimation_wild_46_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_46.png"];
    }
    public get assets_wildAnimation_wild_47_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_47.png"];
    }
    public get assets_wildAnimation_wild_5_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_5.png"];
    }
    public get assets_wildAnimation_wild_6_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_6.png"];
    }
    public get assets_wildAnimation_wild_7_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_7.png"];
    }
    public get assets_wildAnimation_wild_8_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_8.png"];
    }
    public get assets_wildAnimation_wild_9_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_9.png"];
    }
    public get assets_wildAnimation_wild_1_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_1.png"];
    }
    public get assets_wildAnimation_wild_48_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_48.png"];
    }
    public get assets_wildAnimation_wild_49_png(): Texture {
        return this.assets_wildAnimation_wildAnimation_1x_atlas_json.textures["wild_49.png"];
    }
    public get assets_wildAnimation_wildAnimation_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/wildAnimation/wildAnimation_1x.atlas.png");
    }
}

