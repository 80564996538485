import { TextStyle, TextStyleFill } from "pixi.js";

export class UITextStyle extends TextStyle {
    public constructor(fontSize: number = 42, fontFamily: string = "Sigmar Regular", color: TextStyleFill = 0xfcffe7) {
        super({
            fontFamily: fontFamily,
            fill: color,
            fontSize,
            dropShadow: true,
            dropShadowAngle: 1.51,
            stroke: "#000000",
            strokeThickness: 2,
        });
    }
}