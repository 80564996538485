import { EmitterConfigV3 } from "@pixi/particle-emitter";
import { Texture } from "pixi.js";

export class BubblesEffectConfig {
    private emitterConfig: EmitterConfigV3;
    public get EmitterConfig(): EmitterConfigV3 {
        return this.emitterConfig;
    }

    public constructor(texture: Texture, minScale: number, maxScale: number, frequency: number, widthRect: number) {
        this.emitterConfig = {
            lifetime: {
                min: 20,
                max: 40
            },
            frequency: frequency,
            emitterLifetime: -1,
            maxParticles: 300,
            addAtBack: false,
            autoUpdate: true,
            pos: {
                x: 0,
                y: 0
            },
            behaviors: [
                {
                    type: "alpha",
                    config: {
                        alpha: {
                            list: [
                                {
                                    time: 0,
                                    value: 1
                                },
                                {
                                    time: 1,
                                    value: 0
                                }
                            ]
                        }
                    }
                },
                {
                    type: "moveAcceleration",
                    config: {
                        accel: {
                            x: 0,
                            y: -200
                        },
                        minStart: 120,
                        maxStart: 200,
                        maxSpeed: 200,
                        rotate: true
                    }
                },
                {
                    type: "scale",
                    config: {
                        scale: {
                            list: [
                                {
                                    time: 0,
                                    value: minScale
                                },
                                {
                                    time: 1,
                                    value: maxScale
                                }
                            ]
                        },
                        minMult: 0.5
                    }
                },
                {
                    type: "colorStatic",
                    config: {
                        color: '#ffffff'
                    }
                },
                {
                    type: "rotationStatic",
                    config: {
                        min: -90,
                        max: -90,
                    }
                },
                {
                    type: "movePath",
                    config: {
                        path: "sin(x/20)*15",
                        speed: {
                            list: [
                                {
                                    time: 0,
                                    value: 50
                                },
                                {
                                    time: 1,
                                    value: 70
                                }
                            ]
                        },
                        minMult: 1
                    }
                },
                {
                    type: 'textureSingle',
                    config: {
                        texture: texture
                    }
                },
                {
                    type: 'spawnShape',
                    config: {
                        type: 'rect',
                        data: {
                            x: 0,
                            y: 0,
                            w: widthRect,
                            h: 0
                        }
                    }
                }
            ]
        };
    }
}
