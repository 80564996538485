import { Event } from "event-system";
import { IRenderer, utils } from "pixi.js";
import { ICanvasScaler } from "./ICanvasScaler";

export class CanvasScaler implements ICanvasScaler {
    private renderer: IRenderer;
    public readonly referenceWidth: number;
    public readonly referenceHeight: number;
    public readonly referenceRatio: number;
    public readonly onResize: Event<Scales>;
    public readonly safeAreaInsetTop: number;
    public readonly safeAreaInsetBottom: number;
    public readonly safeAreaInsetLeft: number;
    public readonly safeAreaInsetRight: number;
    public get safeAreaOffset(): number {
        // let heightDifference = window.innerWidth > window.innerHeight ? this.safeAreaInsetLeft + this.safeAreaInsetRight : this.safeAreaInsetTop + this.safeAreaInsetBottom;
        // // iOS return value in css pixels??? Check? prove?
        // heightDifference *= window.devicePixelRatio;
        const availableWidth = this.browserWidth;
        const availableHeight = this.browserHeight;
        if (availableWidth > availableHeight) {
            return 0;
        }
        const neededHeight = Math.ceil(availableWidth / this.referenceRatio);
        if (availableHeight >= neededHeight) {
            return 0;
        }
        return Math.min(neededHeight - availableHeight, 100);
    }
    public get browserWidth(): number {
        return window.innerWidth;
    }
    public get browserHeight(): number {
        return window.innerHeight;
    }
    public get referenceScale(): number {
        return this.browserHeight / this.referenceHeight;
    }

    public constructor(renderer: IRenderer, width: number, height: number) {
        this.renderer = renderer;
        this.safeAreaInsetTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat") || "0");
        this.safeAreaInsetBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab") || "0");
        this.safeAreaInsetLeft = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sal") || "0");
        this.safeAreaInsetRight = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sar") || "0");
        this.referenceWidth = width;
        this.referenceHeight = height;
        this.referenceRatio = width / height;
        this.onResize = new Event<Scales>();
        this.resize = this.resize.bind(this);
        window.onresize = this.resize;
        if (utils.isMobile.apple.device) {
            window.addEventListener("orientationchange", this.resize);
        }
        this.resize();
    }

    public getSize(): Scales {
        let height = this.browserHeight + this.safeAreaOffset;
        let width = Math.ceil(height * this.referenceRatio);
        if (this.browserWidth < this.browserHeight) {
            // hack to use full width in portrait mode
            width = this.browserWidth;
            height = width / this.referenceRatio;
        }
        return new Scales(width, height);
    }

    public resize(): void {
        const scales = this.getSize();
        this.onResize.emit(scales);
        this.renderer.resize(this.browserWidth, this.browserHeight);
    }

    public get BASE_RESOLUTION(): { height: number, width: number } {
        return { height: this.referenceHeight, width: this.referenceWidth };
    }
}

export class Scales {
    public readonly height: number;
    public readonly width: number;

    public constructor(width: number, height: number) {
        this.height = Math.floor(height);
        this.width = Math.floor(width);
    }
}
