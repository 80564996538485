import { ColorMatrixFilter, Container, IDestroyOptions, Sprite, Texture } from "pixi.js";
import { SymbolType } from "./SymbolType";
import gsap from "gsap";
import { AsyncAnimatedSprite } from "../../../common/components/AsyncAnimatedSprite";
import { IAudioManager } from "../../../../audio/IAudioManager";
import { AudioNames } from "../../../../audio/AudioNames";
import { WinFrameAnimation } from "../winFrame/WinFrameAnimation";
import { WinLinesFactoryContainer } from "../winFrame/WinLinesFactoryContainer";

export class SlotSymbol extends Container {
    private static darkenAlpha: number = 0.5;
    private _symbolId: number;
    private containerSize: number;
    public get symbolId() {
        return this._symbolId;
    }
    public set symbolId(id: number) {
        this._symbolId = id;
    }
    private icon: Sprite | AsyncAnimatedSprite;
    private anticipationTl: gsap.core.Timeline;
    private winFrame: WinFrameAnimation;
    private winFrameTimeline: gsap.core.Timeline;
    private isHighlight: boolean = false;
    private colorMatrixFilter: ColorMatrixFilter;
    public constructor(
        symbolId: number,
        texture: Texture | Array<Texture>,
        containerSize: number,
        private readonly audioManager: IAudioManager,
        private readonly winLinesFactoryContainer: WinLinesFactoryContainer,
    ) {
        super();
        this._symbolId = symbolId;
        if (texture instanceof Array) {
            this.icon = new AsyncAnimatedSprite(texture);

        } else {
            this.icon = new Sprite(texture);
        }
        this.containerSize = containerSize;
        this.colorMatrixFilter = new ColorMatrixFilter();
        this.colorMatrixFilter.resolution = 0;
        this.colorMatrixFilter.brightness(1, false);
        this.icon.filters = [this.colorMatrixFilter];
        this.updateInternalLayout();
        this.addChild(this.icon);
        window.screen.orientation.addEventListener("change", () => this.onScreenOrientationChange());
    }

    public updateData(symbolId: number, texture: Texture | Array<Texture>, type: SymbolType): void {
        if (texture instanceof Array) {
            if (!(this.icon instanceof AsyncAnimatedSprite)) {
                this.removeChild(this.icon);
                this.icon = new AsyncAnimatedSprite(texture);
                this.addChild(this.icon);
            } else {
                this.icon.textures = texture;
            }
        } else {
            if (this.icon instanceof AsyncAnimatedSprite) {
                this.removeChild(this.icon);
                this.icon = new Sprite(texture);
                this.addChild(this.icon);
            } else {
                this.icon.texture = texture;
            }
        }
        if (this.icon.filters == null) {
            this.icon.filters = [this.colorMatrixFilter];
        }
        this.symbolId = symbolId;
        this.updateInternalLayout();
    }

    private updateInternalLayout(): void {
        const texture = this.icon.texture;
        const ratio = texture.height / texture.width;
        this.icon.width = Math.min(texture.width, this.containerSize);
        this.icon.height = this.icon.width * ratio;
        this.icon.pivot.set(this.icon.width * 0.5, this.icon.height * 0.5);
        this.icon.position.set(this.icon.width * 0.5, this.icon.height * 0.5);
    }

    private onScreenOrientationChange(): void {
        if (this.winFrame) {
            this.winLinesFactoryContainer.deleteWinFrame(this.winFrame);
            this.winFrame = null;
            this.addWinFrame();
        }
    }

    public async playSymbolHighlightAnimation(): Promise<void> {
        this.audioManager.play(AudioNames.IdleSymbol);
        await gsap.to(this.icon, { pixi: { scaleX: 1.05, scaleY: 1.05, brightness: 2 }, duration: 0.2 });
        await gsap.to(this.icon, { pixi: { scaleX: 1, scaleY: 1, brightness: 1 }, duration: 0.2 });
    }

    public checkSymbolId(symbolId: number): boolean {
        return this._symbolId === symbolId;
    }

    public playAnticipationAnimation(): void {
        this.stopAnticipationAnimation();
        this.anticipationTl = gsap.timeline({ repeat: -1, yoyo: true, delay: 1 }).to(this.icon, { pixi: { brightness: 2 }, duration: 1, delay: 1 });
    }

    public stopAnticipationAnimation(): void {
        if (this.anticipationTl) {
            this.anticipationTl.pause().clear();
            gsap.to(this.icon, { pixi: { brightness: 1 }, duration: 1 });
        }
    }

    public async highlight(animationStopFrame: "first" | "last" = "first", darkenInTheEnd: boolean = false): Promise<void> {
        this.isHighlight = true;
        if (this.winFrame) {
            this.winFrameTimeline = gsap.timeline()
                .to(this.winFrame, { alpha: 1, duration: 0.25 })
                .to(this, { alpha: 1, duration: 0.25 }, 0);
            await this.winFrameTimeline.play();
        } else {
            this.cancelDarken();
        }
        if (this.icon instanceof AsyncAnimatedSprite) {
            const icon = this.icon;
            await icon.playAsync();
            icon.gotoAndStop(animationStopFrame === "first" ? 0 : icon.totalFrames - 1);
        }
        if (this.winFrame) {
            this.winFrameTimeline = gsap.timeline({ delay: this.icon instanceof AsyncAnimatedSprite ? 0 : 1.63 })
                .to(this.winFrame, { alpha: 0, duration: 0.25 });
            if (darkenInTheEnd) {
                this.winFrameTimeline.add(gsap.to(this, { alpha: SlotSymbol.darkenAlpha, duration: 0.25 }), 0);
            }
            await this.winFrameTimeline.play();
        } else if (darkenInTheEnd && this.isHighlight) {
            this.darken();
        }
    }

    public stopHighlight(): void {
        this.isHighlight = false;
        this.winFrameTimeline?.pause().clear();
        this.winLinesFactoryContainer.deleteWinFrame(this.winFrame);
        this.winFrame = null;
    }

    public darken(): void {
        this.alpha = SlotSymbol.darkenAlpha;
    }

    public cancelDarken(): void {
        this.alpha = 1;
    }

    public isSticky(): boolean {
        return this._symbolId === 111111;
    }

    public skipWinLineSymbol(): boolean {
        return [9, 101010, 111111].includes(this._symbolId);
    }

    public addWinFrame(): SlotSymbol {
        if (!this.winFrame && !this.skipWinLineSymbol()) {
            this.winFrame = this.winLinesFactoryContainer.createWinFrame(this);
        }
        return this;
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.stopAnticipationAnimation();
        this.stopHighlight();
        super.destroy(options);
    }
}
