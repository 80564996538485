import { Container, DisplayObject } from "pixi.js";
import { SceneManager, SceneManagerConfig, StageLayer } from "scene-manager";

export class SceneManagerWrapper extends SceneManager {
    public readonly backgroundLayer: StageLayer;

    public constructor(stage: Container<DisplayObject>, config?: SceneManagerConfig & { backgroundLayer?: StageLayer }) {
        super(stage, config);
        this.backgroundLayer = config.backgroundLayer ?? new StageLayer();
        stage.addChildAt(this.backgroundLayer, 0);
    }
}