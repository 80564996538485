import { Container, IDestroyOptions, Sprite } from "pixi.js";
import { SlotResourcesPackage } from "../resources/SlotResourcesPackage";
import { Reel } from "./Reel";
import { SymbolsAssetsProvider } from "./SymbolsAssetsProvider";
import { ICanvasScaler } from "by/gamefactory/canvas/ICanvasScaler";
import { GameConfig } from "../../../GameConfig";
import { Anticipation } from "./anticipation/Anticipation";
import { IAudioManager } from "../../../audio/IAudioManager";
import { SpinResult } from "../models/data/SpinResult";
import { WinLine } from "../models/data/WinLine";
import { AudioNames } from "../../../audio/AudioNames";
import { BigWildAnimationFramesProvider } from "../resources/BigWildAnimationFramesProvider";
import { AnticipationFramesProvider } from "./anticipation/resources/AnticipationFramesProvider";
import { BonusSymbolFramesProvider } from "../resources/BonusSymbolFramesProvider";
import { WildAnimationFramesProvider } from "../resources/WildAnimationFramesProvider";
import { Title } from "./ui/components/Title";
import { FreeSpinsModeResourcesPackage } from "../resources/FreeSpinsModeResourcesPackage";
import { SlotViewModel } from "../models/SlotViewModel";
import { StickyWildAnimationFramesProvider } from "../resources/StickyWildAnimationFramesProvider";
import { FreeSpinsSymbolFramesProvider } from "../resources/FreeSpinsSymbolFramesProvider";
import { DeviceCheckUtils } from "../../common/utils/DeviceCheckUtils";
import { Event } from "event-system";
import { SlotSymbol } from "./symbols/SlotSymbol";
import { WinLinesFactoryContainer } from "./winFrame/WinLinesFactoryContainer";

type Margin = {
    top: number;
    left: number;
    right: number;
    bottom: number;
}

export class SlotFrame extends Container {
    public static readonly SYMBOL_WIDTH = 230;
    private static readonly REEL_WIDTH = 240;
    private static readonly REEL_HEIGHT = 720;
    private static readonly MARGIN: Margin = { top: 125, left: 150, right: 135, bottom: 125 };
    private static readonly FIRST_REEL_ANIMATION_TIME = 800; // ms
    private static readonly FIRST_REEL_FAST_ANIMATION_TIME = 400; // ms
    private readonly symbolsAssetsProvider: SymbolsAssetsProvider;
    private substrate: Sprite;
    private title: Title;
    private readonly reels: Array<Reel> = [];
    private frame: Sprite;
    private anticipation: Anticipation;
    private defaultSizes: { width: number; height: number; };
    private scaledSizes: { width: number; height: number; };
    public slotSizesChange: Event<void>;
    private _isFastStop: boolean = false;
    public set isFastStop(value: boolean) {
        this._isFastStop = value;
    }
    private _isSkipWinLines: boolean = false;
    public set isSkipWinLines(value: boolean) {
        this._isSkipWinLines = value;
    }
    private _winLinesSymbols: Array<Array<SlotSymbol>> = [];
    private shouldPlayWinLines: boolean;


    public constructor(
        private readonly slotResources: SlotResourcesPackage,
        private readonly anticipationResources: AnticipationFramesProvider,
        private readonly bigWildAnimationFramesProvider: BigWildAnimationFramesProvider,
        private readonly bonusSymbolFramesProvider: BonusSymbolFramesProvider,
        private readonly wildAnimationFramesProvider: WildAnimationFramesProvider,
        private readonly freeSpinsModeResources: FreeSpinsModeResourcesPackage,
        private readonly stickyWildAnimationFramesProvider: StickyWildAnimationFramesProvider,
        private readonly freeSpinsSymbolFramesProvider: FreeSpinsSymbolFramesProvider,
        private readonly slotViewModel: SlotViewModel,
        private readonly gameConfig: GameConfig,
        private readonly canvasScaler: ICanvasScaler,
        private readonly audioManager: IAudioManager,
        private readonly winLinesFactoryContainer: WinLinesFactoryContainer
    ) {
        super();
        this.symbolsAssetsProvider = new SymbolsAssetsProvider(
            this.slotResources,
            this.bigWildAnimationFramesProvider,
            this.bonusSymbolFramesProvider,
            this.wildAnimationFramesProvider,
            this.stickyWildAnimationFramesProvider,
            this.freeSpinsSymbolFramesProvider,
            this.slotViewModel
        );
        this.slotSizesChange = new Event<void>();
        this.onScreenOrientationChange = this.onScreenOrientationChange.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenOrientationChange);
        this.build();
        this.layout();
    }

    private build(): void {
        this.substrate = new Sprite(this.slotResources.assets_slot_field_substrate_png);
        this.addChild(this.substrate);

        for (let i = 0; i < this.gameConfig.reelsCount; i++) {
            const reel = new Reel(
                this.symbolsAssetsProvider,
                this.canvasScaler,
                this.audioManager,
                this.slotViewModel,
                this.gameConfig.symbolsInReel,
                SlotFrame.REEL_WIDTH,
                SlotFrame.REEL_HEIGHT,
                SlotFrame.SYMBOL_WIDTH,
                this.winLinesFactoryContainer
            );
            this.reels.push(reel);
            this.addChild(reel);
        }

        this.frame = new Sprite(this.slotResources.assets_slot_field_frame_png);
        this.addChild(this.frame);

        this.title = new Title(this.freeSpinsModeResources, this.slotResources, this.slotViewModel);
        this.addChild(this.title);

        this.anticipation = new Anticipation(this.anticipationResources, this.audioManager);
        this.addChild(this.anticipation);

        this.defaultSizes = { width: this.width, height: this.height };
        this.scaledSizes = { width: 2172, height: 1510 };
    }

    private layout(): void {
        this.updateLayout();
        this.substrate.position.set(150, 115);
        this.reels.forEach((reel, i) => reel.position.set(SlotFrame.MARGIN.left + i * SlotFrame.REEL_WIDTH, SlotFrame.MARGIN.top));
    }

    private onScreenOrientationChange(): void {
        this.updateLayout();
        this.slotSizesChange.emit();
    }

    private updateLayout(): void {
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.title.scale.set(1.7);
            this.title.position.set(170, -170);
            this.width = this.scaledSizes.width;
            this.height = this.scaledSizes.height;
        } else {
            this.title.scale.set(1);
            this.title.position.set(408, 25);
            this.width = this.defaultSizes.width;
            this.height = this.defaultSizes.height;
        }
    }
    
    public async spinWithAnimation(): Promise<void> {
        const allReelsSpins: Array<Promise<void>> = this.reels.map((reel, index) => reel.spin(index * 0.05));
        await Promise.all(allReelsSpins);
    }

    public async stopWithResult(spinResult: SpinResult, reelsToStop: number): Promise<void> {
        const stopSpinAnimations: Array<Promise<void>> = [];
        const startReelIndex = this.gameConfig.reelsCount - reelsToStop;
        for (let i = startReelIndex; i < this.gameConfig.reelsCount; i++) {
            const reelData = [
                spinResult.field[i + this.gameConfig.reelsCount * 2],
                spinResult.field[i + this.gameConfig.reelsCount],
                spinResult.field[i],
                spinResult.field[i + this.gameConfig.reelsCount * 2]
            ];
            stopSpinAnimations.push(this.getReel(i).stopSpin(reelData));
        }
        await Promise.all(stopSpinAnimations);
        this.finalizeReelsSpin();
    }

    public async stopWithAnimation(spinResult: SpinResult): Promise<number | void> {
        const field = spinResult.field;

        const anticipationData: { specialSymbolId: number, reelId: number } = this.anticipation.countAnticipation(spinResult.field);
        const reelsStopData = [
            [field[10], field[5], field[0], field[10]],
            [field[11], field[6], field[1], field[11]],
            [field[12], field[7], field[2], field[12]],
            [field[13], field[8], field[3], field[13]],
            [field[14], field[9], field[4], field[14]]
        ];
        this.audioManager.play(AudioNames.ReelsRotation, true);

        await this.delay(this._isFastStop ? SlotFrame.FIRST_REEL_FAST_ANIMATION_TIME : SlotFrame.FIRST_REEL_ANIMATION_TIME);
        if (this._isFastStop) {
            return 5;
        }
        await this.getReel(0).stopSpin(reelsStopData[0]);
        if (this._isFastStop) {
            return 4;
        }
        await this.getReel(1).stopSpin(reelsStopData[1]);
        if (this._isFastStop) {
            return 3;
        }
        if (anticipationData !== null && anticipationData.reelId <= 1 && !this._isFastStop) {
            this.getReel(0).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(1).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.playSymbolsAnticipationAnimation(0, anticipationData.specialSymbolId);
            this.playSymbolsAnticipationAnimation(1, anticipationData.specialSymbolId);
            this.getReel(3).darkenAll();
            this.getReel(4).darkenAll();
            await this.playReelAnticipationAnimation(2);
        }
        await this.getReel(2).stopSpin(reelsStopData[2]);
        if (this._isFastStop) {
            return 2;
        }
        if (anticipationData !== null && anticipationData.reelId <= 2  && !this._isFastStop) {
            this.getReel(0).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(1).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(2).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.playSymbolsAnticipationAnimation(0, anticipationData.specialSymbolId);
            this.playSymbolsAnticipationAnimation(1, anticipationData.specialSymbolId);
            this.playSymbolsAnticipationAnimation(2, anticipationData.specialSymbolId);
            this.getReel(3).cancelDarkenAll();
            await this.playReelAnticipationAnimation(3);
        }
        await this.getReel(3).stopSpin(reelsStopData[3]);
        if (this._isFastStop) {
            return 1;
        }
        if (anticipationData !== null && anticipationData.reelId <= 3 && !this._isFastStop) {
            this.getReel(0).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(1).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(2).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(3).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.playSymbolsAnticipationAnimation(0, anticipationData.specialSymbolId);
            this.playSymbolsAnticipationAnimation(1, anticipationData.specialSymbolId);
            this.playSymbolsAnticipationAnimation(2, anticipationData.specialSymbolId);
            this.playSymbolsAnticipationAnimation(3, anticipationData.specialSymbolId);
            this.getReel(4).cancelDarkenAll();
            await this.playReelAnticipationAnimation(4);
        }
        await this.getReel(4).stopSpin(reelsStopData[4]);
        if (anticipationData !== null && !this._isFastStop) {
            this.getReel(0).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(1).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(2).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(3).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
            this.getReel(4).darkenAllExceptSymbolIds([anticipationData.specialSymbolId]);
        }

        this.finalizeReelsSpin();
    }

    private finalizeReelsSpin(): void {
        this.audioManager.stop(AudioNames.ReelsRotation);
        this.anticipation.forceStop();
        this.stopAllSymbolsAnticipationAnimation();
        this.cancelAllReelsDarken();
        this.isFastStop = false;
    }

    public async highlightSpecialSymbolsCombination(): Promise<void> {
        const bonusSymbols = this.reels.map(reel => reel.getSpecialSymbols(8)).flat();
        const freeSpinsSymbols = this.reels.map(reel => reel.getSpecialSymbols(7)).flat();
        const highlightAnimations: Array<Promise<void>> = [];
        if (bonusSymbols.length > 2) {
            this.audioManager.play(AudioNames.BonusSymbol);
            bonusSymbols.forEach(bonusSymbol => {
                highlightAnimations.push(bonusSymbol.highlight("last"));
            });
        } else if (freeSpinsSymbols.length > 2) {
            this.audioManager.play(AudioNames.FreeSpinsSymbols);
            freeSpinsSymbols.forEach(freeSpinsSymbol => {
                highlightAnimations.push(freeSpinsSymbol.highlight());
            });
        }
        await Promise.all(highlightAnimations);
    }

    public getReel(index: number): Reel {
        if (index < 0 || index >= this.reels.length) {
            throw new Error(`Failed to find reel with index ${index}. Index out of bounds.`);
        }
        return this.reels[index];
    }

    public async playReelAnticipationAnimation(reelId: number): Promise<void> {
        this.anticipation.playReelAnimation(reelId);
        // total time of anticipation frame is 10 * 200ms = 2000 ms
        for (let i = 0; i < 10; i++) {
            if (this._isFastStop) {
                return;
            }
            await this.delay(200);
        }
        this.anticipation.stopReelAnimation(reelId);
    }

    public darkenAllReels(): void {
        this.reels.forEach(reel => reel.darkenAll());
    }

    public darkenAllReelsExceptSymbolIds(ids: Array<number>): void {
        this.reels.forEach(reel => reel.darkenAllExceptSymbolIds(ids));
    }

    public reelsFader(spinResult: SpinResult): void {
        if (spinResult.winning.prize > 0) {
            this.darkenAllReels();
        }
    }

    public cancelAllReelsDarken(): void {
        this.reels.forEach(reel => reel.cancelDarkenAll());
    }

    private playSymbolsAnticipationAnimation(reelId: number, symbolId: number): void {
        this.getReel(reelId).playSymbolsAnticipationAnimation(symbolId);
    }

    private stopAllSymbolsAnticipationAnimation(): void {
        this.reels.forEach(reel => reel.stopSymbolsAnticipationAnimation());
    }

    public createWinLinesAnimations(winLines: WinLine[]): void {
        this._winLinesSymbols = winLines.map((winLine) => {
            const symbols: Array<SlotSymbol> = [];
            for (let i = 0; i < winLine.matched.length; i++) {
                const slotSymbol = this.getReel(i).getSymbol(winLine.line[i] + 1).addWinFrame();
                symbols.push(slotSymbol);
            }
            return symbols;
        });
    }

    public async playWinLinesAnimations(winLines: WinLine[]): Promise<void> {
        this.shouldPlayWinLines = true;
        this.createWinLinesAnimations(winLines);
        this.darkenAllReelsExceptSymbolIds([101010]);
        this.audioManager.play(AudioNames.WinFrame);
        let isShowedFirstLine = false;
        if (this.slotViewModel.autoSpinEnabled.value && !this.destroyed) {
            isShowedFirstLine = true;
            await this.playWinLineAnimation(this._winLinesSymbols[0]);
        }
        this.showAllWinLines(isShowedFirstLine);
    }

    private async showAllWinLines(isShowedFirstLine: boolean): Promise<void> {
        while (this.shouldPlayWinLines) {
            for (const line of this._winLinesSymbols) {
                if (this._winLinesSymbols.length > 1 && this._winLinesSymbols.indexOf(line) == 0 && isShowedFirstLine) {
                    isShowedFirstLine = false;
                    continue;
                }
                await this.playWinLineAnimation(line);
                if (this.destroyed || !this.shouldPlayWinLines) {
                    return;
                }
                await this.delay(1000);
                if (this.destroyed || !this.shouldPlayWinLines) {
                    return;
                }
            }
        }
    }

    private async playWinLineAnimation(symbols: SlotSymbol[]): Promise<void> {
        const lineAnimation: Array<Promise<void>> = symbols.map(symbol => symbol.highlight("first", !symbol.skipWinLineSymbol()));
        await Promise.all(lineAnimation);
    }

    public clearWinLines(): void {
        this.shouldPlayWinLines = false;
        this._winLinesSymbols.forEach(symbols => symbols.forEach(symbol => symbol.stopHighlight()));
        this._winLinesSymbols = [];
        this.cancelAllReelsDarken();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        window.screen.orientation.removeEventListener("change", this.onScreenOrientationChange);
    }

    private delay(timeMs: number) {
        return new Promise(resolve => setTimeout(resolve, timeMs));
    }
}