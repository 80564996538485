import { inject, injectable } from "@launchtray/tsyringe-async";
import { INetworkClient } from "../../network/INetworkClient";
import { InjectableInterfaces } from "../../composition/InjectableInterfaces";
import { IAuthModel } from "./IAuthModel";
import { VariableSaver } from "../../tools/VariableSaver";
import { SignUpResponseDto } from "./data/response/SignUpResponseDto";
import { GuestSignUpDto } from "./data/request/GuestSignUpDto";
import { SignInResponseDto } from "./data/response/SignInResponseDto";
import { GuestSignInDto } from "./data/request/GuestSignInDto";
import CryptoES from 'crypto-es';

const UID_FIELD_NAME = "mdm-guest-uid";
const AUTH_GUEST_REGISTRATION = "auth/guest/registration";
const AUTH_GUEST_LOGIN = "auth/guest/login";
@injectable()
export class GuestAuthModel implements IAuthModel {
    private readonly networkClient: INetworkClient;
    private readonly variableSaver: VariableSaver;

    public constructor(
        @inject(InjectableInterfaces.INetworkClient) networkClient: INetworkClient,
        variableSaver: VariableSaver
    ) {
        this.networkClient = networkClient;
        this.variableSaver = variableSaver;
    }

    public async auth(): Promise<boolean> {
        if (!this.variableSaver.exist(UID_FIELD_NAME)) {
            console.log("Trying to register new guest.");
            // Register new guest
            // Generate new UID
            const uid = this.md5((Date.now() + (Math.random() * 100000)).toString());
            const registrationResult = await this.networkClient.post<SignUpResponseDto, GuestSignUpDto>(AUTH_GUEST_REGISTRATION, { uid: uid });
            if (registrationResult == null || registrationResult.registration == false) {
                console.log(`Failed to register User with uid: ${uid}. Server response is empty or registration result is false.`);
                return false;
            }
            this.variableSaver.saveString(UID_FIELD_NAME, uid);
        }
        const uid = this.variableSaver.getString(UID_FIELD_NAME);
        // login guest with uid
        console.log("Trying to login guest.");
        const loginResult = await this.networkClient.post<SignInResponseDto, GuestSignInDto>(AUTH_GUEST_LOGIN, { uid: uid });
        if (loginResult == null || loginResult.access_token == null || loginResult.access_token == "") {
            console.log(`Failed to login User with uid: ${uid}. Server response was empty or no valid access token present.`);
            return false;
        }
        this.networkClient.authToken = loginResult.access_token;
        console.log("Guest login success.");
        return true;
    }

    private md5(contents: string): string {
        return CryptoES.MD5(contents).toString();
    }

}