import { PromiseCompletionSource } from "scene-manager";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { IDestroyOptions, Sprite, Text } from "pixi.js";
import { IFocusable } from "../../../../layers/IFocusable";
import { InOutAnimatedPopup } from "../../../common/views/InOutAnimatedPopup";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../../audio/AudioManager";
import { UIResourcesPackage } from "../../../slot/components/ui/resources/UIResourcesPackage";
import { UITextStyle } from "../../../slot/components/ui/UITextStyle";
import { CurrencyFormatUtils } from "../../../common/utils/NumberFormatUtils";
import { BetConfig } from "../../../slot/components/ui/components/BetField";

export class MobileBetView extends InOutAnimatedPopup<number> implements IFocusable {
    public readonly focusable: boolean = true;
    private substrate: Sprite;
    private totalBetLabel: Sprite;
    private _totalBet: number;
    private minusButton: DefaultHoverHighlightButton;
    private betPerLineContainer: Sprite;
    private totalBetText: Text;
    private _betPerLine: number;
    private plusButton: DefaultHoverHighlightButton;
    private cancelButton: DefaultHoverHighlightButton;
    private confirmButton: DefaultHoverHighlightButton;

    public set betPerLine(value: number) {
        this._betPerLine = value;
        
        this.totalBet = value;
    }
    
    private set totalBet(value: number) {
        this._totalBet = BetConfig.TOTAL_BETS[value - 1];
        this.totalBetText.text = CurrencyFormatUtils.getFormatedCurrency(this._totalBet);
    }

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly canvasScaler: ICanvasScaler,
        private readonly initialTotalBet: number,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        audioManager: AudioManager,
        operationControls: PromiseCompletionSource<number>
    ) {
        super(audioManager, operationControls);
        this._totalBet = initialTotalBet;
        this._betPerLine = BetConfig.TOTAL_BETS.indexOf(initialTotalBet) + 1;
        this.onMinusButtonClick = this.onMinusButtonClick.bind(this);
        this.onPlusButtonClick = this.onPlusButtonClick.bind(this);
        this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        window.screen.orientation.addEventListener("change", this.onCancelButtonClick);
        this.build();
        this.layout();
    }

    private build(): void {
        this.substrate = new Sprite(this.resources.assets_ui_mobile_bet_field_substrate_png);
        this.addChild(this.substrate);

        this.totalBetLabel = new Sprite(this.resources.assets_ui_mobile_bet_field_totalBetLabel_png);
        this.substrate.addChild(this.totalBetLabel);

        const minusSprite = new Sprite(this.resources.assets_ui_mobile_bet_field_minus_button_png);
        this.minusButton = this.defaultHoverHighlightButtonFactory.createButton(minusSprite);
        this.minusButton.onPress.connect(this.onMinusButtonClick);
        this.substrate.addChild(this.minusButton.view);

        this.betPerLineContainer = new Sprite(this.resources.assets_ui_mobile_bet_field_bet_container_png);
        this.substrate.addChild(this.betPerLineContainer);
        this.totalBetText = new Text(CurrencyFormatUtils.getFormatedCurrency(this._totalBet), new UITextStyle(23, "Sigmar Regular", 0xffffff));
        this.betPerLineContainer.addChild(this.totalBetText);

        const plusSprite = new Sprite(this.resources.assets_ui_mobile_bet_field_plus_button_png);
        this.plusButton = this.defaultHoverHighlightButtonFactory.createButton(plusSprite);
        this.plusButton.onPress.connect(this.onPlusButtonClick);
        this.substrate.addChild(this.plusButton.view);

        const cancelSprite = new Sprite(this.resources.assets_ui_mobile_bet_field_cancel_button_png);
        this.cancelButton = this.defaultHoverHighlightButtonFactory.createButton(cancelSprite);
        this.cancelButton.onPress.connect(this.onCancelButtonClick);
        this.addChild(this.cancelButton.view);

        const confirmSprite = new Sprite(this.resources.assets_ui_mobile_bet_field_confirm_button_png);
        this.confirmButton = this.defaultHoverHighlightButtonFactory.createButton(confirmSprite);
        this.confirmButton.onPress.connect(this.onConfirmButtonClick);
        this.addChild(this.confirmButton.view);
    }

    private layout(): void {
        this.width = 1200;
        this.height = 1200;
        this.substrate.position.set(80, 120);
        this.totalBetLabel.position.set((this.substrate.width - this.totalBetLabel.width) * 0.5, 65);
        this.minusButton.view.position.set(20, (this.substrate.height - this.plusButton.view.height) * 0.5);
        this.betPerLineContainer.position.set(82,(this.substrate.height - this.betPerLineContainer.height) * 0.5);
        this.totalBetText.anchor.set(0.5);
        this.totalBetText.position.set(this.betPerLineContainer.width * 0.5, this.betPerLineContainer.height * 0.5);
        this.plusButton.view.position.set(185, (this.substrate.height - this.plusButton.view.height) * 0.5);
        this.cancelButton.view.position.set(60, 390);
        this.confirmButton.view.position.set(220, 390);
    }

    private onMinusButtonClick() {
        const newBetPerLineValue = this._betPerLine - 1;
        if (newBetPerLineValue >= BetConfig.MIN_BET_PER_LINE_VALUE) {
            this.betPerLine = newBetPerLineValue;
        }
    }

    private onPlusButtonClick() {
        const newBetPerLineValue = this._betPerLine + 1;
        if (newBetPerLineValue <= BetConfig.MAX_BET_PER_LINE_VALUE) {
            this.betPerLine = newBetPerLineValue;
        }
    }

    private onCancelButtonClick(): void {
        this.operationControls.complete(this.initialTotalBet);
    }

    private onConfirmButtonClick(): void {
        this.operationControls.complete(this._totalBet);
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        window.screen.orientation.removeEventListener("change", this.onCancelButtonClick);
        this.minusButton.onPress.disconnect(this.onMinusButtonClick);
        this.minusButton.destroy();
        this.plusButton.onPress.disconnect(this.onPlusButtonClick);
        this.plusButton.destroy();
        this.cancelButton.onPress.disconnect(this.onCancelButtonClick);
        this.cancelButton.destroy();
        this.confirmButton.onPress.disconnect(this.onConfirmButtonClick);
        this.confirmButton.destroy();
        super.destroy(options);
    }

}
