import { DependencyContainer, injectable } from "@launchtray/tsyringe-async";
import { BundleResourcesPackage } from "resource-system";

export class PackageRegistrator {
    private readonly container: DependencyContainer;

    public constructor(container: DependencyContainer) {
        this.container = container;
    }

    public registerPackage(packageType: new () => BundleResourcesPackage, resourcePackage: BundleResourcesPackage): void {
        this.container.registerInstance(packageType, resourcePackage);
    }
}