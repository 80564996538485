import { BonusGameFinishView } from "./views/BonusGameFinishView";
import { PromiseCompletionSource, Viewable } from "scene-manager";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { BonusGameFinishResourcesPackage } from "./resources/BonusGameFinishResourcesPackage";
import { InjectableFactories } from "../../../../composition/InjectableFactories";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../audio/AudioManager";
import { BonusGameFinishController } from "./controllers/BonusGameFinishController";

@injectable()
export class BonusGameFinishScene extends Viewable<void> {
    public constructor(
        private readonly bonusGameFinishResources: BonusGameFinishResourcesPackage,
        private readonly bonusGameFinishController: BonusGameFinishController,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        @inject("prize") private readonly prize: number,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager
    ) {
        super();
        this.bonusGameFinishResources = bonusGameFinishResources;
        this.canvasScaler = canvasScaler;
    }
    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<void>();
        this.view = new BonusGameFinishView(
            this.bonusGameFinishResources,
            this.bonusGameFinishController,
            this.canvasScaler,
            this.prize,
            this.defaultHoverHighlightButtonFactory,
            this.audioManager,
            viewCanBeClosed
        );

        viewCanBeClosed.result.then(() => this.operationControls.complete());
    }
}
