import { injectable } from "@launchtray/tsyringe-async";
import { SlotViewModel } from "../../../slot/models/SlotViewModel";

@injectable()
export class BonusGameFinishController {
    public constructor(private readonly slotViewModel: SlotViewModel) { }

    public updateWinField(prize: number): void {
        this.slotViewModel.onUpdateWinPrize.emit(prize);
    }

}
