export enum InjectableInterfaces {
    IContextResolver = "IContextResolver",
    ICanvasScaler = "ICanvasScaler",
    INetworkClient = "INetworkClient",
    IUserModel = "IUserModel",
    IClientConfigProvider = "IClientConfigProvider",
    IAuthModel = "IAuthModel",
    IDataStorage = "IDataStorage",
    IAudioManager = "IAudioManager",
    IPlayerModel = "IPlayerModel"
}