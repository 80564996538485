import { Rectangle, Texture } from "pixi.js";
import { SlotResourcesPackage } from "../resources/SlotResourcesPackage";
import { SymbolType } from "./symbols/SymbolType";
import { BigWildAnimationFramesProvider } from "../resources/BigWildAnimationFramesProvider";
import { BonusSymbolFramesProvider } from "../resources/BonusSymbolFramesProvider";
import { WildAnimationFramesProvider } from "../resources/WildAnimationFramesProvider";
import { StickyWildAnimationFramesProvider } from "../resources/StickyWildAnimationFramesProvider";
import { FreeSpinsSymbolFramesProvider } from "../resources/FreeSpinsSymbolFramesProvider";
import { SlotViewModel } from "../models/SlotViewModel";
import { nameof } from "bindable-data";

type SymbolAsset = {
    texture: Texture | Array<Texture>,
    type: SymbolType
}

export const AllSlotSymbols = [1, 2, 3, 4, 5, 6, 0, 7, 8];
export const FreeSpinModeSymbols = [1, 2, 3, 4, 5, 6, 0];
export const SpecialSlotSymbols = [101010, 111111, 0, 7, 8];
export const WinLineSkipSymbols = [9, 10, 11, 12, 13, 101010, 111111];

export class SymbolsAssetsProvider {
    private currentSlotSymbols: Array<number>;

    public constructor(
        private readonly slotResources: SlotResourcesPackage,
        private readonly bigWildAnimationFramesProvider: BigWildAnimationFramesProvider,
        private readonly bonusSymbolFramesProvider: BonusSymbolFramesProvider,
        private readonly wildAnimationFramesProvider: WildAnimationFramesProvider,
        private readonly stickyWildAnimationFramesProvider: StickyWildAnimationFramesProvider,
        private readonly freeSpinsAnimationFramesProvider: FreeSpinsSymbolFramesProvider,
        private readonly slotViewModel: SlotViewModel
    ) {
        this.currentSlotSymbols = this.slotViewModel.isFreeSpinMode.value ? [...FreeSpinModeSymbols] : [...AllSlotSymbols];
        this.slotViewModel.propertyChanged.add(this.onSlotViewModelPropertyChange, this);
    }

    public getTextureById(id: number): SymbolAsset {
        switch (id) {
            case 1: {
                return { texture: this.slotResources.assets_slot_symbols_common_Dice_1_png, type: SymbolType.SMALL };
            }
            case 2: {
                return { texture: this.slotResources.assets_slot_symbols_common_Dice_2_png, type: SymbolType.SMALL };
            }
            case 3: {
                return { texture: this.slotResources.assets_slot_symbols_common_Dice_3_png, type: SymbolType.SMALL };
            }
            case 4: {
                return { texture: this.slotResources.assets_slot_symbols_common_Dice_4_png, type: SymbolType.SMALL };
            }
            case 5: {
                return { texture: this.slotResources.assets_slot_symbols_common_Dice_5_png, type: SymbolType.SMALL };
            }
            case 6: {
                return { texture: this.slotResources.assets_slot_symbols_common_Dice_6_png, type: SymbolType.SMALL };
            }
            case 0: {
                return { texture: this.wildAnimationFramesProvider.animationFrames, type: SymbolType.FULL_SIZE };
            }
            case 7: {
                return { texture: this.freeSpinsAnimationFramesProvider.animationFrames, type: SymbolType.FULL_SIZE };
            }
            case 8: {
                return { texture: this.bonusSymbolFramesProvider.animationFrames, type: SymbolType.FULL_SIZE };
            }
            case 9: {
                return this.getEmptyTexture();
            }
            case 10: {
                return this.getEmptyTexture();
            }
            case 11: {
                return this.getEmptyTexture();
            }
            case 12: {
                return this.getEmptyTexture();
            }
            case 13: {
                return this.getEmptyTexture();
            }
            case 101010: {
                return { texture: this.bigWildAnimationFramesProvider.animationFrames, type: SymbolType.FULL_SIZE };
            }
            case 111111: {
                return { texture: this.stickyWildAnimationFramesProvider.animationFrames, type: SymbolType.FULL_SIZE };
            }
            default: {
                console.warn(`SymbolsAssetsProvider: Symbol with id ${id} not defined, used white texture`);
                return { texture: Texture.WHITE, type: SymbolType.FULL_SIZE };
            }
        }
    }

    private getEmptyTexture(): SymbolAsset {
        const texture = new Texture(Texture.EMPTY.baseTexture, new Rectangle(0, 0, 230, 1));
        return { texture, type: SymbolType.FULL_SIZE };
    }

    private onSlotViewModelPropertyChange(model: SlotViewModel, propertyName: string): void {
        if (propertyName === nameof(model, "isFreeSpinMode")) {
            this.currentSlotSymbols = model.isFreeSpinMode.value ? [...FreeSpinModeSymbols] : [...AllSlotSymbols];
        }
    }

    public getRandom(): { id: number, asset: SymbolAsset } {
        const index = Math.round(Math.random() * (this.currentSlotSymbols.length - 1));
        return { id: this.currentSlotSymbols[index], asset: this.getTextureById(this.currentSlotSymbols[index]) };
    }

    public destroy(): void {
        this.slotViewModel.propertyChanged.remove(this.onSlotViewModelPropertyChange, this);
    }
}
