import { IDestroyOptions, Sprite, Texture } from "pixi.js";
import gsap from "gsap";
import { AudioNames } from "../../../../audio/AudioNames";
import { IAudioManager } from "../../../../audio/IAudioManager";

export class BigWinTextSprite extends Sprite {
    private showAnimation: gsap.core.Timeline;
    private hideAnimation: gsap.core.Timeline;

    public constructor(
        initTexture: Texture,
        private readonly textures: Array<Texture>,
        private readonly audioManager: IAudioManager
    ) {
        super(initTexture);
        this.build();
    }

    private build(): void {
        this.anchor.set(0.5, 1);
        this.visible = false;
    }

    public showBigWinText(): gsap.core.Timeline {
        this.showAnimation = new gsap.core.Timeline({ paused: true }).fromTo(this.scale,
            {
                x: 0,
                y: 0,
            },
            {
                x: 1,
                y: 1,
                ease: "back.out(1.7)",
                duration: 0.5,
                onStart: () => { this.visible = true; this.audioManager.play(AudioNames.BigWinWaveText); }
            });
        return this.showAnimation.play();
    }

    public hideBigWinText(): gsap.core.Timeline {
        this.hideAnimation = new gsap.core.Timeline({ paused: true }).to(this.scale, {
            x: 0,
            y: 0,
            ease: "back.in(1.7)",
            duration: 0.5,
        });
        return this.hideAnimation.play();
    }

    public changeTexture(index: number): void {
        this.texture = this.textures[index];
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        this.showAnimation.kill();
        this.hideAnimation.kill();
    }
}