import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class BonusSymbolAnimationResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("BonusSymbolAnimationResourcesPackage");
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/bonusSymbolAnimation/bonusSymbolAnimation_1x.atlas.json");
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_0_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_0.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_10_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_10.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_11_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_11.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_12_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_12.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_13_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_13.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_14_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_14.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_15_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_15.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_16_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_16.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_17_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_17.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_18_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_18.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_19_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_19.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_2_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_2.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_20_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_20.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_21_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_21.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_22_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_22.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_23_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_23.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_24_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_24.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_25_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_25.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_26_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_26.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_27_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_27.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_28_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_28.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_29_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_29.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_3_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_3.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_30_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_30.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_31_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_31.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_32_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_32.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_33_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_33.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_34_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_34.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_35_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_35.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_36_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_36.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_37_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_37.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_38_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_38.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_39_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_39.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_4_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_4.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_40_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_40.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_41_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_41.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_42_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_42.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_43_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_43.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_44_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_44.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_45_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_45.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_46_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_46.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_47_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_47.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_48_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_48.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_49_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_49.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_5_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_5.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_6_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_6.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_7_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_7.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_8_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_8.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_9_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_9.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_1_png(): Texture {
        return this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_json.textures["bonusSymbolAnimation_1.png"];
    }
    public get assets_bonusSymbolAnimation_bonusSymbolAnimation_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/bonusSymbolAnimation/bonusSymbolAnimation_1x.atlas.png");
    }
}

