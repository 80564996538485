import { injectable } from "@launchtray/tsyringe-async";
import { Bindable } from "bindable-data/dist/binding_data/Bindable";
import { NotifyProperty } from "bindable-data/dist/binding_data/NotifyProperty";
import { nameof } from "bindable-data";
import { BundleResourcesPackage } from "resource-system";
import { SlotResourcesPackage } from "../../slot/resources/SlotResourcesPackage";
import { PackageRegistrator } from "../../../resources/PackageRegistrator";
import { FontsResourcesPackage } from "../../common/resources/FontsResourcesPackage";
import { UIResourcesPackage } from "../../slot/components/ui/resources/UIResourcesPackage";
import { WinPopupResourcesPackage } from "../../common/resources/WinPopupResourcesPackage";
import { BigWildAnimationFramesProvider } from "../../slot/resources/BigWildAnimationFramesProvider";
import { BonusGameOpenResourcesPackage } from "../../popups/bonusGameOpen/resources/BonusGameOpenResourcesPackage";
import { BonusGameResourcesPackage } from "../../popups/bonusGame/resources/BonusGameResourcesPackage";
import { BonusGameFinishResourcesPackage } from "../../popups/bonusGameFinish/resources/BonusGameFinishResourcesPackage";
import { BigWinResourcesPackage } from "../../popups/bigWin/resources/BigWinResourcesPackage";
import { FreeSpinOpenResourcesPackage } from "../../popups/freeSpinOpen/resources/FreeSpinOpenResourcesPackage";
import { FreeSpinFinishResourcesPackage } from "../../popups/freeSpinFinish/resources/FreeSpinFinishResourcesPackage";
import { RulesResourcesPackage } from "../../popups/rules/resources/RulesResourcesPackage";
import { WinFrameAnimationFramesProvider } from "../../slot/components/winFrame/resources/WinFrameAnimationFramesProvider";
import { ChestAnimationFramesProvider } from "../../popups/bonusGame/resources/ChestAnimationFramesProvider";
import { AnticipationFramesProvider } from "../../slot/components/anticipation/resources/AnticipationFramesProvider";
import { BonusSymbolFramesProvider } from "../../slot/resources/BonusSymbolFramesProvider";
import { WildAnimationFramesProvider } from "../../slot/resources/WildAnimationFramesProvider";
import { FreeSpinsModeResourcesPackage } from "../../slot/resources/FreeSpinsModeResourcesPackage";
import { StickyWildAnimationFramesProvider } from "../../slot/resources/StickyWildAnimationFramesProvider";
import { FreeSpinsSymbolFramesProvider } from "../../slot/resources/FreeSpinsSymbolFramesProvider";
import { RulesMobileResourcesPackage } from "../../popups/rules/resources/RulesMobileResourcesPackage";

@injectable()
export class PreloaderModel extends Bindable {
    private readonly packageRegistrator: PackageRegistrator;
    public readonly loadingProgress: NotifyProperty<number>;
    private readonly packagesToPreload: Map<new () => BundleResourcesPackage, BundleResourcesPackage>;
    private readonly packagesToBackgroundLoad: Map<new () => BundleResourcesPackage, BundleResourcesPackage>;
    private readonly packagesAmount: number;
    private loadedPackages: number;
    private readonly progressPerPackage: number;

    public constructor(packageRegistrator: PackageRegistrator) {
        super();
        this.packageRegistrator = packageRegistrator;
        this.loadingProgress = new NotifyProperty(this, nameof(this, "loadingProgress"), 0);
        this.packagesToPreload = new Map<new () => BundleResourcesPackage, BundleResourcesPackage>([
            [FontsResourcesPackage, new FontsResourcesPackage()],
            [UIResourcesPackage, new UIResourcesPackage()],
            [SlotResourcesPackage, new SlotResourcesPackage()],
            [WinPopupResourcesPackage, new WinPopupResourcesPackage()],
            [BigWinResourcesPackage, new BigWinResourcesPackage()],
            [BonusGameOpenResourcesPackage, new BonusGameOpenResourcesPackage()],
            [FreeSpinOpenResourcesPackage, new FreeSpinOpenResourcesPackage()],
            [FreeSpinsModeResourcesPackage, new FreeSpinsModeResourcesPackage()],
            [BonusSymbolFramesProvider, new BonusSymbolFramesProvider()], // TODO: potentially move to background loading
            [FreeSpinsSymbolFramesProvider, new FreeSpinsSymbolFramesProvider()], // TODO: potentially move to background loading
            [WildAnimationFramesProvider, new WildAnimationFramesProvider()], // TODO: potentially move to background loading
        ]);
        this.packagesToBackgroundLoad = new Map<new () => BundleResourcesPackage, BundleResourcesPackage>([
            [StickyWildAnimationFramesProvider, new StickyWildAnimationFramesProvider()],
            [BigWildAnimationFramesProvider, new BigWildAnimationFramesProvider()],
            [WinFrameAnimationFramesProvider, new WinFrameAnimationFramesProvider()],
            [AnticipationFramesProvider, new AnticipationFramesProvider()],
            [ChestAnimationFramesProvider, new ChestAnimationFramesProvider()],
            [BonusGameResourcesPackage, new BonusGameResourcesPackage()],
            [BonusGameFinishResourcesPackage, new BonusGameFinishResourcesPackage()],
            [FreeSpinFinishResourcesPackage, new FreeSpinFinishResourcesPackage()],
            [RulesResourcesPackage, new RulesResourcesPackage()],
            [RulesMobileResourcesPackage, new RulesMobileResourcesPackage()]
        ]);
        this.packagesAmount = this.packagesToPreload.size;
        this.loadedPackages = 0;
        this.progressPerPackage = 1.0 / this.packagesAmount;
    }

    public async startPreloadingPackages(): Promise<void> {
        const preloadedAssetsPromises: Array<Promise<void>> = [];
        for (const keyValuePair of this.packagesToPreload) {
            const pkgType = keyValuePair[0];
            const pkg = keyValuePair[1];
            preloadedAssetsPromises.push(this.assetPackageLoading(pkgType, pkg));
        }
        await Promise.all(preloadedAssetsPromises);
    }

    public async backgroundLoadingPackages(): Promise<void> {
        const backgroundAssetsPromises: Array<Promise<void>> = [];
        for (const keyValuePair of this.packagesToBackgroundLoad) {
            const pkgType = keyValuePair[0];
            const pkg = keyValuePair[1];
            backgroundAssetsPromises.push(this.assetPackageLoading(pkgType, pkg, false));
        }
        await Promise.all(backgroundAssetsPromises);
    }

    private async assetPackageLoading(pkgType: new () => BundleResourcesPackage, pkg: BundleResourcesPackage, isPreloaded: boolean = true) {
        if (isPreloaded) {
            pkg.onLoadingProgress.add(this.handlePackageLoadingProgress, this);
        }
        try {
            await pkg.load();
        } catch (e) {
            console.log(e);
        }
        if (isPreloaded) {
            pkg.onLoadingProgress.remove(this.handlePackageLoadingProgress, this);
            this.loadedPackages++;
        }
        this.packageRegistrator.registerPackage(pkgType, pkg);
    }

    private handlePackageLoadingProgress(packageProgress: number): void {
        this.loadingProgress.setValue(this.loadedPackages / this.packagesAmount + this.progressPerPackage * packageProgress);
    }

}