import { BonusGameOpenView } from "./views/BonusGameOpenView";
import { PromiseCompletionSource, Viewable } from "scene-manager";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { BonusGameOpenResourcesPackage } from "./resources/BonusGameOpenResourcesPackage";
import { InjectableFactories } from "../../../../composition/InjectableFactories";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../audio/AudioManager";

@injectable()
export class BonusGameOpenScene extends Viewable<void> {
    public constructor(
        private readonly bonusGameOpenResources: BonusGameOpenResourcesPackage,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager
    ) {
        super();
        this.bonusGameOpenResources = bonusGameOpenResources;
        this.canvasScaler = canvasScaler;
    }
    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<void>();
        this.view = new BonusGameOpenView(
            this.canvasScaler,
            this.bonusGameOpenResources,
            this.defaultHoverHighlightButtonFactory,
            this.audioManager,
            viewCanBeClosed
        );
        viewCanBeClosed.result.then(async () => {
            this.operationControls.complete()
        });
    }
}
