import { AnimatedSprite, Texture } from "pixi.js";

export class WinFrameAnimation extends AnimatedSprite {
    public constructor(frames: Array<Texture>) {
        super(frames);
        this.animationSpeed = 0.5;
        this.play();
    }

}
