import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class RulesResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("RulesResourcesPackage");
    }
    public get assets_popups_rules_bonusGame_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/bonusGame.png");
    }
    public get assets_popups_rules_buttonExit_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/buttonExit.png");
    }
    public get assets_popups_rules_button_toLeft_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/button_toLeft.png");
    }
    public get assets_popups_rules_button_toRight_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/button_toRight.png");
    }
    public get assets_popups_rules_dice_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/dice.png");
    }
    public get assets_popups_rules_payLines_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/payLines.png");
    }
    public get assets_popups_rules_specialSymbols_mode_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/specialSymbols_mode.png");
    }
    public get assets_popups_rules_specialSymbols_wild_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/specialSymbols_wild.png");
    }
    public get assets_popups_rules_stickyWild_png(): Texture {
        return this.resource<Texture>("assets/popups/rules/stickyWild.png");
    }
}

