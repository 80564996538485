import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class BigWinResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("BigWinResourcesPackage");
    }
    public get assets_popups_bigWin_bigWinText_png(): Texture {
        return this.resource<Texture>("assets/popups/bigWin/bigWinText.png");
    }
    public get assets_popups_bigWin_epicWinText_png(): Texture {
        return this.resource<Texture>("assets/popups/bigWin/epicWinText.png");
    }
    public get assets_popups_bigWin_megaWinText_png(): Texture {
        return this.resource<Texture>("assets/popups/bigWin/megaWinText.png");
    }
    public get assets_popups_bigWin_superWinText_png(): Texture {
        return this.resource<Texture>("assets/popups/bigWin/superWinText.png");
    }
}

