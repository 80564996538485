import { PromiseCompletionSource } from "scene-manager";
import { FreeSpinOpenResourcesPackage } from "../resources/FreeSpinOpenResourcesPackage";
import { BitmapText, IDestroyOptions, Point, Sprite } from "pixi.js";
import { Emitter } from "@pixi/particle-emitter";
import { IFocusable } from "../../../../layers/IFocusable";
import { InOutAnimatedPopup } from "../../../common/views/InOutAnimatedPopup";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../../audio/AudioManager";
import { DefaultBitmapStyleText } from "../../../common/font/DefaultBitmapStyleText";
import { DeviceCheckUtils } from "../../../common/utils/DeviceCheckUtils";
import { ClosePopupOnClickPanel } from "../../../common/views/ClosePopupOnClickPanel";
import { CanvasScaler } from "../../../../../canvas/CanvasScaler";

export class FreeSpinOpenView extends InOutAnimatedPopup<void> implements IFocusable {
    private static readonly forceCompleteTimeDelay = 60_000;
    public readonly focusable: boolean = true;
    private substrate: Sprite;
    private startButton: DefaultHoverHighlightButton;
    private sparks: Emitter;
    private spawnPositions: Array<Point>
    private currentSpawnPosition: number;
    private spinsCountText: BitmapText;
    private timeoutTimerId: NodeJS.Timeout;
    private onScreenChangeHandler: () => void;
    private closePopupOnClickPanel: ClosePopupOnClickPanel;

    public constructor(
        private readonly freeSpinOpenResources: FreeSpinOpenResourcesPackage,
        private readonly canvasScaler: ICanvasScaler,
        private readonly freeSpinsCount: number,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        audioManager: AudioManager,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(audioManager, operationControls);
        this.currentSpawnPosition = 0;
        this.spawnPositions = new Array<Point>(
            new Point(100, 620),
            new Point(590, 320),
            new Point(930, 150));
        this.onStartButtonClick = this.onStartButtonClick.bind(this);
        this.onEndPlayEmitter = this.onEndPlayEmitter.bind(this);
        this.onScreenChangeHandler = this.layout.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenChangeHandler);
        this.build();
        this.layout();
        this.timeoutTimerId = setTimeout(() => {
            this.operationControls.complete();
        }, FreeSpinOpenView.forceCompleteTimeDelay)
    }

    private build(): void {
        this.substrate = new Sprite(this.freeSpinOpenResources.assets_popups_freeSpinOpen_popup_png);
        this.addChild(this.substrate);
        this.spinsCountText = new BitmapText(this.freeSpinsCount.toString(), new DefaultBitmapStyleText(80));
        this.closePopupOnClickPanel = new ClosePopupOnClickPanel(this.canvasScaler as CanvasScaler);
        this.closePopupOnClickPanel.onClick.connect(this.onStartButtonClick);
        this.addChild(this.closePopupOnClickPanel);
        const spriteButton = new Sprite(this.freeSpinOpenResources.assets_popups_freeSpinOpen_start_button_png);
        this.startButton = this.defaultHoverHighlightButtonFactory.createButton(spriteButton);
        this.startButton.onPress.connect(this.onStartButtonClick);
        this.substrate.addChild(this.startButton.view);
        this.sparks = new Emitter(this.substrate, {
            lifetime: { min: 1, max: 1 },
            frequency: 1,
            spawnChance: 1,
            particlesPerWave: 1,
            emitterLifetime: 2,
            maxParticles: 1,
            pos: this.spawnPositions[this.currentSpawnPosition],
            autoUpdate: true,
            behaviors: [
                {
                    type: 'spawnPoint',
                    config:
                    {
                    }
                },
                {
                    type: "rotation",
                    config: {
                        minStart: 0,
                        maxStart: 90,
                        minSpeed: 200,
                        maxSpeed: 200,
                        accel: 50
                    }
                },
                {
                    type: 'scale',
                    config: {
                        scale: {
                            list: [{ value: 0, time: 0 }, { value: 0.3, time: 0.5 }, { value: 0, time: 1 }],
                            isStepped: false
                        },
                        minMult: 1
                    }
                },
                {
                    type: 'textureSingle',
                    config: {
                        texture: this.freeSpinOpenResources.assets_popups_freeSpinOpen_Spark_png
                    }
                },
            ],
        });
        this.sparks.playOnce(this.onEndPlayEmitter);
    }

    private layout(): void {
        const referenceWidth = this.canvasScaler.referenceWidth;
        const substrateMermaidOffset = 60;
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.scale.set(1.6);
            this.substrate.position.set(30, 220);
            this.spinsCountText.position.set(0, 495);
        }
        else {
            this.scale.set(1);
            this.substrate.position.set((referenceWidth - this.substrate.width) * 0.5 + substrateMermaidOffset, 120);
            this.spinsCountText.position.set((referenceWidth - this.spinsCountText.width) * 0.5, 295);
        }
        this.startButton.view.position.set((this.substrate.width - this.startButton.view.width) * 0.5 - substrateMermaidOffset, this.substrate.height - this.startButton.view.height * 0.4);
    }

    private onEndPlayEmitter() {
        this.currentSpawnPosition++;
        if (this.currentSpawnPosition >= this.spawnPositions.length) {
            this.currentSpawnPosition = 0;
        }
        this.sparks.updateSpawnPos(this.spawnPositions[this.currentSpawnPosition].x, this.spawnPositions[this.currentSpawnPosition].y);
        this.sparks.playOnce(this.onEndPlayEmitter);
    }

    private onStartButtonClick(): void {
        this.closePopupOnClickPanel.eventMode = 'none';
        this.operationControls.complete();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        clearTimeout(this.timeoutTimerId);
        this.closePopupOnClickPanel.onClick.disconnect(this.onStartButtonClick);
        window.screen.orientation.removeEventListener("change", this.onScreenChangeHandler);
        this.sparks.destroy();
        this.startButton.onPress.disconnect(this.onStartButtonClick);
        this.startButton.destroy();
        super.destroy(options);
    }
}
