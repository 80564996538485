import { SceneManager } from "scene-manager";
import { Game } from "../Game";
import { MarineDiceContext } from "../composition/MarineDiceContext";
import { MarineDiceViewableLayer } from "./layers/MarineDiceViewableLayer";
import { PreloaderScene } from "./scenes/preloader/PreloaderScene";
import { InjectableInterfaces } from "../composition/InjectableInterfaces";
import { IAuthModel } from "./auth/IAuthModel";
import { SceneManagerWrapper } from "./SceneManagerWrapper";
import { FullscreenBackgroundLayer } from "./layers/FullscreenBackgroundLayer";

export class MarineDice extends Game {
    private readonly context: MarineDiceContext;

    constructor() {
        super();
        this.context = new MarineDiceContext();
    }

    public override async launch(sceneManager?: SceneManager, manifestPath?: string): Promise<void> {
        await super.launch(
            new SceneManagerWrapper(this.stage, {
                viewableLayerType: new MarineDiceViewableLayer(this.canvasScaler),
                backgroundLayer: new FullscreenBackgroundLayer()
            })
        );
        this.context.build(this.canvasScaler, this.sceneManager);
        // Auth user before doing anything
        const authModel = await this.context.resolve<IAuthModel>(InjectableInterfaces.IAuthModel);
        const authenticated = await authModel.auth();
        if (!authenticated) {
            console.error("Failed to authenticate user. See logs above.");
            return;
        }
        try {
            this.sceneManager.showViewableAsync(await this.context.resolve(PreloaderScene));
        } catch (error) {
            console.error(error);
        }
    }
}
