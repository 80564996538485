import { PromiseCompletionSource } from "scene-manager";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { BitmapText, IDestroyOptions, Sprite } from "pixi.js";
import { FreeSpinFinishResourcesPackage } from "../resources/FreeSpinFinishResourcesPackage";
import { IFocusable } from "../../../../layers/IFocusable";
import { InOutAnimatedPopup } from "../../../common/views/InOutAnimatedPopup";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../../audio/AudioManager";
import { DefaultBitmapStyleText } from "../../../common/font/DefaultBitmapStyleText";
import { CurrencyFormatUtils } from "../../../common/utils/NumberFormatUtils";
import { DeviceCheckUtils } from "../../../common/utils/DeviceCheckUtils";
import { ClosePopupOnClickPanel } from "../../../common/views/ClosePopupOnClickPanel";
import { CanvasScaler } from "../../../../../canvas/CanvasScaler";

export class FreeSpinFinishView extends InOutAnimatedPopup<void> implements IFocusable {
    public readonly focusable: boolean = true;
    private substrate: Sprite;
    private rewardText: BitmapText;
    private continueButton: DefaultHoverHighlightButton;
    private onScreenChangeHandler: () => void;
    private closePopupOnClickPanel: ClosePopupOnClickPanel;

    public constructor(
        private readonly freeSpinFinishResources: FreeSpinFinishResourcesPackage,
        private readonly canvasScaler: ICanvasScaler,
        private readonly prizeToShow: number,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        audioManager: AudioManager,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(audioManager, operationControls);
        this.onContinueButtonClick = this.onContinueButtonClick.bind(this);
        this.onScreenChangeHandler = this.layout.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenChangeHandler);
        this.build();
        this.layout();
    }

    private build(): void {
        this.substrate = new Sprite(this.freeSpinFinishResources.assets_popups_freeSpinFinish_popup_png);
        this.addChild(this.substrate);
        this.rewardText = new BitmapText(CurrencyFormatUtils.getFormatedCurrency(this.prizeToShow), new DefaultBitmapStyleText(55));
        this.substrate.addChild(this.rewardText);
        const spriteButton = new Sprite(this.freeSpinFinishResources.assets_popups_freeSpinFinish_continue_button_png);
        this.continueButton = this.defaultHoverHighlightButtonFactory.createButton(spriteButton);
        this.continueButton.onPress.connect(this.onContinueButtonClick)
        this.closePopupOnClickPanel = new ClosePopupOnClickPanel(this.canvasScaler as CanvasScaler);
        this.closePopupOnClickPanel.onClick.connect(this.onContinueButtonClick);
        this.addChild(this.closePopupOnClickPanel);
        this.substrate.addChild(this.continueButton.view);
    }

    private layout(): void {
        const referenceWidth = this.canvasScaler.referenceWidth;
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.scale.set(1.6);
            this.substrate.position.set(75, 220);
        }
        else {
            this.scale.set(1);
            this.substrate.position.set((referenceWidth - this.substrate.width) * 0.5, 120);
        }
        this.continueButton.view.position.set((this.substrate.width - this.continueButton.view.width) * 0.5, this.substrate.height - this.continueButton.view.height * 0.5);
        this.rewardText.anchor.set(0.5, 0.5);
        this.rewardText.position.set((this.substrate.width) * 0.5, 305);
    }

    private onContinueButtonClick(): void {
        this.closePopupOnClickPanel.eventMode = 'none';
        this.operationControls.complete();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        this.closePopupOnClickPanel.onClick.disconnect(this.onContinueButtonClick);
        window.screen.orientation.removeEventListener("change", this.onScreenChangeHandler);
        this.continueButton.onPress.disconnect(this.onContinueButtonClick);
        this.continueButton.destroy();
    }
}
