export enum AudioNames {
    MainTheme = "background_music",
    Spin = "spin",
    SpinStop = "reel_stop",
    Ui = "ui_click",
    BigWinBackground = "bigWin_background",
    BigWinSkip = "bigWin_skip",
    BigWinStopCounter = "bigWin_stopCounter",
    BigWinWaveText = "bigWin_waveText",
    Anticipation = "anticipation",
    BonusSymbol = "bonusSymbol_win",
    PopupIn = "popup_in",
    PopupOut = "popup_out",
    ReelsRotation = "reelsRotation",
    RegularWinStart = "regularWinStart",
    RegularWin = "regularWin",
    WinFrame = "winFrame",
    IdleSymbol = "idleSymbol",
    BonusGameIdle = "bonusGame_Idle",
    BonusGameMultipliers = "bonusGame_Multipliers",
    FreeSpinsSymbols = "freeSpins_Symbols",
    FreeSpinsMusic = "freeSpins_music",
    BonusGameMusic = "bonusGame_music"
}