import { PromiseCompletionSource } from "scene-manager";
import { IFocusable } from "../../../../layers/IFocusable";
import { IDestroyOptions, Sprite } from "pixi.js";
import { BonusGameController } from "../controllers/BonusGameController";
import { BonusGameResourcesPackage } from "../resources/BonusGameResourcesPackage";
import { ChestContainer } from "../components/ChestContainer";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { nameof } from "bindable-data";
import gsap from "gsap";
import { BonusGameViewModel } from "../models/BonusGameViewModel";
import { SlotModel } from "../../../slot/models/SlotModel";
import { InOutAnimatedPopup } from "../../../common/views/InOutAnimatedPopup";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../../audio/AudioManager";
import { ChestAnimationFramesProvider } from "../resources/ChestAnimationFramesProvider";
import { AudioNames } from "../../../../audio/AudioNames";
import { DeviceCheckUtils } from "../../../common/utils/DeviceCheckUtils";

export class BonusGameView extends InOutAnimatedPopup<void> implements IFocusable {
    public readonly focusable: boolean = true;
    private substrate: Sprite;
    private frame: Sprite;
    private title: Sprite;
    private chestContainers: Array<ChestContainer>;
    private onScreenChangeHandler: () => void;

    public constructor(
        private readonly resources: BonusGameResourcesPackage,
        private readonly chestAnimationResources: ChestAnimationFramesProvider,
        private readonly slotModel: SlotModel,
        private readonly bonusGameViewModel: BonusGameViewModel,
        private readonly bonusGameController: BonusGameController,
        private readonly canvasScaler: ICanvasScaler,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        audioManager: AudioManager,
        pcs: PromiseCompletionSource<void>
    ) {
        super(audioManager, pcs);
        this.bonusGameViewModel.propertyChanged.add(this.onBonusGameViewPropertyChange, this);
        this.onScreenChangeHandler = this.layout.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenChangeHandler);
        this.build();
        this.layout();
    }

    public build() {
        this.substrate = new Sprite(this.resources.assets_popups_bonusGame_BonusGameContainer_png);
        this.addChild(this.substrate);
        this.chestContainers = [];
        this.frame = new Sprite(this.resources.assets_popups_bonusGame_Frame_png);
        this.addChild(this.frame);
        this.title = new Sprite(this.resources.assets_popups_bonusGame_TITLE_png);
        this.addChild(this.title);
        for (let i = 0; i < 3; i++) {
            const chest = new ChestContainer(
                this.resources,
                this.chestAnimationResources,
                this.slotModel,
                this.bonusGameViewModel,
                this.bonusGameController,
                this.defaultHoverHighlightButtonFactory,
                this.audioManager,
                i
            );
            chest.onButtonClickEvent.add(this.handleOpenButtonClickEvent.bind(this));
            this.chestContainers.push(chest);
            this.addChild(chest);
        }
    }

    public layout() {
        const referenceWidth = this.canvasScaler.referenceWidth;
        const referenceHeight = this.canvasScaler.referenceHeight;
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.scale.set(1.5);
            this.substrate.position.set(45, 320);
            this.frame.position.set(15, 300);
            this.title.position.set(375, 200);
            this.chestContainers.forEach((chest, index) => (chest.position.set(75 + index * 400, 500)));
        } else {
            this.scale.set(1);
            this.substrate.position.set((referenceWidth - this.substrate.width) * 0.5, (referenceHeight - this.substrate.height) * 0.5);
            this.frame.position.set((referenceWidth - this.substrate.width) * 0.5 - 25, (referenceHeight - this.frame.height) * 0.5);
            this.title.position.set((referenceWidth - this.title.width) * 0.5, (referenceHeight - this.frame.height) * 0.5 - 100);
            this.chestContainers.forEach((chest, index) => (chest.position.set(390 + index * 400, 360)));
        }
    }

    private onBonusGameViewPropertyChange(bonusGameViewModel: BonusGameViewModel, propertyName: string) {
        switch (propertyName) {
            case nameof(bonusGameViewModel, "finishAnimation"): {
                if (bonusGameViewModel.finishAnimation.value == true) {
                    this.operationControls.complete();
                }
                break;
            }
        }
    }

    private async handleOpenButtonClickEvent(chestId: number) {
        this.chestContainers.forEach(chestContainer => chestContainer.disableButton());
        await this.bonusGameController.pickBonus(chestId);
        this.chestContainers[chestId].updateButtonTexture(this.resources.assets_popups_bonusGame_Button_Opened_png);
    }

    protected async showPopupAnimation(): Promise<void> {
        this.audioManager.play(AudioNames.PopupIn);
        const isVerticalMobile = DeviceCheckUtils.checkIsMobileVerticalOrientation();
        this.showAnimationPopup = gsap.timeline().fromTo(this.content.scale,
            {
                x: 0,
                y: 0
            },
            {
                x: 1,
                y: 1,
                ease: 'elastic.out(1,1)',
                duration: 1
            }).fromTo(this.content.position,
                {
                    x: isVerticalMobile ? this.substrate.width * 0.5 + 45 : this.canvasScaler.referenceWidth * 0.5,
                    y: isVerticalMobile ? this.substrate.height * 0.5 + 320 : this.canvasScaler.referenceHeight * 0.5,
                },
                {
                    x: 0,
                    y: 0,
                    ease: 'elastic.out(1,1)',
                    duration: 1
                }, "<");
        await this.showAnimationPopup;
    }

    protected async hidePopupAnimation(): Promise<void> {
        this.audioManager.play(AudioNames.PopupOut);
        const isVerticalMobile = DeviceCheckUtils.checkIsMobileVerticalOrientation();
        this.hideAnimationPopup = gsap.timeline().to(this.content.scale,
            {
                x: 0,
                y: 0,
                ease: 'elastic.in(1,1)',
                duration: 1
            }).fromTo(this.content.position,
                {
                    x: 0,
                    y: 0,
                },
                {
                    x: isVerticalMobile ? this.substrate.width * 0.5 + 45 : this.canvasScaler.referenceWidth * 0.5,
                    y: isVerticalMobile ? this.substrate.height * 0.5 + 320 : this.canvasScaler.referenceHeight * 0.5,
                    ease: 'elastic.in(1,1)',
                    duration: 1
                }, "<");
        await this.hideAnimationPopup;
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.slotModel.propertyChanged.remove(this.onBonusGameViewPropertyChange, this);
        this.chestContainers.forEach(chestContainer => chestContainer.onButtonClickEvent.removeAll());
        window.screen.orientation.removeEventListener("change", this.onScreenChangeHandler);
        super.destroy(options);
    }
}