import { FreeSpinOpenView } from "./views/FreeSpinOpenView";
import { PromiseCompletionSource, Viewable } from "scene-manager";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { FreeSpinOpenResourcesPackage } from "./resources/FreeSpinOpenResourcesPackage";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { InjectableFactories } from "../../../../composition/InjectableFactories";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../audio/AudioManager";

@injectable()
export class FreeSpinOpenScene extends Viewable<void> {
    public constructor(
        private readonly freeSpinOpenResources: FreeSpinOpenResourcesPackage,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        @inject('freeSpinsCount') private readonly freeSpinsCount: number,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager
    ) {
        super();
    }
    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<void>();
        this.view = new FreeSpinOpenView(
            this.freeSpinOpenResources,
            this.canvasScaler,
            this.freeSpinsCount,
            this.defaultHoverHighlightButtonFactory,
            this.audioManager,
            viewCanBeClosed
        );
        viewCanBeClosed.result.then(() => this.operationControls.complete());
    }
}
