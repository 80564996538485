import { injectable, inject, initializer } from "@launchtray/tsyringe-async";
import { Viewable, PromiseCompletionSource } from "scene-manager";
import { SlotController } from "./controllers/SlotController";
import { SlotModel } from "./models/SlotModel";
import { SlotView } from "./views/SlotView";
import { SlotResourcesPackage } from "./resources/SlotResourcesPackage";
import { InjectableInterfaces } from "../../../composition/InjectableInterfaces";
import { ICanvasScaler } from "../../../canvas/ICanvasScaler";
import { GameConfig } from "../../GameConfig";
import { FontsResourcesPackage } from "../common/resources/FontsResourcesPackage";
import { IAudioManager } from "../../audio/IAudioManager";
import { UIResourcesPackage } from "./components/ui/resources/UIResourcesPackage";
import { IPlayerModel } from "../../player/IPlayerModel";
import { SlotViewModel } from "./models/SlotViewModel";
import { InjectableFactories } from "../../../composition/InjectableFactories";
import { DefaultHoverHighlightButtonFactory } from "../../factories/DefaultHoverHighlightButtonFactory";
import { ToggleButtonFactory } from "../../factories/ToggleButtonFactory";
import { BigWildAnimationFramesProvider } from "./resources/BigWildAnimationFramesProvider";
import { WinFrameAnimationFramesProvider } from "./components/winFrame/resources/WinFrameAnimationFramesProvider";
import { AnticipationFramesProvider } from "./components/anticipation/resources/AnticipationFramesProvider";
import { BonusSymbolFramesProvider } from "./resources/BonusSymbolFramesProvider";
import { WildAnimationFramesProvider } from "./resources/WildAnimationFramesProvider";
import { FreeSpinsModeResourcesPackage } from "./resources/FreeSpinsModeResourcesPackage";
import { FullscreenBackground } from "../common/components/FullscreenBackground";
import { StickyWildAnimationFramesProvider } from "./resources/StickyWildAnimationFramesProvider";
import { FreeSpinsSymbolFramesProvider } from "./resources/FreeSpinsSymbolFramesProvider";

@injectable()
export class SlotScene extends Viewable<void> {
    public constructor(
        private readonly slotModel: SlotModel,
        private readonly slotViewModel: SlotViewModel,
        private readonly slotController: SlotController,
        private readonly fontsResources: FontsResourcesPackage,
        private readonly slotResources: SlotResourcesPackage,
        private readonly uiResources: UIResourcesPackage,
        private readonly winFrameResources: WinFrameAnimationFramesProvider,
        private readonly anticipationResources: AnticipationFramesProvider,
        private readonly bigWildResources: BigWildAnimationFramesProvider,
        private readonly bonusSymbolFramesProvider: BonusSymbolFramesProvider,
        private readonly wildAnimationFramesProvider: WildAnimationFramesProvider,
        private readonly freeSpinsModeResources: FreeSpinsModeResourcesPackage,
        private readonly stickyWildAnimationFramesProvider: StickyWildAnimationFramesProvider,
        private readonly freeSpinsSymbolFramesProvider: FreeSpinsSymbolFramesProvider,
        private readonly gameConfig: GameConfig,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: IAudioManager,
        @inject(InjectableInterfaces.IPlayerModel) private readonly player: IPlayerModel,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        @inject(InjectableFactories.ToggleButtonFactory) private readonly toggleButtonFactory: ToggleButtonFactory,
        private readonly fullscreenBackground: FullscreenBackground
    ) {
        super();
        this.slotModel = slotModel;
        this.slotViewModel = slotViewModel;
        this.slotController = slotController;
        this.fontsResources = fontsResources;
        this.slotResources = slotResources;
        this.uiResources = uiResources;
        this.bigWildResources = bigWildResources;
        this.gameConfig = gameConfig;
        this.canvasScaler = canvasScaler;
        this.audioManager = audioManager;
        this.player = player;
    }

    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<void>();

        this.view = new SlotView(
            this.slotModel,
            this.slotViewModel,
            this.slotController,
            this.fontsResources,
            this.slotResources,
            this.winFrameResources,
            this.anticipationResources,
            this.uiResources,
            this.bigWildResources,
            this.bonusSymbolFramesProvider,
            this.wildAnimationFramesProvider,
            this.freeSpinsModeResources,
            this.stickyWildAnimationFramesProvider,
            this.freeSpinsSymbolFramesProvider,
            this.gameConfig,
            this.canvasScaler,
            this.audioManager,
            this.player,
            this.defaultHoverHighlightButtonFactory,
            this.toggleButtonFactory,
            this.fullscreenBackground,
            viewCanBeClosed
        );

        viewCanBeClosed.result.then(() => this.operationControls.complete());
    }
}
