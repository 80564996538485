import { Container, Sprite, Texture } from "pixi.js";
import { RulesResourcesPackage } from "../resources/RulesResourcesPackage";

export class RulesContainer extends Container {
    private textures: Array<Texture>;
    private image: Sprite;
    private currentImageIndex: number;
    public constructor(private readonly rulesResources: RulesResourcesPackage) {
        super();
        this.currentImageIndex = 0;
        this.textures = new Array<Texture>(this.rulesResources.assets_popups_rules_specialSymbols_wild_png,
            this.rulesResources.assets_popups_rules_specialSymbols_mode_png,
            this.rulesResources.assets_popups_rules_stickyWild_png,
            this.rulesResources.assets_popups_rules_dice_png,
            this.rulesResources.assets_popups_rules_bonusGame_png,
            this.rulesResources.assets_popups_rules_payLines_png);
        this.build();
    }

    private build(): void {
        this.image = new Sprite(this.textures[this.currentImageIndex]);
        this.addChild(this.image);
    }

    public trySetNextImage(): boolean {
        if (this.currentImageIndex + 1 < this.textures.length) {
            this.currentImageIndex++;
            this.image.texture = this.textures[this.currentImageIndex];
        }
        return this.currentImageIndex + 1 < this.textures.length;
    }

    public trySetPreviousImage(): boolean {
        if (this.currentImageIndex - 1 >= 0) {
            this.currentImageIndex--;
            this.image.texture = this.textures[this.currentImageIndex];
        }
        return this.currentImageIndex - 1 >= 0;
    }
}