import { BlurFilter, Container, Graphics, IDestroyOptions, isMobile, Rectangle, Ticker } from "pixi.js";
import { SlotSymbol } from "./symbols/SlotSymbol";
import { SpecialSlotSymbols, SymbolsAssetsProvider } from "./SymbolsAssetsProvider";
import { ICanvasScaler } from "by/gamefactory/canvas/ICanvasScaler";
import { gsap } from "gsap";
import { PromiseCompletionSource } from "scene-manager";
import { IAudioManager } from "../../../audio/IAudioManager";
import { AudioNames } from "../../../audio/AudioNames";
import { SlotViewModel } from "../models/SlotViewModel";
import { nameof } from "bindable-data";
import { WinLinesFactoryContainer } from "./winFrame/WinLinesFactoryContainer";

type SpinStopData = {
    symbol: number;
    setted: boolean;
};

export class Reel extends Container {
    private static readonly SPIN_SYMBOL_TIME = 50; // ms
    private static readonly FULL_BLUR_TIME = 50; // ms

    private readonly symbols: Array<SlotSymbol>;
    private symbolsContainer: Container;
    private spinStopData: Array<SpinStopData>;
    private spinStopPCS: PromiseCompletionSource<void>;
    private spinned: boolean;
    private blurFilter: BlurFilter;
    private globalAnimationTime: number;
    private symbolsAnimationTime: number;
    private animationStartYPosition: number;
    private swapYOffset: number;
    private sticky: boolean;

    public constructor(
        private readonly symbolsAssetsProvider: SymbolsAssetsProvider,
        private readonly canvasScaler: ICanvasScaler,
        private readonly audioManager: IAudioManager,
        private readonly slotViewModel: SlotViewModel,
        symbolsInReel: number,
        width: number,
        height: number,
        private readonly symbolSize: number,
        private readonly winLinesFactoryContainer: WinLinesFactoryContainer,
    ) {
        super();
        this.symbols = [];
        this.spinned = false;
        this.symbolsAnimationTime = 0;
        this.globalAnimationTime = 0;
        this.swapYOffset = 0;
        this.sticky = false;
        this.slotViewModel.propertyChanged.add(this.onSlotViewModelPropertyChange, this);
        this.build(symbolsInReel, width, height);
        this.canvasScaler.onResize.add(this.onResize, this);
        Ticker.shared.add(this.onTick, this);
    }

    private build(symbolsInReel: number, width: number, height: number): void {
        const symbolsMask = new Graphics().beginFill(0xffffff).drawRect(0, 0, width, height);
        this.addChild(symbolsMask);

        this.symbolsContainer = new Container();
        this.symbolsContainer.mask = symbolsMask;

        this.blurFilter = new BlurFilter();
        this.blurFilter.blur = 0;
        this.blurFilter.resolution = isMobile ? 1.5 : 0;
        this.blurFilter.enabled = false;
        this.symbolsContainer.filters = [this.blurFilter];
        this.symbolsContainer.filterArea = new Rectangle(0, 0, this.canvasScaler.browserWidth, this.canvasScaler.browserHeight);

        let y = -this.symbolSize;
        for (let i = 0; i < symbolsInReel + 1; i++) {
            const { id, asset } = this.symbolsAssetsProvider.getRandom();
            const symbol = new SlotSymbol(id, asset.texture, this.symbolSize, this.audioManager, this.winLinesFactoryContainer);
            symbol.position.y = y;
            y += symbol.height;
            this.symbols.push(symbol);
            this.symbolsContainer.addChild(symbol);
        }
        this.symbolsContainer.position.set(5, 0);
        this.addChild(this.symbolsContainer);
    }

    private onTick(): void {
        if (!this.spinned) {
            return;
        }
        this.globalAnimationTime = this.spinStopData == null ? this.globalAnimationTime + Ticker.shared.elapsedMS : this.globalAnimationTime - Ticker.shared.elapsedMS;
        this.symbolsAnimationTime += Ticker.shared.elapsedMS;
        const animationProgress = Math.min(this.symbolsAnimationTime / Reel.SPIN_SYMBOL_TIME, 1);

        const blurProgress = Math.max(0, Math.min(this.globalAnimationTime / Reel.FULL_BLUR_TIME, 1));
        this.blurFilter.blurY = blurProgress * 8;
        this.symbolsContainer.position.y = this.lerp(this.animationStartYPosition, this.animationStartYPosition + this.symbolSize, animationProgress);

        const lastSymbol = this.symbols[this.symbols.length - 1];
        if (this.symbolsContainer.position.y >= this.swapYOffset + this.symbolSize) {
            this.swapYOffset = this.symbolsContainer.position.y;
            const firstSymbol = this.symbols[0];
            lastSymbol.position.y = firstSymbol.position.y - this.symbolSize;

            let asset = null;
            let assetId: number = null;
            if (this.spinStopData != null) {
                const symbolData = this.spinStopData.find((value) => !value.setted);
                asset = this.symbolsAssetsProvider.getTextureById(symbolData.symbol);
                assetId = symbolData.symbol;
                symbolData.setted = true;
            }
            else {
                const generateRandom = this.symbolsAssetsProvider.getRandom();
                asset = generateRandom.asset;
                assetId = generateRandom.id;
            }
            lastSymbol.updateData(assetId, asset.texture, asset.type);
            this.symbols.splice(this.symbols.length - 1, 1);
            this.symbols.splice(0, 0, lastSymbol);
        }

        if (animationProgress === 1) {
            this.symbolsAnimationTime = 0;
            this.animationStartYPosition = this.symbolsContainer.position.y;
            if (this.spinStopData?.every((value) => value.setted)) {
                this.blurFilter.blur = 0;
                this.blurFilter.enabled = false;
                this.spinStopData = null;
                this.spinned = false;
                this.spinStopPCS.complete();
                return;
            }
        }
    }

    private onResize(): void {
        this.symbolsContainer.filterArea = new Rectangle(0, 0, this.canvasScaler.browserWidth, this.canvasScaler.browserHeight);
    }

    private onSlotViewModelPropertyChange(model: SlotViewModel, propertyName: string): void {
        if (propertyName === nameof(model, "isFreeSpinMode")) {
            if (model.isFreeSpinMode.value == false) {
                this.sticky = false;
            }
        }
    }

    public async spin(delay: number): Promise<void> {
        if (this.sticky) {
            return;
        }
        await this.spinLiftingUp(delay);
        this.blurFilter.blur = 0;
        this.blurFilter.enabled = true;
        this.animationStartYPosition = this.symbolsContainer.position.y;
        this.swapYOffset = this.symbolsContainer.position.y;
        this.spinned = true;
    }

    public darkenAll(): void {
        this.symbols.forEach(slotSymbol => slotSymbol.darken());
    }

    public darkenAllExceptSymbolIds(symbolId: Array<number>): void {
        const otherSymbols = this.symbols.filter(slotSymbol => !symbolId.includes(slotSymbol.symbolId));
        otherSymbols.forEach(slotSymbol => slotSymbol.darken());
    }

    public cancelDarkenAll(): void {
        this.symbols.forEach(slotSymbol => slotSymbol.cancelDarken());
    }

    public async stopSpin(endData: Array<number>): Promise<void> {
        if (this.sticky) {
            return;
        }
        this.spinStopPCS = new PromiseCompletionSource();
        this.spinStopData = endData.map((value) => ({ symbol: value, setted: false }));
        await this.spinStopPCS.result;
        this.spinStopPCS = null;
        await this.overwhelmed();
        this.highlightSpecialSymbolsAnimation();
        this.sticky = this.checkStickyEndData();
        this.audioManager.play(AudioNames.SpinStop);
    }

    private checkStickyEndData(): boolean {
        return this.symbols.some(slotSymbol => slotSymbol.isSticky());
    }

    public async spinLiftingUp(delay: number): Promise<void> {
        await gsap.to(this.symbolsContainer.position, { y: this.symbolsContainer.position.y - 50, duration: 0.1, ease: "power2.out", delay });
        await gsap.to(this.symbolsContainer.position, { y: this.symbolsContainer.position.y + 50, duration: 0.1, ease: "power2.out" });
    }

    public async overwhelmed(): Promise<void> {
        await gsap.to(this.symbolsContainer.position, { y: this.symbolsContainer.position.y + 50, duration: 0.1, ease: "power2.out" });
        await gsap.to(this.symbolsContainer.position, { y: this.symbolsContainer.position.y - 50, duration: 0.1, ease: "power2.out" });
    }

    public async highlightSpecialSymbolsAnimation(): Promise<void> {
        for (let i = 1; i < this.symbols.length; i++) {
            const slotSymbol = this.getSymbol(i);
            if (SpecialSlotSymbols.includes(slotSymbol.symbolId)) {
                await slotSymbol.playSymbolHighlightAnimation();
            }
        }
    }

    public getSpecialSymbols(symbolId: number): Array<SlotSymbol> {
        const specialSymbolsOnField = this.symbols.filter((symbol, index) => index > 0 && symbol.checkSymbolId(symbolId));
        return specialSymbolsOnField;
    }

    private lerp(first: number, second: number, amount: number): number {
        return first * (1 - amount) + second * amount;
    }

    public playSymbolsAnticipationAnimation(symbolId: number): void {
        this.symbols.filter(slotSymbol => slotSymbol.checkSymbolId(symbolId)).forEach(slotSymbol => slotSymbol.playAnticipationAnimation());
    }

    public stopSymbolsAnticipationAnimation(): void {
        this.symbols.forEach(slotSymbol => slotSymbol.stopAnticipationAnimation());
    }

    public getSymbol(index: number): SlotSymbol {
        if (index < 0 || index >= this.symbols.length) {
            throw new Error(`Failed to find symbol with index ${index}. Index out of bounds.`);
        }
        return this.symbols[index];
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.canvasScaler.onResize.remove(this.onResize, this);
        this.slotViewModel.propertyChanged.remove(this.onSlotViewModelPropertyChange, this);
        Ticker.shared.remove(this.onTick, this);
        super.destroy(options);
    }
}