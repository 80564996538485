import { BaseView, PromiseCompletionSource } from "scene-manager";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { BitmapText, Container, Graphics, IDestroyOptions } from "pixi.js";
import { gsap } from "gsap";
import { Spine } from 'pixi-spine';
import { WinPopupResourcesPackage } from "../../../common/resources/WinPopupResourcesPackage";
import { Emitter, upgradeConfig } from "@pixi/particle-emitter";
import { Button } from "@pixi/ui";
import { RegularWinController } from "../controllers/RegularWinController";
import { SlotModel } from "../../../slot/models/SlotModel";
import { AudioManager } from "../../../../audio/AudioManager";
import { AudioNames } from "../../../../audio/AudioNames";
import { CurrencyFormatUtils } from "../../../common/utils/NumberFormatUtils";
import { DefaultBitmapStyleText } from "../../../common/font/DefaultBitmapStyleText";
import { DeviceCheckUtils } from "../../../common/utils/DeviceCheckUtils";

export class RegularWinView extends BaseView<void> {
    private rewardText: BitmapText;
    private timeline: GSAPTimeline;
    private animation: Spine;
    private sparks: Emitter;
    private sparksContainer: Container;
    private skipButton: Button;
    private clicksCount: number;
    private onScreenChangeHandler: () => void;

    public constructor(
        private readonly canvasScaler: ICanvasScaler,
        private readonly winPopupResources: WinPopupResourcesPackage,
        private readonly regularWinController: RegularWinController,
        private readonly slotModel: SlotModel,
        private readonly audioManager: AudioManager,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(operationControls);
        this.clicksCount = 0;
        this.onEndAnimation = this.onEndAnimation.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.timeline = new gsap.core.Timeline({ paused: true });
        this.onScreenChangeHandler = this.layout.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenChangeHandler);
        this.build();
        this.layout();
        this.createAnimation();
    }

    public start(): void {
        this.timeline.play();
    }

    private build(): void {
        this.animation = new Spine((this.winPopupResources.assets_popups_win_animation_WinPopups_json as any).spineData);
        this.animation.state.setAnimation(0, "RegularWin_rise", false);
        this.rewardText = new BitmapText("", new DefaultBitmapStyleText(50));
        this.skipButton = new Button(new Graphics().beginFill(0xff0000).drawRect(0, 0, this.canvasScaler.referenceWidth, this.canvasScaler.referenceHeight));
        this.skipButton.view.alpha = 0;
        this.skipButton.onPress.connect(this.onButtonClick);
        this.rewardText.anchor.set(0.5, 1);
        this.sparksContainer = new Container();
        const config = upgradeConfig(this.winPopupResources.assets_popups_win_emitter_json as any, [this.winPopupResources.assets_popups_win_particle_png]);
        this.sparks = new Emitter(this.sparksContainer, config);
        this.sparks.autoUpdate = true;
        this.addChild(this.animation);
        this.addChild(this.sparksContainer);
        this.addChild(this.rewardText);
        this.addChild(this.skipButton.view);
    }

    private layout(): void {
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.scale.set(2);
            this.animation.position.set(this.canvasScaler.referenceWidth * 0.25, this.canvasScaler.referenceHeight * 0.5 - 50);
            this.sparksContainer.position.set(0, 300);
            this.rewardText.position.set(this.canvasScaler.referenceWidth * 0.25, 400);
        } else {
            this.scale.set(1);
            this.animation.position.set(this.canvasScaler.referenceWidth * 0.5, this.canvasScaler.referenceHeight * 0.5);
            this.sparksContainer.position.set(475, 350);
            this.rewardText.position.set(this.canvasScaler.referenceWidth * 0.5, 450);
        }
    }

    private createAnimation(): void {
        const prize = this.slotModel.spinResult.value.winning.prize;
        this.timeline.to(this.animation,
            {
                duration: 1.75,
                onStart: () => { this.audioManager.play(AudioNames.RegularWinStart); this.audioManager.play(AudioNames.RegularWin) },
                onComplete: () => this.animation.state.setAnimation(0, "RegularWin_way", false)
            }
        );
        this.timeline.to(this.rewardText.scale,
            {
                x: 1,
                y: 1,
                ease: "back.out(1.4)",
                duration: 1.5,
                delay: 0.5
            }, "<"
        );
        let target = { val: 0 };
        this.timeline.to(target, {
            val: prize,
            duration: 2,
            onUpdate: () => this.setText(target.val),
            onComplete: () => this.onCountPrizeComplete(prize)
        }, "<");
        this.timeline.addLabel("Splash");
        this.timeline.fromTo(this.rewardText.scale,
            {
                x: 1,
                y: 1
            },
            {
                x: 1.3,
                y: 1.3,
                repeat: 3,
                yoyo: true,
                duration: 0.2,
                onStart: () => this.animation.state.setAnimation(0, "RegularWin_splash", false)
            }
        );
        this.timeline.to(this.rewardText, { delay: 0.5 });
        this.timeline.addLabel("Hide");
        this.timeline.fromTo(this.rewardText.scale,
            {
                x: 1,
                y: 1
            },
            {
                x: 0,
                y: 0,
                ease: "back.in(1.7)",
                duration: 0.65,
                onStart: () => this.onStartHideAnimation(),
            }
        );
        this.timeline.eventCallback("onComplete", this.onEndAnimation);
    }

    private onButtonClick(): void {
        this.clicksCount++;
        if (this.timeline.currentLabel() == "Splash") {
            this.timeline.play("Hide", false);
            this.skipButton.enabled = false;
            return;
        }
        if (this.clicksCount == 1) {
            this.timeline.play("Splash", false);
        }
        else {
            this.timeline.play("Hide", false);
            this.skipButton.enabled = false;
        }
    }

    private setText(num: number): void {
        this.rewardText.text = CurrencyFormatUtils.getFormatedCurrency(num);
    }

    private onStartHideAnimation() {
        this.sparks.destroy();
        this.audioManager.play(AudioNames.BigWinSkip);
        this.animation.state.setAnimation(0, "RegularWin_decrease", false);
        this.skipButton.enabled = false;
    }

    private onCountPrizeComplete(prize: number): void {
        this.audioManager.stop(AudioNames.RegularWin);
        this.regularWinController.handlePrizeAnimationFinish(prize);
        this.audioManager.play(AudioNames.BigWinStopCounter);
    }

    private onEndAnimation(): void {
        this.operationControls.complete();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.timeline.kill();
        window.screen.orientation.removeEventListener("change", this.onScreenChangeHandler);
        super.destroy(options);
    }

}
