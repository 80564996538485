import { Container, IDestroyOptions, Sprite, Text, Texture } from "pixi.js";
import { UIResourcesPackage } from "../resources/UIResourcesPackage";
import { UITextStyle } from "../UITextStyle";
import { SlotViewModel } from "../../../models/SlotViewModel";
import { CurrencyFormatUtils } from "../../../../common/utils/NumberFormatUtils";

export class WinField extends Container {
    private winLabel: Text;
    private winPrizeText: Text;

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly slotViewModel: SlotViewModel,
        private readonly initialValue: number
    ) {
        super();
        this.resources = resources;
        this.build();
        this.layout();
        this.slotViewModel.onUpdateWinPrize.add(this.onUpdateWinPrize, this);
    }

    private build() {
        this.winLabel = new Text("WIN", new UITextStyle(32));
        this.addChild(this.winLabel);
        this.winPrizeText = new Text(this.formatWinPrizeToString(this.initialValue), new UITextStyle(34));
        this.winPrizeText.anchor.set(0.5);
        this.addChild(this.winPrizeText);
    }

    private layout() {
        this.winLabel.position.set((this.width - this.winLabel.width) * 0.5, 0);
        this.winPrizeText.position.set(this.width * 0.5, this.winLabel.position.y + this.winLabel.height + 30);
    }

    private formatWinPrizeToString(value: number): string {
        return value === 0 ? '' : CurrencyFormatUtils.getFormatedCurrency(value);
    }

    private onUpdateWinPrize(prize: number): void {
        this.winPrizeText.text = this.formatWinPrizeToString(prize);
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.slotViewModel.onUpdateWinPrize.remove(this.onUpdateWinPrize, this);
        super.destroy(options);
    }
}