import { Container, Sprite, Texture } from "pixi.js";

export class VerticalScrollBar extends Container {
    private line: Sprite;
    private lift: Sprite;
    private heightLine: number;
    private scrollZoneProperty: number;
    private scrollSizeProperty: number;
    public get scrollZone(): number {
        return this.scrollZoneProperty
    }
    public set scrollZone(value: number) {
        if (value === this.scrollZoneProperty) {
            return;
        }
        this.scrollZoneProperty = value;
        this.updateLift();
    }
    public get scrollSize(): number {
        return this.scrollSizeProperty
    }
    public set scrollSize(value: number) {
        if (value === this.scrollSizeProperty) {
            return;
        }
        this.scrollSizeProperty = value;
        this.updateLift();
    }

    public constructor(
        scrollZone: number,
        scrollSize: number,
        private scrollZoneOffset: number = 0,
        private liftXOffset: number = 0
    ) {
        super();
        this.heightLine = scrollZone;
        this.build();
        this.layout();
        this.scrollZone = scrollZone;
        this.scrollSize = scrollSize;
    }

    private build(): void {
        this.line = new Sprite(Texture.WHITE);
        this.line.width = 40;
        this.line.height = this.heightLine;
        this.line.alpha = 0;
        this.addChild(this.line);

        this.lift = new Sprite(Texture.WHITE);
        this.lift.width = 40;
        this.lift.height = 25;
        this.lift.tint = 0x808080;
        this.addChild(this.lift);
    }

    private layout(): void {
        this.lift.position.set(this.line.position.x + this.liftXOffset, this.line.position.y + this.scrollZoneOffset);
    }

    private updateLift(): void {
        if (this.scrollSizeProperty === 0 || this.scrollZone >= this.scrollSize + this.scrollZoneOffset) {
            this.lift.height = this.line.height;
            return;
        }
        this.lift.height = this.line.height * this.scrollZone / (this.scrollSize + this.scrollZoneOffset);
    }

    public updateScroll(scrollValue: number): void {
        const clampedValue = Math.min(Math.max(scrollValue, 0), this.scrollSize - (this.scrollZone + this.scrollZoneOffset * 2));
        this.lift.position.y = this.line.position.y + this.scrollZoneOffset + Math.round((this.line.height - this.lift.height - this.scrollZoneOffset * 2) * (clampedValue / (this.scrollSize - this.scrollZone)));
    }
}
