import { BaseView, PromiseCompletionSource } from "scene-manager";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { BitmapText, Container, Graphics, IDestroyOptions, Texture } from "pixi.js";
import { gsap } from "gsap";
import { Spine } from 'pixi-spine';
import { IFocusable } from "../../../../layers/IFocusable";
import { WinPopupResourcesPackage } from "../../../common/resources/WinPopupResourcesPackage";
import { Emitter, upgradeConfig } from "@pixi/particle-emitter";
import { Button } from "@pixi/ui";
import { SlotModel } from "../../../slot/models/SlotModel";
import { BigWinController } from "../controllers/BigWinController";
import { BigWinResourcesPackage } from "../resources/BigWinResourcesPackage";
import { IAudioManager } from "../../../../audio/IAudioManager";
import { AudioNames } from "../../../../audio/AudioNames";
import { CurrencyFormatUtils } from "../../../common/utils/NumberFormatUtils";
import { DefaultBitmapStyleText } from "../../../common/font/DefaultBitmapStyleText";
import { BigWinTextSprite } from "../components/BigWinTextSprite";
import { DeviceCheckUtils } from "../../../common/utils/DeviceCheckUtils";


export class BigWinView extends BaseView<void> implements IFocusable {
    private rewardText: BitmapText;
    private timeline: gsap.core.Timeline;
    private animation: Spine;
    private sparks: Emitter;
    private sparksContainer: Container;
    private bigWinText: BigWinTextSprite;
    private skipButton: Button;
    private clicksCount: number;
    private prizesForBigWin: Array<number>;
    public readonly focusable: boolean = true;
    private onScreenChangeHandler: () => void;

    public constructor(
        private readonly canvasScaler: ICanvasScaler,
        private readonly bigWinPopupResources: BigWinResourcesPackage,
        private readonly winPopupResources: WinPopupResourcesPackage,
        private readonly bigWinController: BigWinController,
        private readonly slotModel: SlotModel,
        private readonly audioManager: IAudioManager,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(operationControls);
        this.prizesForBigWin = slotModel.spinResult.value.prizesForBigWin;
        this.clicksCount = 0;
        this.onEndAnimation = this.onEndAnimation.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.timeline = new gsap.core.Timeline({ paused: true });
        this.onScreenChangeHandler = this.layout.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenChangeHandler);
        this.build();
        this.layout();
        this.createAnimation();
    }

    public start(): void {
        this.timeline.play();
    }

    private build(): void {
        this.animation = new Spine((this.winPopupResources.assets_popups_win_animation_WinPopups_json as any).spineData);
        this.animation.state.setAnimation(0, "BigWin_rise2", false);
        this.rewardText = new BitmapText("", new DefaultBitmapStyleText(50));
        const textures = new Array<Texture>(
            this.bigWinPopupResources.assets_popups_bigWin_superWinText_png,
            this.bigWinPopupResources.assets_popups_bigWin_megaWinText_png,
            this.bigWinPopupResources.assets_popups_bigWin_epicWinText_png
        );
        this.bigWinText = new BigWinTextSprite(this.bigWinPopupResources.assets_popups_bigWin_bigWinText_png, textures, this.audioManager);
        this.skipButton = new Button(new Graphics().beginFill(0xff0000).drawRect(0, 0, this.canvasScaler.referenceWidth, this.canvasScaler.referenceHeight));
        this.skipButton.view.alpha = 0;
        this.skipButton.onPress.connect(this.onButtonClick);
        this.rewardText.anchor.set(0.5, 0);
        this.sparksContainer = new Container();
        const config = upgradeConfig(this.winPopupResources.assets_popups_win_emitter_json as any, [this.winPopupResources.assets_popups_win_particle_png]);
        this.sparks = new Emitter(this.sparksContainer, config);
        this.sparks.autoUpdate = true;
        this.addChild(this.animation);
        this.addChild(this.sparksContainer);
        this.addChild(this.bigWinText);
        this.addChild(this.rewardText);
        this.addChild(this.skipButton.view);
    }

    private layout(): void {
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.scale.set(2);
            this.animation.position.set(this.canvasScaler.referenceWidth * 0.25, this.canvasScaler.referenceHeight * 0.5);
            this.sparksContainer.position.set(0, 350);
            this.bigWinText.position.set(this.canvasScaler.referenceWidth * 0.25, 445);
            this.rewardText.position.set(this.canvasScaler.referenceWidth * 0.25, 475);
        } else {
            this.scale.set(1);
            this.animation.position.set(this.canvasScaler.referenceWidth * 0.5, this.canvasScaler.referenceHeight * 0.5 + 100);
            this.sparksContainer.position.set(475, 450);
            this.bigWinText.position.set(this.canvasScaler.referenceWidth * 0.5, 545);
            this.rewardText.position.set(this.canvasScaler.referenceWidth * 0.5, 575);
        }
    }

    private createAnimation(): void {
        const prize = this.slotModel.spinResult.value.winning.prize;
        const wayTextTime = 8;
        this.timeline.to(this.animation,
            {
                duration: 2.25,
                onStart: () => { this.audioManager.play(AudioNames.BigWinBackground); this.audioManager.pauseMusic(); },
                onComplete: () => this.animation.state.setAnimation(0, "BigWin_way2", false).timeScale = this.getAnimationDuration("BigWin_way2") / wayTextTime
            }
        );
        this.timeline.fromTo(this.rewardText.scale,
            {
                x: 0,
                y: 0
            },
            {
                x: 1,
                y: 1,
                ease: "back.out(1.4)",
                duration: 1.5,
                delay: 0.5,
            }, "<"
        );
        this.timeline.add(this.bigWinText.showBigWinText(), "<");
        let target = { val: 0.00 };
        this.timeline.to(target, {
            val: prize,
            duration: wayTextTime,
            onUpdate: () => this.setText(target.val),
            onComplete: () => this.onCountPrizeComplete(prize)
        }, "<");
        for (let i = 0; i < this.prizesForBigWin.length; i++) {
            this.timeline.add(this.bigWinText.hideBigWinText()
                .eventCallback("onComplete", () => this.bigWinText.changeTexture(i)), (this.prizesForBigWin[i] / prize) * wayTextTime - 1.1
            );
            this.timeline.add(this.bigWinText.showBigWinText(), ">");
        }
        this.timeline.addLabel("Splash");
        this.timeline.fromTo(this.rewardText.scale,
            {
                x: 1,
                y: 1
            },
            {
                x: 1.3,
                y: 1.3,
                repeat: 3,
                yoyo: true,
                duration: 0.2,
                onStart: () => this.animation.state.setAnimation(0, "BigWin_splash2", false)
            }
        );
        this.timeline.fromTo(this.bigWinText.scale,
            {
                x: 1,
                y: 1
            },
            {
                x: 1.3,
                y: 1.3,
                repeat: 3,
                yoyo: true,
                duration: 0.2
            }, "<"
        );
        this.timeline.to(this.rewardText, { delay: 0.5 });
        this.timeline.addLabel("Hide");
        this.timeline.to(this.rewardText.scale,
            {
                x: 0,
                y: 0,
                ease: "back.in(1.7)",
                duration: 0.5,
                onStart: () => this.onStartHideAnimation(),
            }
        )
        this.timeline.eventCallback("onComplete", this.onEndAnimation);
    }

    private onCountPrizeComplete(prize: number): void {
        this.audioManager.resumeMusic();
        this.audioManager.stop(AudioNames.BigWinBackground);
        this.bigWinController.handlePrizeAnimationFinish(prize);
        this.audioManager.play(AudioNames.BigWinStopCounter);
    }

    private onButtonClick(): void {
        this.clicksCount++;
        if (this.timeline.currentLabel() == "Splash") {
            this.timeline.play("Hide", false);
            this.skipButton.enabled = false;
            return;
        }
        if (this.clicksCount == 1) {
            this.timeline.play("Splash", false);
        }
        else {
            this.timeline.play("Hide", false);
            this.skipButton.enabled = false;
        }
    }

    private setText(prize: number): void {
        this.rewardText.text = CurrencyFormatUtils.getFormatedCurrency(prize);
    }

    private onStartHideAnimation() {
        this.sparks.destroy();
        this.audioManager.play(AudioNames.BigWinSkip);
        this.timeline.add(this.bigWinText.hideBigWinText(), "Hide");
        this.animation.state.setAnimation(0, "BigWin_decrease2", false);
        this.skipButton.enabled = false;
    }

    private onEndAnimation(): void {
        this.operationControls.complete();
    }

    private getAnimationDuration(name: string): number {
        const animation = this.animation.spineData.animations.find(a => a.name == name);
        return animation.duration;
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.timeline.kill();
        window.screen.orientation.removeEventListener("change", this.onScreenChangeHandler);
        super.destroy(options);
    }

}
