import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class RulesMobileResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("RulesMobileResourcesPackage");
    }
    public get assets_popups_rulesMobile_bonusGame_png(): Texture {
        return this.resource<Texture>("assets/popups/rulesMobile/bonusGame.png");
    }
    public get assets_popups_rulesMobile_closeButton_png(): Texture {
        return this.resource<Texture>("assets/popups/rulesMobile/closeButton.png");
    }
    public get assets_popups_rulesMobile_dice_png(): Texture {
        return this.resource<Texture>("assets/popups/rulesMobile/dice.png");
    }
    public get assets_popups_rulesMobile_freeSpins_png(): Texture {
        return this.resource<Texture>("assets/popups/rulesMobile/freeSpins.png");
    }
    public get assets_popups_rulesMobile_freeSpinsBonus_png(): Texture {
        return this.resource<Texture>("assets/popups/rulesMobile/freeSpinsBonus.png");
    }
    public get assets_popups_rulesMobile_payLines_png(): Texture {
        return this.resource<Texture>("assets/popups/rulesMobile/payLines.png");
    }
    public get assets_popups_rulesMobile_wild_png(): Texture {
        return this.resource<Texture>("assets/popups/rulesMobile/wild.png");
    }
}

