import { inject, injectable, singleton } from "@launchtray/tsyringe-async";
import { AudioManager } from "../audio/AudioManager";
import { DefaultHoverHighlightButton } from "../scenes/common/components/DefaultHoverHighlightButton";
import { Container, DisplayObject } from "pixi.js";
import { InjectableInterfaces } from "../../composition/InjectableInterfaces";
import { AudioNames } from "../audio/AudioNames";

@injectable()
export class DefaultHoverHighlightButtonFactory {

    public constructor(@inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager) {
        this.audioManager = audioManager;
    }

    public createButton(view: Container<DisplayObject>, audioName: string = AudioNames.Ui): DefaultHoverHighlightButton {
        return new DefaultHoverHighlightButton(view, this.audioManager, audioName);
    }
}