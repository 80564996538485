import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class FreeSpinFinishResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("FreeSpinFinishResourcesPackage");
    }
    public get assets_popups_freeSpinFinish_continue_button_png(): Texture {
        return this.resource<Texture>("assets/popups/freeSpinFinish/continue_button.png");
    }
    public get assets_popups_freeSpinFinish_popup_png(): Texture {
        return this.resource<Texture>("assets/popups/freeSpinFinish/popup.png");
    }
}

