import { SpinWinning } from "./SpinWinning";
import { SpinResultDto } from "./response/SpinResultDto";
import { WinLineDto } from "./response/WinLineDto";

export class SpinResult {
    public readonly field: Array<number>;
    public readonly bet: number;
    public readonly winning: SpinWinning;
    public readonly playerBalance: number;
    public readonly prizesForBigWin: Array<number>;

    public constructor(dto: SpinResultDto, dropLastWinField: boolean = false) {
        if (dto == null) {
            throw new Error("Failed to convert SpinResultDto to SpinResult. Dto is null.");
        }
        if (dto.field == null || dto.field.length == 0 || dto.field.length < 15) {
            throw new Error("Failed to convert SpinResultDto to SpinResult. Dto 'field' has wrong data.");
        }
        if (dto.bet == null || isNaN(dto.bet)) {
            throw new Error("Failed to convert SpinResultDto to SpinResult. Dto 'bet' has wrong data.");
        }
        if (dto.playerBalance == null || isNaN(dto.playerBalance)) {
            throw new Error("Failed to convert SpinResultDto to SpinResult. Dto 'playerBalance' has wrong data.");
        }
        if (dto.winning.isBonusGameWin && dto.winning.freeSpinCount > 0) {
            throw new Error("Failed to convert SpinResultDto to SpinResult. Can't be bonus game and free spins the same time.");
        }
        this.field = this.doMappingMagic(dto.field, dto.winning.winLines);
        this.bet = dto.bet;
        this.winning = new SpinWinning(dto.winning, dropLastWinField);
        this.playerBalance = dto.playerBalance;
        this.prizesForBigWin = new Array<number>(50 * this.bet, 100 * this.bet).filter(p => this.winning.prize > p);
    }

    private doMappingMagic(field: Array<number>, winLines: Array<WinLineDto>): Array<number> {
        for (let reelIndex = 0; reelIndex < 5; reelIndex++) {
            const firstReelSymbolIndex = reelIndex;
            const reelSymbols = [field[firstReelSymbolIndex], field[firstReelSymbolIndex + 5], field[firstReelSymbolIndex + 10]];
            for (const mapping of this.mappingTable) {
                const pattern = mapping[0];
                if (reelSymbols.every((val, idx) => val === pattern[idx])) {
                    const replaceWith = mapping[1];
                    const notZeroSymbolIndex = replaceWith.findIndex((symbolId) => symbolId != 9);
                    for (let i = 0; i < 3; i++) {
                        const replaceToSymbolId = replaceWith[i];
                        field[firstReelSymbolIndex + i * 5] = replaceToSymbolId;
                        if (replaceToSymbolId == 9) {
                            this.doWinlineMappingMagic(winLines, reelIndex, i, notZeroSymbolIndex);
                        }
                    }
                }
            }
        }
        return field;
    }

    private doWinlineMappingMagic(winLines: Array<WinLineDto>, reelIndex: number, reelSymbolIndex: number, notZeroSymbolIndex: number) {
        for (const winLine of winLines) {
            if (winLine.line[reelIndex] == reelSymbolIndex) {
                winLine.line[reelIndex] = notZeroSymbolIndex;
            }
        }
    }

    private mappingTable: Map<Array<number>, Array<number>> = new Map([
        [[0, 0, 0], [101010, 9, 9]],
        [[7, 7, 7], [111111, 9, 9]]
    ]);
}