"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bindable = void 0;
const event_system_1 = require("event-system");
class Bindable {
    constructor() {
        this.propertyChanged = new event_system_1.Event();
    }
    onPropertyChanged(propertyName) {
        this.propertyChanged.emit(this, propertyName);
    }
    raisePropertyChanged(propertyName) {
        this.onPropertyChanged(propertyName);
    }
}
exports.Bindable = Bindable;
