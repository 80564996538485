import { initializer, injectable } from "@launchtray/tsyringe-async";
import { sound, SoundSourceMap } from "@pixi/sound";
import { VariableSaver } from "../../tools/VariableSaver";
import { IAudioManager } from "./IAudioManager";
import axios from "axios";

interface SoundDto {
    alias: string;
    path: string;
    isMusic: boolean;
    volume: number;
}

@injectable()
export class AudioManager implements IAudioManager {
    private static readonly SOUNDS_STORAGE_KEY = "play_sounds";
    private static readonly configPath = "configs/SoundConfig.json";
    private soundData: Array<SoundDto> = [];
    private _playAllSounds: boolean;
    private _currentMusic: string;
    public get playAllSounds(): boolean {
        return this._playAllSounds;
    }
    public set playAllSounds(value: boolean) {
        this._playAllSounds = value;
        this.variableSaver.saveBoolean(AudioManager.SOUNDS_STORAGE_KEY, this._playAllSounds);
        this.setAllSoundsVolume(this._playAllSounds);
    }

    public constructor(private readonly variableSaver: VariableSaver) {
        document.addEventListener("visibilitychange", this.onVisibilityChange);
    }

    @initializer()
    public async initialize(): Promise<void> {
        const { data } = await axios.get<Array<SoundDto>>(AudioManager.configPath);

        const soundsManifest: SoundSourceMap = data.reduce((prev, curr) => {
            if (curr !== null && this.validateConfigValue(curr)) {
                this.soundData.push(curr);
                return { ...prev, [curr.alias]: curr.path };
            }
            return prev;
        }, {});

        sound.add(soundsManifest);

        if (this.variableSaver.exist(AudioManager.SOUNDS_STORAGE_KEY)) {
            this.playAllSounds = this.variableSaver.getBoolean(AudioManager.SOUNDS_STORAGE_KEY);
        } else {
            this.playAllSounds = true;
        }
    }

    private validateConfigValue({ alias, isMusic, volume, path }: SoundDto): boolean {
        return alias != null && isMusic != null && volume != null && path != null;
    }

    public changeVolume(alias: string, volume: number): void {
        if (sound.exists(alias)) {
            sound.volume(alias, volume);
        }
    }

    public play(alias: string, loop: boolean = false): void {
        if (sound.exists(alias)) {
            const soundConfig = this.soundData.find((data) => data.alias === alias);
            if (soundConfig.isMusic) {
                this._currentMusic = alias;
            }
            sound.play(alias, { loop: soundConfig.isMusic ? true : loop, volume: soundConfig.volume });
        }
    }

    public stop(alias: string): void {
        if (sound.exists(alias)) {
            sound.stop(alias);
        }
    }

    public pauseMusic(): void {
        if (this._currentMusic) {
            sound.pause(this._currentMusic);
        }
    }

    public resumeMusic(): void {
        if (this._currentMusic) {
            sound.resume(this._currentMusic);
        }
    }

    private setAllSoundsVolume(state: boolean): void {
        state ? sound.unmuteAll() : sound.muteAll();
    }

    private onVisibilityChange(): void {
        if (document.hidden) {
            if (this._playAllSounds) {
                this.setAllSoundsVolume(false);
            }
        } else {
            if (this._playAllSounds) {
                this.playAllSounds = true;
            }
        }
    }

}
