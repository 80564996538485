import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { Viewable, PromiseCompletionSource, SceneManager } from "scene-manager";
import { PreloaderResourcesPackage } from "./resources/PreloaderResourcesPackage";
import { PreloaderView } from "./views/PreloaderView";
import { PreloaderModel } from "./models/PreloaderModel";
import { InjectableFactories, SlotSceneFactory } from "../../../composition/InjectableFactories";
import { ICanvasScaler } from "../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../composition/InjectableInterfaces";
import { PreloaderController } from "./controllers/PreloaderContorller";
import { FullscreenBackground } from "../common/components/FullscreenBackground";
import { DefaultHoverHighlightButtonFactory } from "../../factories/DefaultHoverHighlightButtonFactory";

@injectable()
export class PreloaderScene extends Viewable<void> {

    public constructor(
        private readonly preloaderModel: PreloaderModel,
        @inject(InjectableFactories.SlotSceneFactory) private readonly slotSceneFactory: SlotSceneFactory,
        private readonly sceneManager: SceneManager,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        private readonly preloaderController: PreloaderController,
        private readonly fullscreenBackground: FullscreenBackground,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
    ) {
        super();
    }

    @initializer()
    public async initialize(): Promise<void> {
        const preloaderResourcesPackage = await new PreloaderResourcesPackage().load() as PreloaderResourcesPackage;
        const viewCanBeClosed = new PromiseCompletionSource<void>();
        this.view = new PreloaderView(
            preloaderResourcesPackage,
            this.preloaderModel,
            this.preloaderController,
            this.canvasScaler,
            this.fullscreenBackground,
            this.defaultHoverHighlightButtonFactory,
            viewCanBeClosed
        );
        viewCanBeClosed.result.then(async () => {
            const slotScene = await this.slotSceneFactory();
            this.sceneManager.showViewableAsync(slotScene);
            this.operationControls.complete();
        });
    }
}
