"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifyProperty = void 0;
class NotifyProperty {
    get name() {
        return this.propertyName;
    }
    constructor(owner, name, initialValue) {
        this.ownerValue = owner;
        this.propertyName = name;
        this.valueOfProperty = initialValue;
    }
    get value() {
        return this.valueOfProperty;
    }
    setValue(newValue) {
        if (newValue !== this.value) {
            this.valueOfProperty = newValue;
            this.ownerValue.raisePropertyChanged(this.name);
        }
    }
}
exports.NotifyProperty = NotifyProperty;
