import { Container, IDestroyOptions, Sprite } from "pixi.js";
import { UIResourcesPackage } from "../resources/UIResourcesPackage";
import { Event } from "event-system";
import { SlotController } from "../../../controllers/SlotController";
import { SlotViewModel } from "../../../models/SlotViewModel";
import { nameof } from "bindable-data";
import { DefaultHoverHighlightButton } from "../../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../../factories/DefaultHoverHighlightButtonFactory";

export class SpinButtonsContainer extends Container {
    public readonly onSpinClick: Event<void>;
    private spinButton: DefaultHoverHighlightButton;
    private isStopAction: boolean = false;
    private autoButton: DefaultHoverHighlightButton;
    private spinButtonHandler: () => void;
    private autoButtonHandler: () => void;

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly slotController: SlotController,
        private readonly slotViewModel: SlotViewModel,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory
    ) {
        super();
        this.onSpinClick = new Event();
        this.spinButtonHandler = this.onSpinButtonClick.bind(this);
        this.autoButtonHandler = this.onAutoButtonClick.bind(this);
        this.slotViewModel.propertyChanged.add(this.onSlotViewModelPropertyChanged, this);
        this.isStopAction = this.slotViewModel.spinAnimationInProgress.value;
        this.build();
        this.layout();
    }

    private build() {
        const autoButtonTexture = this.slotViewModel.autoSpinEnabled.value ? this.resources.assets_ui_spin_container_auto_stop_button_png : this.resources.assets_ui_spin_container_auto_button_png;
        const autoButtonSprite = new Sprite(autoButtonTexture);
        this.autoButton = this.defaultHoverHighlightButtonFactory.createButton(autoButtonSprite);
        this.autoButton.onPress.connect(this.autoButtonHandler);
        this.addChild(this.autoButton.view);

        const spinButtonTexture = this.slotViewModel.spinAnimationInProgress.value ? this.resources.assets_ui_spin_container_spin_stop_button_png : this.resources.assets_ui_spin_container_spin_button_png;
        const spinButtonSprite = new Sprite(spinButtonTexture);
        this.spinButton = this.defaultHoverHighlightButtonFactory.createButton(spinButtonSprite);
        this.spinButton.onPress.connect(this.spinButtonHandler);
        this.addChild(this.spinButton.view);

        this.spinButton.enabled = !this.slotViewModel.disabledSlotElements.value.includes('spinButtonUi');
        this.autoButton.enabled = !this.slotViewModel.disabledSlotElements.value.includes('autoSpinButtonUi');
    }

    private layout() {
        this.spinButton.view.position.set(0, 0);
        this.autoButton.view.position.set(this.spinButton.view.width + 3, this.height - this.autoButton.view.height);
    }

    private async onSpinButtonClick() {
        if (!this.isStopAction) {
            this.onSpinClick.emit();
        } else {
            this.slotController.stopReelsFast();
        }
    }

    private onAutoButtonClick() {
        this.slotController.toggleAutoSpin();
    }

    private onSlotViewModelPropertyChanged(model: SlotViewModel, key: string) {
        switch (key) {
            case nameof(model, "autoSpinEnabled"): {
                const texture = model.autoSpinEnabled.value ? this.resources.assets_ui_spin_container_auto_stop_button_png : this.resources.assets_ui_spin_container_auto_button_png;
                const viewRef: Sprite = this.autoButton.view as Sprite;
                viewRef.texture = texture;
                break;
            }
            case nameof(model, "spinAnimationInProgress"): {
                this.isStopAction = model.spinAnimationInProgress.value;
                const texture = model.spinAnimationInProgress.value ? this.resources.assets_ui_spin_container_spin_stop_button_png : this.resources.assets_ui_spin_container_spin_button_png;
                const viewRef: Sprite = this.spinButton.view as Sprite;
                viewRef.texture = texture;
                break;
            }
            case nameof(model, "disabledSlotElements"): {
                this.spinButton.enabled = !model.disabledSlotElements.value.includes('spinButtonUi');
                this.autoButton.enabled = !model.disabledSlotElements.value.includes('autoSpinButtonUi');
                break;
            }
            default: break;
        }
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.autoButton.onPress.disconnect(this.autoButtonHandler);
        this.autoButton.destroy();
        this.spinButton.onPress.disconnect(this.spinButtonHandler);
        this.spinButton.destroy();
        this.slotViewModel.propertyChanged.remove(this.onSlotViewModelPropertyChanged, this);
        super.destroy(options);
    }
}