import { Button } from "@pixi/ui";
import { ColorMatrixFilter, Container, DisplayObject, Sprite } from "pixi.js";
import { AudioManager } from "../../../audio/AudioManager";

export class DefaultHoverHighlightButton extends Button {
    private sprite: Sprite;
    private lightFilter: ColorMatrixFilter;

    public constructor(view: Container<DisplayObject>, private readonly audioManager: AudioManager, private readonly audioName: string) {
        super(view);
        this.onHoverButton = this.onHoverButton.bind(this);
        this.onOutButton = this.onOutButton.bind(this);
        this.onDownButton = this.onDownButton.bind(this);
        this.onUpButton = this.onUpButton.bind(this);
        this.onPressButton = this.onPressButton.bind(this);
        this.onHover.connect(this.onHoverButton);
        this.onOut.connect(this.onOutButton);
        this.onDown.connect(this.onDownButton);
        this.onUp.connect(this.onUpButton);
        this.onPress.connect(this.onPressButton);
        this.sprite = this.view as Sprite;
        this.lightFilter = new ColorMatrixFilter();
        this.lightFilter.matrix = [
            1, 0, 0, 0, 0.2,
            0, 1, 0, 0, 0.2,
            0, 0, 1, 0, 0.2,
            0, 0, 0, 1, 0
        ];
    }

    private onHoverButton(): void {
    }

    private onOutButton(): void {
    }

    private onPressButton(): void {
        this.audioManager.play(this.audioName);
    }

    private onDownButton(): void {
        this.sprite.filters = [this.lightFilter];
    }

    private onUpButton(): void {
        this.sprite.filters = [];
    }

    public destroy(): void {
        this.onHover.disconnect(this.onHoverButton);
        this.onOut.disconnect(this.onOutButton);
        this.onDown.disconnect(this.onDownButton);
        this.onUp.disconnect(this.onUpButton);
        this.onPress.disconnect(this.onPressButton);
    }
}