import { ClientConfigDto } from "./ClientConfigDto";

export class ClientConfig {
    public readonly remoteURL: string;
    public readonly localURL: string;
    public readonly toRemote: boolean;
    public readonly showFps: boolean;

    constructor(data: ClientConfigDto) {
        this.remoteURL = data.remoteURL;
        this.localURL = data.localURL;
        this.toRemote = data.toRemote;
        this.showFps = data.showFps;
    }
}
