import { inject, injectable } from "@launchtray/tsyringe-async";
import { AudioManager } from "../audio/AudioManager";
import { Texture } from "pixi.js";
import { InjectableInterfaces } from "../../composition/InjectableInterfaces";
import { AudioNames } from "../audio/AudioNames";
import { ToggleButton } from "../scenes/common/components/ToggleButton";

@injectable()
export class ToggleButtonFactory {

    public constructor(@inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager) {
        this.audioManager = audioManager;
    }

    public createToggleButton(onTexture: Texture, offTexture: Texture, isOn: boolean, audioName: string = AudioNames.Ui): ToggleButton {
        return new ToggleButton(onTexture, offTexture, isOn, this.audioManager, audioName);
    }
}