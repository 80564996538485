import { Texture } from "pixi.js";
import { StickyWildAnimationResourcePackage } from "./StickyWildAnimationResourcePackage";

export class StickyWildAnimationFramesProvider extends StickyWildAnimationResourcePackage {
    private instance: Array<Texture>;

    public get animationFrames(): Array<Texture> {
        if (!this.loaded) {
            throw new Error("You can not use frames provider before actual package is loaded.");
        }
        if (this.instance == null) {
            this.instance = [
                this.assets_stickyWildAnimation_stickyWild_0_png,
                this.assets_stickyWildAnimation_stickyWild_1_png,
                this.assets_stickyWildAnimation_stickyWild_2_png,
                this.assets_stickyWildAnimation_stickyWild_3_png,
                this.assets_stickyWildAnimation_stickyWild_4_png,
                this.assets_stickyWildAnimation_stickyWild_5_png,
                this.assets_stickyWildAnimation_stickyWild_6_png,
                this.assets_stickyWildAnimation_stickyWild_7_png,
                this.assets_stickyWildAnimation_stickyWild_8_png,
                this.assets_stickyWildAnimation_stickyWild_9_png,
                this.assets_stickyWildAnimation_stickyWild_10_png,
                this.assets_stickyWildAnimation_stickyWild_11_png,
                this.assets_stickyWildAnimation_stickyWild_12_png,
                this.assets_stickyWildAnimation_stickyWild_13_png,
                this.assets_stickyWildAnimation_stickyWild_14_png,
                this.assets_stickyWildAnimation_stickyWild_15_png,
                this.assets_stickyWildAnimation_stickyWild_16_png,
                this.assets_stickyWildAnimation_stickyWild_17_png,
                this.assets_stickyWildAnimation_stickyWild_18_png,
                this.assets_stickyWildAnimation_stickyWild_19_png,
                this.assets_stickyWildAnimation_stickyWild_20_png,
                this.assets_stickyWildAnimation_stickyWild_21_png,
                this.assets_stickyWildAnimation_stickyWild_22_png,
                this.assets_stickyWildAnimation_stickyWild_23_png,
                this.assets_stickyWildAnimation_stickyWild_24_png,
                this.assets_stickyWildAnimation_stickyWild_25_png,
                this.assets_stickyWildAnimation_stickyWild_26_png,
                this.assets_stickyWildAnimation_stickyWild_27_png,
                this.assets_stickyWildAnimation_stickyWild_28_png,
                this.assets_stickyWildAnimation_stickyWild_29_png,
                this.assets_stickyWildAnimation_stickyWild_30_png,
                this.assets_stickyWildAnimation_stickyWild_31_png,
                this.assets_stickyWildAnimation_stickyWild_32_png,
                this.assets_stickyWildAnimation_stickyWild_33_png,
                this.assets_stickyWildAnimation_stickyWild_34_png,
                this.assets_stickyWildAnimation_stickyWild_35_png,
                this.assets_stickyWildAnimation_stickyWild_36_png,
                this.assets_stickyWildAnimation_stickyWild_37_png,
                this.assets_stickyWildAnimation_stickyWild_38_png,
                this.assets_stickyWildAnimation_stickyWild_39_png,
                this.assets_stickyWildAnimation_stickyWild_40_png,
                this.assets_stickyWildAnimation_stickyWild_41_png,
                this.assets_stickyWildAnimation_stickyWild_42_png,
                this.assets_stickyWildAnimation_stickyWild_43_png,
                this.assets_stickyWildAnimation_stickyWild_44_png,
                this.assets_stickyWildAnimation_stickyWild_45_png,
                this.assets_stickyWildAnimation_stickyWild_46_png,
                this.assets_stickyWildAnimation_stickyWild_47_png,
                this.assets_stickyWildAnimation_stickyWild_48_png,
                this.assets_stickyWildAnimation_stickyWild_49_png
            ]
        }
        return this.instance;
    }
}