"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Event = void 0;
class ListenerNode {
    constructor(func, context, once = false) {
        this.func = func;
        this.context = context;
        this.once = once;
    }
    call(param0, param1, param2, param3, param4, param5) {
        this.func.apply(this.context, arguments);
    }
    equal(func, context) {
        return this.func === func && this.context === context;
    }
}
class Event {
    add(listener, context) {
        this.addNode(new ListenerNode(listener, context, false));
    }
    once(listener, context) {
        this.addNode(new ListenerNode(listener, context, true));
    }
    remove(listener, context) {
        let node = this.firstNode;
        while (node != null) {
            if (node.equal(listener, context)) {
                this.removeNode(node);
                return;
            }
            node = node.nextNode;
        }
    }
    removeAll() {
        let node = this.firstNode;
        while (node != null) {
            this.removeNode(node);
            node = node.nextNode;
        }
    }
    emit(param0, param1, param2, param3, param4, param5) {
        let node = this.firstNode;
        while (node != null) {
            if (node.once) {
                this.removeNode(node);
            }
            node.call(param0, param1, param2, param3, param4, param5);
            node = node.nextNode;
        }
    }
    addNode(node) {
        if (this.firstNode == null) {
            this.firstNode = this.lastNode = node;
            return;
        }
        this.lastNode.nextNode = node;
        node.prevNode = this.lastNode;
        this.lastNode = node;
    }
    removeNode(node) {
        if (node === this.firstNode) {
            this.firstNode = node.nextNode;
        }
        if (node === this.lastNode) {
            this.lastNode = node.prevNode;
        }
        if (node.prevNode != null) {
            node.prevNode.nextNode = node.nextNode;
        }
        if (node.nextNode != null) {
            node.nextNode.prevNode = node.prevNode;
        }
    }
}
exports.Event = Event;
