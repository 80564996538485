import { injectable } from "@launchtray/tsyringe-async";
import { PreloaderModel } from "../models/PreloaderModel";

@injectable()
export class PreloaderController {
    private readonly preloaderModel: PreloaderModel;

    public constructor(preloaderModel: PreloaderModel) {
        this.preloaderModel = preloaderModel;
    }

    public async startPreloading(): Promise<void> {
        this.preloaderModel.startPreloadingPackages();
    }

    public async startBackgroundAssetsLoading(): Promise<void> {
        this.preloaderModel.backgroundLoadingPackages();
    }
}