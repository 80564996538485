import { BundleResourcesPackage } from "resource-system";
import { Texture } from "pixi.js";
export class FreeSpinsModeResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("FreeSpinsModeResourcesPackage");
    }
    public get assets_freeSpinsMode_freeSpinsMode_BG_jpg(): Texture {
        return this.resource<Texture>("assets/freeSpinsMode/freeSpinsMode_BG.jpg");
    }
    public get assets_freeSpinsMode_freeSpinsTitle_png(): Texture {
        return this.resource<Texture>("assets/freeSpinsMode/freeSpinsTitle.png");
    }
}

