import { BundleResourcesPackage } from "resource-system";
import { Texture, BitmapFont } from "pixi.js";
export class FontsResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("FontsResourcesPackage");
    }
    public get assets_font_Sigmar_Regular_ttf(): FontFace {
        return this.resource<FontFace>("assets/font/Sigmar-Regular.ttf");
    }
    public get assets_font_Yellow_Candy_otf(): any {
        return this.resource<any>("assets/font/Yellow-Candy.otf");
    }
    public get assets_font_bitmap_numbers_png(): Texture {
        return this.resource<Texture>("assets/font/bitmap/numbers.png");
    }
    public get assets_font_bitmap_numbers_xml(): BitmapFont {
        return this.resource<BitmapFont>("assets/font/bitmap/numbers.xml");
    }
}

