import { FreeSpinFinishView } from "./views/FreeSpinFinishView";
import { PromiseCompletionSource, Viewable } from "scene-manager";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { FreeSpinFinishResourcesPackage } from "./resources/FreeSpinFinishResourcesPackage";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableFactories } from "../../../../composition/InjectableFactories";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../audio/AudioManager";

@injectable()
export class FreeSpinFinishScene extends Viewable<void> {

    public constructor(
        private readonly freeSpinFinishResources: FreeSpinFinishResourcesPackage,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        @inject('prize') private readonly prize: number,
        @inject(InjectableFactories.DefaultHoverHighlightButtonFactory) private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager
    ) {
        super();
    }

    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<void>();
        this.view = new FreeSpinFinishView(
            this.freeSpinFinishResources,
            this.canvasScaler,
            this.prize,
            this.defaultHoverHighlightButtonFactory,
            this.audioManager,
            viewCanBeClosed
        );

        viewCanBeClosed.result.then(() => this.operationControls.complete());
    }
}
