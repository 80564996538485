
import { PromiseCompletionSource, Viewable } from "scene-manager";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { RegularWinView } from "./views/RegularWinView";
import { WinPopupResourcesPackage } from "../../common/resources/WinPopupResourcesPackage";
import { RegularWinController } from "./controllers/RegularWinController";
import { SlotModel } from "../../slot/models/SlotModel";
import { AudioManager } from "../../../audio/AudioManager";

@injectable()
export class RegularWinScene extends Viewable<void> {
    public constructor(
        private readonly winPopupResources: WinPopupResourcesPackage,
        private readonly regularWinController: RegularWinController,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        private readonly slotModel: SlotModel,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: AudioManager
    ) {
        super();
    }

    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<void>();
        this.view = new RegularWinView(
            this.canvasScaler,
            this.winPopupResources,
            this.regularWinController,
            this.slotModel,
            this.audioManager,
            viewCanBeClosed
        );

        viewCanBeClosed.result.then(() => this.operationControls.complete());
    }
}