import "reflect-metadata";
import { Assets, LoaderParser } from "@pixi/assets";
import { Sound } from "@pixi/sound";
import { MarineDice } from "./by/gamefactory/marinedice/MarineDice";
import gsap from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import { MotionPathPlugin, CustomEase } from "gsap/all";
import * as PIXI from "pixi.js";

function start(): void {
    const game = new MarineDice();
    (globalThis as any).__PIXI_APP__ = game;
    document.body.appendChild(game.view as HTMLCanvasElement);
    gsap.registerPlugin(PixiPlugin);
    gsap.registerPlugin(CustomEase);
    gsap.registerPlugin(MotionPathPlugin) 
    PixiPlugin.registerPIXI(PIXI);
    game.launch();
}

function initializeSoundParser() {
    Assets.loader.parsers.push({
        test: (url) => {
            return url.indexOf(".mp3") > -1;
        },
        load(url: string) {
            return Promise.resolve(Sound.from(url));
        },
    } as LoaderParser<Sound>);
}

initializeSoundParser();

start();