import { BitmapFont, IDestroyOptions } from "pixi.js";
import { BaseView, PromiseCompletionSource } from "scene-manager";
import { SlotModel } from "../models/SlotModel";
import { SlotController } from "../controllers/SlotController";
import { SlotResourcesPackage } from "../resources/SlotResourcesPackage";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { GameConfig } from "../../../GameConfig";
import { SlotFrame } from "../components/SlotFrame";
import { FontsResourcesPackage } from "../../common/resources/FontsResourcesPackage";
import { IAudioManager } from "../../../audio/IAudioManager";
import { SlotControlPanel } from "../components/ui/SlotControlPanel";
import { UIResourcesPackage } from "../components/ui/resources/UIResourcesPackage";
import { IPlayerModel } from "../../../player/IPlayerModel";
import { SlotViewModel } from "../models/SlotViewModel";
import { nameof } from "bindable-data";
import { ISlotState } from "./slotStates/ISlotState";
import { DefaultState } from "./slotStates/DefaultState";
import { FreeSpinState } from "./slotStates/FreeSpinState";
import { BubblesEffect } from "../components/effects/BubblesEffect";
import { CanvasScaler } from "../../../../canvas/CanvasScaler";
import { DefaultHoverHighlightButtonFactory } from "../../../factories/DefaultHoverHighlightButtonFactory";
import { ToggleButtonFactory } from "../../../factories/ToggleButtonFactory";
import { BonusGameState } from "./slotStates/BonusGameState";
import { SlotStateType } from "./slotStates/SlotStateType";
import { BigWildAnimationFramesProvider } from "../resources/BigWildAnimationFramesProvider";
import { WinFrameAnimationFramesProvider } from "../components/winFrame/resources/WinFrameAnimationFramesProvider";
import { AnticipationFramesProvider } from "../components/anticipation/resources/AnticipationFramesProvider";
import { BonusSymbolFramesProvider } from "../resources/BonusSymbolFramesProvider";
import { WildAnimationFramesProvider } from "../resources/WildAnimationFramesProvider";
import { FreeSpinsModeResourcesPackage } from "../resources/FreeSpinsModeResourcesPackage";
import { FullscreenBackground } from "../../common/components/FullscreenBackground";
import { StickyWildAnimationFramesProvider } from "../resources/StickyWildAnimationFramesProvider";
import { FreeSpinsSymbolFramesProvider } from "../resources/FreeSpinsSymbolFramesProvider";
import { AudioNames } from "../../../audio/AudioNames";
import { ISlotControlPanel } from "../components/ui/ISlotControlPanel";
import { MobileSlotControlPanel } from "../components/ui/MobileSlotControlPanel";
import { DeviceCheckUtils } from "../../common/utils/DeviceCheckUtils";
import { WinLinesFactoryContainer } from "../components/winFrame/WinLinesFactoryContainer";

export class SlotView extends BaseView<void> {
    private frame: SlotFrame;
    private uiPanel: ISlotControlPanel;
    private winLinesFactoryContainer: WinLinesFactoryContainer;
    private currentSlotStateType: SlotStateType;
    private slotViewState: ISlotState<any>;
    private slotStates: Map<SlotStateType, ISlotState<any>>;
    private bubblesEffect: BubblesEffect;

    public constructor(
        private readonly slotModel: SlotModel,
        private readonly slotViewModel: SlotViewModel,
        private readonly slotController: SlotController,
        private readonly fontsResources: FontsResourcesPackage,
        private readonly slotResources: SlotResourcesPackage,
        private readonly winFrameResources: WinFrameAnimationFramesProvider,
        private readonly anticipationResources: AnticipationFramesProvider,
        private readonly uiResources: UIResourcesPackage,
        private readonly bigWildAnimationFramesProvider: BigWildAnimationFramesProvider,
        private readonly bonusSymbolFramesProvider: BonusSymbolFramesProvider,
        private readonly wildAnimationFramesProvider: WildAnimationFramesProvider,
        private readonly freeSpinsModeResources: FreeSpinsModeResourcesPackage,
        private readonly stickyWildAnimationFramesProvider: StickyWildAnimationFramesProvider,
        private readonly freeSpinsSymbolFramesProvider: FreeSpinsSymbolFramesProvider,
        private readonly gameConfig: GameConfig,
        private readonly canvasScaler: ICanvasScaler,
        private readonly audioManager: IAudioManager,
        private readonly player: IPlayerModel,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        private readonly toggleButtonFactory: ToggleButtonFactory,
        private readonly fullscreenBackground: FullscreenBackground,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(operationControls);

        BitmapFont.available["sansserif"] = this.fontsResources.assets_font_bitmap_numbers_xml;

        this.slotViewModel.propertyChanged.add(this.onSlotViewModelPropertyChanged, this);
        this.slotViewModel.onSpinResultsReady.add(() => this.processGameResult(this.slotModel.spinResult.value), this);
        this.slotViewModel.onBonusGameOpenSceneComplete.add(() => this.processGameResult(this.slotModel.bonusGameResult.value), this);
        this.build();
        this.layout();
        this.slotStates = this.initSlotStates();
        this.currentSlotStateType = SlotStateType.DefaultState;
        this.slotViewState = this.slotStates.get(this.currentSlotStateType);
    }

    public async start(): Promise<void> {
        if (this.slotModel.bonusGameActive.value) {
            this.currentSlotStateType = SlotStateType.BonusGameState;
            this.slotViewState = this.slotStates.get(this.currentSlotStateType);
            this.slotViewState.enter();
            return;
        }
        if (this.slotModel.freeSpinMode.value) {
            this.currentSlotStateType = SlotStateType.FreeSpinState;
            this.slotViewState = this.slotStates.get(this.currentSlotStateType);
            this.slotViewState.enter();
            return;
        }
        this.slotViewState.enter();
    }

    private initSlotStates(): Map<number, ISlotState<any>> {
        const stopReelsMethod = this.frame.stopWithAnimation.bind(this.frame);
        const fastStopReelsMethod = this.frame.stopWithResult.bind(this.frame);
        const reelsFader = this.frame.reelsFader.bind(this.frame);
        const showWinLines = this.frame.playWinLinesAnimations.bind(this.frame);
        const highlightSpecialSymbols = this.frame.highlightSpecialSymbolsCombination.bind(this.frame);
        const map: Map<number, ISlotState<any>> = new Map<SlotStateType, ISlotState<any>>(
            [
                [SlotStateType.DefaultState, new DefaultState(stopReelsMethod, fastStopReelsMethod, reelsFader, highlightSpecialSymbols, showWinLines, this.slotController)],
                [SlotStateType.FreeSpinState, new FreeSpinState(stopReelsMethod, fastStopReelsMethod, reelsFader, highlightSpecialSymbols, showWinLines, this.slotController, this.slotModel)],
                [SlotStateType.BonusGameState, new BonusGameState(this.slotController)]
            ]
        );
        return map;
    }

    private build(): void {
        this.bubblesEffect = new BubblesEffect(this.canvasScaler as CanvasScaler, this.slotResources.assets_slot_effects_bubbles_png);
        this.addChild(this.bubblesEffect);
        this.winLinesFactoryContainer = new WinLinesFactoryContainer(this.winFrameResources);
        this.frame = new SlotFrame(
            this.slotResources,
            this.anticipationResources,
            this.bigWildAnimationFramesProvider,
            this.bonusSymbolFramesProvider,
            this.wildAnimationFramesProvider,
            this.freeSpinsModeResources,
            this.stickyWildAnimationFramesProvider,
            this.freeSpinsSymbolFramesProvider,
            this.slotViewModel,
            this.gameConfig,
            this.canvasScaler,
            this.audioManager,
            this.winLinesFactoryContainer
        );
        this.frame.slotSizesChange.add(this.onScreenOrientationChange, this);
        this.addChild(this.frame);
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.uiPanel = new MobileSlotControlPanel(
                this.uiResources,
                this.player,
                this.slotController,
                this.slotViewModel,
                this.defaultHoverHighlightButtonFactory,
                this.toggleButtonFactory,
                this.audioManager
            );
        } else {
            this.uiPanel = new SlotControlPanel(
                this.uiResources,
                this.player,
                this.slotController,
                this.slotViewModel,
                this.defaultHoverHighlightButtonFactory,
                this.toggleButtonFactory,
                this.audioManager
            );
        }

        this.uiPanel.onSpin.add(this.onSpinButtonClick, this);
        this.addChild(this.uiPanel);

        this.addChild(this.winLinesFactoryContainer);
    }

    private layout(): void {
        const referenceWidth = this.canvasScaler.referenceWidth;
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.frame.position.set((referenceWidth - this.frame.width) * 0.5, 250);
            this.winLinesFactoryContainer.scale.set(this.frame.scale.x, this.frame.scale.y);
            this.uiPanel.position.set(0, 1525);
        } else {
            this.frame.position.set((referenceWidth - this.frame.width) * 0.5, 15);
            this.uiPanel.position.set(283, 900);
        }
    }

    private onScreenOrientationChange(): void {
        this.uiPanel.destroy();
        this.uiPanel.onSpin.remove(this.onSpinButtonClick, this);
        const currentTotalBet = this.uiPanel.getTotalBet();
        const currentWinField = this.slotModel.spinResult.value.winning.prize || 0;
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.uiPanel = new MobileSlotControlPanel(
                this.uiResources,
                this.player,
                this.slotController,
                this.slotViewModel,
                this.defaultHoverHighlightButtonFactory,
                this.toggleButtonFactory,
                this.audioManager,
                currentTotalBet,
                currentWinField
            );
            this.winLinesFactoryContainer.scale.set(this.frame.scale.x, this.frame.scale.y);
            this.addChild(this.uiPanel);
        } else {
            this.uiPanel = new SlotControlPanel(
                this.uiResources,
                this.player,
                this.slotController,
                this.slotViewModel,
                this.defaultHoverHighlightButtonFactory,
                this.toggleButtonFactory,
                this.audioManager,
                currentTotalBet,
                currentWinField
            );
            this.winLinesFactoryContainer.scale.set(1);
            this.addChild(this.uiPanel);
        }
        this.uiPanel.onSpin.add(this.onSpinButtonClick, this);
        this.layout();
    }

    private async onSpinButtonClick(): Promise<void> {
        this.audioManager.play(AudioNames.Spin);
        const totalBet = this.uiPanel.getTotalBet();
        const bet = this.slotViewModel.isFreeSpinMode.value ? 0 : totalBet;
        if (!this.slotController.tryBet(bet)) {
            // TODO: here need to show not enough money popup
            console.log("Failed to make bet. Player is out of coins.");
            // No many disable auto spin if it was enabled
            if (this.slotViewModel.autoSpinEnabled.value) {
                this.slotController.toggleAutoSpin();
            }
            return;
        }
        this.frame.clearWinLines();
        this.slotController.setSpinAnimationInProgress(true);
        await this.frame.spinWithAnimation();
        this.slotController.spin(bet);
    }

    private async onSlotViewModelPropertyChanged(model: SlotViewModel, key: string) {
        switch (key) {
            case nameof(model, "autoSpinEnabled"):
                if (model.autoSpinEnabled.value && !model.spinAnimationInProgress.value) {
                    this.onSpinButtonClick();
                }
                break;
            case nameof(model, "slotViewState"):
                let isFreeSpinMode = model.slotViewState.value == SlotStateType.FreeSpinState;
                this.fullscreenBackground.updateImage(isFreeSpinMode
                    ? this.freeSpinsModeResources.assets_freeSpinsMode_freeSpinsMode_BG_jpg
                    : this.slotResources.assets_slot_defaultBackground_jpg);
                break;
            case nameof(model, "fastReelsStop"):
                if (model.fastReelsStop.value && model.spinAnimationInProgress.value) {
                    this.frame.isFastStop = true;
                    this.frame.isSkipWinLines = true;
                }
        }
    }

    private async processGameResult<TGameResult>(result: TGameResult): Promise<void> {
        const nextSlotStateType = await this.slotViewState.process(result);
        this.slotController.setSpinAnimationInProgress(false);
        if (this.currentSlotStateType != nextSlotStateType) {
            await this.slotViewState.exit();
            this.currentSlotStateType = nextSlotStateType;
            this.slotViewState = this.slotStates.get(this.currentSlotStateType);
            await this.slotViewState.enter();
            return;
        }
        if (this.slotViewModel.autoSpinEnabled.value) {
            this.onSpinButtonClick();
        }
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.frame.slotSizesChange.remove(this.onScreenOrientationChange, this);
        this.slotModel.propertyChanged.remove(this.processGameResult, this);
        this.slotViewModel.propertyChanged.remove(this.onSlotViewModelPropertyChanged, this);
        this.slotViewModel.onSpinResultsReady.removeAll();
        this.slotViewModel.onBonusGameOpenSceneComplete.removeAll();
        super.destroy(options);
    }
}
