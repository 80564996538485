import { PromiseCompletionSource, Viewable } from "scene-manager";
import { initializer, inject, injectable } from "@launchtray/tsyringe-async";
import { ICanvasScaler } from "../../../../canvas/ICanvasScaler";
import { InjectableInterfaces } from "../../../../composition/InjectableInterfaces";
import { BigWinView } from "./views/BigWinView";
import { WinPopupResourcesPackage } from "../../common/resources/WinPopupResourcesPackage";
import { SlotModel } from "../../slot/models/SlotModel";
import { BigWinController } from "./controllers/BigWinController";
import { BigWinResourcesPackage } from "./resources/BigWinResourcesPackage";
import { IAudioManager } from "../../../audio/IAudioManager";

@injectable()
export class BigWinScene extends Viewable<void> {

    public constructor(
        private readonly bigWinPopupResources: BigWinResourcesPackage,
        private readonly winPopupResources: WinPopupResourcesPackage,
        @inject(InjectableInterfaces.ICanvasScaler) private readonly canvasScaler: ICanvasScaler,
        private readonly bigWinController: BigWinController,
        private readonly slotModel: SlotModel,
        @inject(InjectableInterfaces.IAudioManager) private readonly audioManager: IAudioManager
    ) {
        super();
    }

    @initializer()
    public async initialize(): Promise<void> {
        const viewCanBeClosed = new PromiseCompletionSource<void>();
        this.view = new BigWinView(
            this.canvasScaler,
            this.bigWinPopupResources,
            this.winPopupResources,
            this.bigWinController,
            this.slotModel,
            this.audioManager,
            viewCanBeClosed
        );
        viewCanBeClosed.result.then(() => this.operationControls.complete());
    }
}