import { DefaultHoverHighlightButtonFactory } from "../marinedice/factories/DefaultHoverHighlightButtonFactory";
import { BigWinScene } from "../marinedice/scenes/popups/bigWin/BigWinScene";
import { BonusGameScene } from "../marinedice/scenes/popups/bonusGame/BonusGameScene";
import { BonusGameFinishScene } from "../marinedice/scenes/popups/bonusGameFinish/BonusGameFinishScene";
import { BonusGameOpenScene } from "../marinedice/scenes/popups/bonusGameOpen/BonusGameOpenScene";
import { FreeSpinFinishScene } from "../marinedice/scenes/popups/freeSpinFinish/FreeSpinFinishScene";
import { FreeSpinOpenScene } from "../marinedice/scenes/popups/freeSpinOpen/FreeSpinOpenScene";
import { MobileBetScene } from "../marinedice/scenes/popups/mobileBet/MobileBetScene";
import { RegularWinScene } from "../marinedice/scenes/popups/regularWin/RegularWinScene";
import { RulesScene } from "../marinedice/scenes/popups/rules/RulesScene";
import { PreloaderScene } from "../marinedice/scenes/preloader/PreloaderScene"
import { SlotScene } from "../marinedice/scenes/slot/SlotScene";
import { SlotModel } from "../marinedice/scenes/slot/models/SlotModel";
import { SlotViewModel } from "../marinedice/scenes/slot/models/SlotViewModel";

export enum InjectableFactories {
    PreloaderSceneFactory = "PreloaderSceneFactory",
    SlotSceneFactory = "SlotSceneFactory",
    FreeSpinOpenSceneFactory = "FreeSpinOpenSceneFactory",
    FreeSpinFinishSceneFactory = "FreeSpinFinishSceneFactory",
    BonusGameOpenSceneFactory = "BonusGameOpenSceneFactory",
    BonusGameSceneFactory = "BonusGameSceneFactory",
    BonusGameFinishSceneFactory = "BonusGameFinishSceneFactory",
    RegularWinSceneFactory = "RegularWinSceneFactory",
    BigWinSceneFactory = "BigWinSceneFactory",
    RulesSceneFactory = "RulesSceneFactory",
    DefaultHoverHighlightButtonFactory = "DefaultHoverHighlightButtonFactory",
    ToggleButtonFactory = "ToggleButtonFactory",
    MobileBetSceneFactory = "MobileBetSceneFactory"
}

export type PreloaderSceneFactory = () => Promise<PreloaderScene>;
export type SlotSceneFactory = () => Promise<SlotScene>;
export type FreeSpinOpenSceneFactory = (freeSpinsCount: number) => Promise<FreeSpinOpenScene>;
export type FreeSpinFinishSceneFactory = (prize: number) => Promise<FreeSpinFinishScene>;
export type BonusGameOpenSceneFactory = () => Promise<BonusGameOpenScene>;
export type BonusGameSceneFactory = () => Promise<BonusGameScene>;
export type BonusGameFinishSceneFactory = (prize: number) => Promise<BonusGameFinishScene>;
export type RegularWinSceneFactory = (slotModel: SlotModel, slotViewModel: SlotViewModel) => Promise<RegularWinScene>;
export type BigWinSceneFactory = (slotModel: SlotModel, slotViewModel: SlotViewModel) => Promise<BigWinScene>;
export type RulesSceneFactory = () => Promise<RulesScene>;
export type MobileBetSceneFactory = (initialTotalBet: number) => Promise<MobileBetScene>;