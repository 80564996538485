import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class AnticipationResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("AnticipationResourcesPackage");
    }
    public get assets_anticipation_anticipation_0_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/anticipation/anticipation-0_1x.atlas.json");
    }
    public get assets_anticipation_anticipation_0_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_0.png"];
    }
    public get assets_anticipation_anticipation_1_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_1.png"];
    }
    public get assets_anticipation_anticipation_10_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_10.png"];
    }
    public get assets_anticipation_anticipation_11_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_11.png"];
    }
    public get assets_anticipation_anticipation_13_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_13.png"];
    }
    public get assets_anticipation_anticipation_14_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_14.png"];
    }
    public get assets_anticipation_anticipation_15_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_15.png"];
    }
    public get assets_anticipation_anticipation_16_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_16.png"];
    }
    public get assets_anticipation_anticipation_17_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_17.png"];
    }
    public get assets_anticipation_anticipation_18_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_18.png"];
    }
    public get assets_anticipation_anticipation_19_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_19.png"];
    }
    public get assets_anticipation_anticipation_2_png(): Texture {
        return this.assets_anticipation_anticipation_0_1x_atlas_json.textures["anticipation_2.png"];
    }
    public get assets_anticipation_anticipation_0_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/anticipation/anticipation-0_1x.atlas.png");
    }
    public get assets_anticipation_anticipation_1_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/anticipation/anticipation-1_1x.atlas.json");
    }
    public get assets_anticipation_anticipation_20_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_20.png"];
    }
    public get assets_anticipation_anticipation_21_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_21.png"];
    }
    public get assets_anticipation_anticipation_22_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_22.png"];
    }
    public get assets_anticipation_anticipation_23_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_23.png"];
    }
    public get assets_anticipation_anticipation_25_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_25.png"];
    }
    public get assets_anticipation_anticipation_26_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_26.png"];
    }
    public get assets_anticipation_anticipation_27_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_27.png"];
    }
    public get assets_anticipation_anticipation_28_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_28.png"];
    }
    public get assets_anticipation_anticipation_29_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_29.png"];
    }
    public get assets_anticipation_anticipation_3_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_3.png"];
    }
    public get assets_anticipation_anticipation_30_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_30.png"];
    }
    public get assets_anticipation_anticipation_31_png(): Texture {
        return this.assets_anticipation_anticipation_1_1x_atlas_json.textures["anticipation_31.png"];
    }
    public get assets_anticipation_anticipation_1_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/anticipation/anticipation-1_1x.atlas.png");
    }
    public get assets_anticipation_anticipation_2_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/anticipation/anticipation-2_1x.atlas.json");
    }
    public get assets_anticipation_anticipation_32_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_32.png"];
    }
    public get assets_anticipation_anticipation_33_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_33.png"];
    }
    public get assets_anticipation_anticipation_34_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_34.png"];
    }
    public get assets_anticipation_anticipation_35_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_35.png"];
    }
    public get assets_anticipation_anticipation_36_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_36.png"];
    }
    public get assets_anticipation_anticipation_37_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_37.png"];
    }
    public get assets_anticipation_anticipation_38_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_38.png"];
    }
    public get assets_anticipation_anticipation_39_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_39.png"];
    }
    public get assets_anticipation_anticipation_4_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_4.png"];
    }
    public get assets_anticipation_anticipation_40_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_40.png"];
    }
    public get assets_anticipation_anticipation_41_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_41.png"];
    }
    public get assets_anticipation_anticipation_42_png(): Texture {
        return this.assets_anticipation_anticipation_2_1x_atlas_json.textures["anticipation_42.png"];
    }
    public get assets_anticipation_anticipation_2_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/anticipation/anticipation-2_1x.atlas.png");
    }
    public get assets_anticipation_anticipation_3_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/anticipation/anticipation-3_1x.atlas.json");
    }
    public get assets_anticipation_anticipation_43_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_43.png"];
    }
    public get assets_anticipation_anticipation_44_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_44.png"];
    }
    public get assets_anticipation_anticipation_45_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_45.png"];
    }
    public get assets_anticipation_anticipation_46_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_46.png"];
    }
    public get assets_anticipation_anticipation_47_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_47.png"];
    }
    public get assets_anticipation_anticipation_5_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_5.png"];
    }
    public get assets_anticipation_anticipation_6_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_6.png"];
    }
    public get assets_anticipation_anticipation_7_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_7.png"];
    }
    public get assets_anticipation_anticipation_8_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_8.png"];
    }
    public get assets_anticipation_anticipation_9_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_9.png"];
    }
    public get assets_anticipation_anticipation_12_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_12.png"];
    }
    public get assets_anticipation_anticipation_24_png(): Texture {
        return this.assets_anticipation_anticipation_3_1x_atlas_json.textures["anticipation_24.png"];
    }
    public get assets_anticipation_anticipation_3_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/anticipation/anticipation-3_1x.atlas.png");
    }
}

