import { Button } from "@pixi/ui";
import { ColorMatrixFilter, Sprite, Texture } from "pixi.js";
import { AudioManager } from "../../../audio/AudioManager";
import { AudioNames } from "../../../audio/AudioNames";
import { Signal } from 'typed-signals';


export class ToggleButton extends Button {
    private sprite: Sprite;
    private lightFilter: ColorMatrixFilter;
    public onChange: Signal<(state: boolean) => void>;

    public constructor(private readonly onTexture: Texture,
        private readonly offTexture: Texture,
        private isOn: boolean,
        private readonly audioManager: AudioManager,
        private readonly soundName: string = AudioNames.Ui
    ) {
        super(new Sprite(isOn ? onTexture : offTexture));
        this.onDownToggleButton = this.onDownToggleButton.bind(this);
        this.onUpToggleButton = this.onUpToggleButton.bind(this);
        this.onPressToggleButton = this.onPressToggleButton.bind(this);
        this.sprite = this.view as Sprite;
        this.onUp.connect(this.onUpToggleButton);
        this.onDown.connect(this.onDownToggleButton);
        this.onPress.connect(this.onPressToggleButton);
        this.lightFilter = new ColorMatrixFilter();
        this.lightFilter.matrix = [
            1, 0, 0, 0, 0.2,
            0, 1, 0, 0, 0.2,
            0, 0, 1, 0, 0.2,
            0, 0, 0, 1, 0
        ];
        this.onChange = new Signal();
    }

    private onPressToggleButton(): void {
        this.audioManager.play(this.soundName);
        this.isOn = !this.isOn;
        this.sprite.texture = this.isOn ? this.onTexture : this.offTexture;
        this.onChange.emit(this.isOn);
    }

    private onDownToggleButton(): void {
        this.sprite.filters = [this.lightFilter];
    }

    private onUpToggleButton(): void {
        this.sprite.filters = [];
    }

    public destroy(): void {
        this.onUp.disconnect(this.onUpToggleButton);
        this.onDown.disconnect(this.onDownToggleButton);
        this.onPress.disconnect(this.onPressToggleButton);
    }
}