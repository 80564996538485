import { Container, IDestroyOptions } from "pixi.js";
import { UIResourcesPackage } from "../resources/UIResourcesPackage";
import { SlotController } from "../../../controllers/SlotController";
import { ToggleButtonFactory } from "../../../../../factories/ToggleButtonFactory";
import { ToggleButton } from "../../../../common/components/ToggleButton";

export class SoundContainer extends Container {
    private soundToggleButton: ToggleButton;

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly slotController: SlotController,
        private readonly toggleButtonFactory: ToggleButtonFactory,
        private readonly isPlaySounds: boolean
    ) {
        super();
        this.onSoundButtonClick = this.onSoundButtonClick.bind(this);
        this.build();
    }

    private build() {
        this.soundToggleButton = this.toggleButtonFactory.createToggleButton(this.resources.assets_ui_sound_container_soundOn_png, this.resources.assets_ui_sound_container_soundOff_png, this.isPlaySounds);
        this.soundToggleButton.onChange.connect(this.onSoundButtonClick);
        this.addChild(this.soundToggleButton.view);
    }

    private onSoundButtonClick(isOn: boolean) {
        this.slotController.switchSound(isOn);
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        this.soundToggleButton.onChange.disconnect(this.onSoundButtonClick);
    }
}