import { IDataStorage } from "./IDataStorage";

export class LocalStorage implements IDataStorage {
    public exist(name: string): boolean {
        if (localStorage.getItem(name)) {
            return true;
        }
        return false;
    }

    public saveBoolean(name: string, value: boolean): void {
        let savedVal = value ? 1 : 0;
        localStorage.setItem(name, savedVal.toString());
    }

    public saveNumber(name: string, value: number): void {
        localStorage.setItem(name, value.toString());
    }

    public saveString(name: string, value: string): void {
        localStorage.setItem(name, value);
    }

    public getBoolean(name: string): boolean {
        this.throwIfNotExists(name);
        let value = this.getNumber(name);
        return Boolean(value);
    }

    public getNumber(name: string): number {
        this.throwIfNotExists(name);
        let value = +localStorage.getItem(name);
        return value;
    }

    public getString(name: string): string {
        this.throwIfNotExists(name);
        let value = localStorage.getItem(name);
        return value;
    }

    private throwIfNotExists(name: string): void {
        try {
            localStorage.getItem(name);
        } catch (error) {
            throw new Error(error);
        }
    }

    public removeItem(name: string): void {
        this.throwIfNotExists(name);
        localStorage.removeItem(name);
    }
}