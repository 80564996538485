import { PromiseCompletionSource } from "scene-manager";
import { ICanvasScaler } from "../../../../../canvas/ICanvasScaler";
import { BitmapText, IDestroyOptions, Sprite } from "pixi.js";
import { BonusGameFinishResourcesPackage } from "../resources/BonusGameFinishResourcesPackage";
import { IFocusable } from "../../../../layers/IFocusable";
import { InOutAnimatedPopup } from "../../../common/views/InOutAnimatedPopup";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { AudioManager } from "../../../../audio/AudioManager";
import { CurrencyFormatUtils } from "../../../common/utils/NumberFormatUtils";
import { DefaultBitmapStyleText } from "../../../common/font/DefaultBitmapStyleText";
import { BonusGameFinishController } from "../controllers/BonusGameFinishController";
import { DeviceCheckUtils } from "../../../common/utils/DeviceCheckUtils";
import { ClosePopupOnClickPanel } from "../../../common/views/ClosePopupOnClickPanel";
import { CanvasScaler } from "../../../../../canvas/CanvasScaler";

export class BonusGameFinishView extends InOutAnimatedPopup<void> implements IFocusable {
    public readonly focusable: boolean = true;
    private continueButton: DefaultHoverHighlightButton;
    private substrate: Sprite;
    private rewardText: BitmapText;
    private onScreenChangeHandler: () => void;
    private closePopupOnClickPanel: ClosePopupOnClickPanel;

    public constructor(
        private readonly bonusGameFinishResources: BonusGameFinishResourcesPackage,
        private readonly bonusGameFinishController: BonusGameFinishController,
        private readonly canvasScaler: ICanvasScaler,
        private readonly prize: number,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        audioManager: AudioManager,
        operationControls: PromiseCompletionSource<void>
    ) {
        super(audioManager, operationControls);
        this.onContinueButtonClick = this.onContinueButtonClick.bind(this);
        this.onScreenChangeHandler = this.layout.bind(this);
        window.screen.orientation.addEventListener("change", this.onScreenChangeHandler);
        this.build();
        this.layout();
    }

    private build(): void {
        this.substrate = new Sprite(this.bonusGameFinishResources.assets_popups_bonusGameFinish_Popup_BonusGame_Finish_png);
        this.addChild(this.substrate);
        this.rewardText = new BitmapText(CurrencyFormatUtils.getFormatedCurrency(this.prize), new DefaultBitmapStyleText(55));
        this.substrate.addChild(this.rewardText);
        this.closePopupOnClickPanel = new ClosePopupOnClickPanel(this.canvasScaler as CanvasScaler);
        this.closePopupOnClickPanel.onClick.connect(this.onContinueButtonClick);
        this.addChild(this.closePopupOnClickPanel);
        const spriteButton = new Sprite(this.bonusGameFinishResources.assets_popups_bonusGameFinish_Button_BonusGame_Finish_png);
        this.continueButton = this.defaultHoverHighlightButtonFactory.createButton(spriteButton);
        this.continueButton.onPress.connect(this.onContinueButtonClick);
        this.substrate.addChild(this.continueButton.view);
    }

    private layout(): void {
        const referenceWidth = this.canvasScaler.referenceWidth;
        if (DeviceCheckUtils.checkIsMobileVerticalOrientation()) {
            this.scale.set(1.5);
            this.substrate.position.set(-30, 120);
        } else {
            this.scale.set(1);
            this.substrate.position.set((referenceWidth - this.substrate.width) * 0.5, 120);
        }
        this.continueButton.view.position.set(this.substrate.width * 0.5 - this.continueButton.view.width * 0.5, 750);
        this.rewardText.position.set(this.substrate.width * 0.5 - this.rewardText.width * 0.5, 315);
    }

    private onContinueButtonClick(): void {
        this.closePopupOnClickPanel.eventMode = 'none';
        this.bonusGameFinishController.updateWinField(this.prize);
        this.operationControls.complete();
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
        this.closePopupOnClickPanel.onClick.disconnect(this.onContinueButtonClick);
        this.continueButton.onPress.disconnect(this.onContinueButtonClick);
        window.screen.orientation.removeEventListener("change", this.onScreenChangeHandler);
        this.continueButton.destroy();
    }
}
