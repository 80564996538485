import { injectable, inject } from "@launchtray/tsyringe-async";
import { IClientConfigProvider } from "./clientConfig/IClientConfigProvider";
import axios from "axios";
import { InjectableInterfaces } from "../composition/InjectableInterfaces";

@injectable()
export class HttpTransport {
    private urlPrefix: string;

    public constructor(
        @inject(InjectableInterfaces.IClientConfigProvider) clientConfigProvider: IClientConfigProvider
    ) {
        this.urlPrefix = clientConfigProvider.toRemote ? clientConfigProvider.remoteURL : clientConfigProvider.localURL;
    }

    public async get<R, T = {}>(url: string, payload: T, token: string): Promise<R> {
        try {
            const fullUrl = `${this.urlPrefix}${url}`;
            const { data } = await axios.get<R>(fullUrl, { headers: { "Authorization": `Bearer ${token}` }, params: payload });
            return data;
        }
        catch (e) {
            console.log(e);
        }
    }
    public async post<R, T = {}>(url: string, payload: T, token: string): Promise<R> {
        try {
            const fullUrl = `${this.urlPrefix}${url}`;
            const { data } = await axios.post<R>(fullUrl, payload, { headers: { "Authorization": `Bearer ${token}` } });
            return data;
        }
        catch (e) {
            console.log(e);
        }
    }
}
